import React, { useState } from 'react'
import { useFormatMessage } from '@babylon/intl'

import TextInput from '../../../TextInput'
import triageIntro from '../../../../../questions/triageIntro'
import { GoToQuestionInterface } from '../../../../../constants/types'
import { trackWelcomeScreenStarted } from '../../../../../util/tracking'
import authAccessor from '../../../../../util/auth'

import messages from './messages'

const WelcomeScreen = ({
  goToQuestion,
  loading,
  onChange,
  setError,
  submitForm,
}: Props) => {
  const auth = authAccessor()
  const [search, updateSearch] = useState('')
  const translate = useFormatMessage()

  const goToTriage = async () => {
    const isLoggedIn = await auth.tokenIsValid()

    trackWelcomeScreenStarted()

    if (!isLoggedIn) {
      // @ts-ignore
      goToQuestion(triageIntro(translate))
    } else {
      // Set this to the action we want to perform, and submit the form
      onChange('startConversation')
      setImmediate(() => submitForm())
    }
  }

  const handleSubmit = () => {
    if (search && search.trim()) {
      goToTriage()
    }
  }

  return (
    <TextInput
      submitLabel={translate(messages.welcomeSubmit)}
      value={search}
      onTextInputChange={updateSearch}
      onSubmit={handleSubmit}
      enabled
      wrapWithDiv
      loading={loading}
      autoFocus={false}
      setError={setError}
    />
  )
}

interface Props {
  goToQuestion: (newQuestion: GoToQuestionInterface) => void
  loading: boolean
  onChange: (value: string) => void
  setError: (error: string) => void
  submitForm: () => void
}

export default WelcomeScreen
