import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'question.login.heading',
    defaultMessage: 'Log in to your account',
  },
  email: {
    id: 'question.login.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'question.login.password',
    defaultMessage: 'Password',
  },
  submitButton: {
    id: 'question.login.submitButton',
    defaultMessage: 'Log In',
  },
})
