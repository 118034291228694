import { microservicesClient } from '../../client'
import {
  replyWithMessageResponseRest,
  replyWithMessageApiProps,
} from '../../../constants/types'
import { getBaseChatscriptUrl } from '../../util/baseUrls'

const replyWithMessageRest = async ({
  conversationId,
  etag,
  memberUuid,
  message,
}: replyWithMessageApiProps) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .post<Omit<replyWithMessageResponseRest, 'etag'>>(
      `${baseUrl}/conversations/${conversationId}/say`,
      message,
      {
        headers: {
          'If-Match': etag,
        },
      }
    )
    .then((response) => ({
      ...response.data,
      etag: response.headers.etag as string,
    }))
}

export default replyWithMessageRest
