import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

const registerName = (translate: IntlFormatters['formatMessage']) => ({
  question: {
    fields: [
      {
        label: translate(messages.firstName),
        name: 'first_name',
        type: 'text',
      },
      {
        label: translate(messages.lastName),
        name: 'last_name',
        type: 'text',
      },
      {
        label: translate(messages.submitButton),
        type: 'submit',
      },
      {
        type: 'login_link',
      },
    ],
    progress: 0.33,
    text: translate(messages.heading),
    type: inputTypes.FORM,
    undoable: true,
  },
  answerProcessorName: 'registerNameProcessor',
  answerProcessorOptions: {},
  conversationContext: {},
})

export default registerName
