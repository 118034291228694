import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'

import { getSymptomSuggestions } from '../../../api'
import { currentConversationId } from '../../../redux/selectors'
import { SuggestionInterface } from '../../../constants/types'

const debounceWaitTime = 250

const useSymptomSearch = (): [
  boolean,
  (query: string) => void,
  SuggestionInterface[]
] => {
  const [isFetching, setIsFetching] = useState(false)
  const [suggestions, setSuggestions] = useState<SuggestionInterface[]>([])
  const conversationId = useSelector(currentConversationId)

  // TODO address the eslint error below
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetSymptomSuggestions = useCallback(
    debounce(
      (query: string) =>
        getSymptomSuggestions({ conversationId, query })
          .then((response) => setSuggestions(response))
          .finally(() => setIsFetching(false)),
      debounceWaitTime
    ),
    [conversationId]
  )

  const searchSymptoms = (query: string) => {
    if (query.length <= 1) {
      setSuggestions([])
    } else {
      setIsFetching(true)
      debouncedGetSymptomSuggestions(query)
    }
  }

  return [isFetching, searchSymptoms, suggestions]
}

export default useSymptomSearch
