import { defineMessages } from 'react-intl'

export default defineMessages({
  headingOne: {
    id: 'assessmentOverview.headingOne',
    defaultMessage: 'Your results',
  },
  headingTwo: {
    id: 'assessmentOverview.headingTwo',
    defaultMessage:
      'You mentioned some symptoms that aren’t featured in the conditions information provided here:',
  },
  medicalAdviceDisclaimer: {
    id: 'assessmentOverview.medicalAdviceDisclaimer',
    defaultMessage:
      'This is for information purposes only and does not replace medical diagnosis or advice.',
  },
  triageDisclaimer: {
    id: 'assessmentOverview.triageDisclaimer',
    defaultMessage:
      'Please note this is not an exhaustive list, so you may have a condition not listed here. If you are at all concerned about your health, please seek medical advice.',
  },
  seekMedicalAdviceNotice: {
    id: 'assessmentOverview.seekMedicalAdviceNotice',
    defaultMessage:
      'If you’re concerned about these symptoms, you should seek medical advice.',
  },
  possibleCausesLink: {
    id: 'possibleCauses.link',
    defaultMessage: 'See how we got to these results',
  },
  summaryTitle: {
    id: 'assessmentOverview.summaryTitle',
    defaultMessage: 'Your summary',
  },
  explanationTitle: {
    id: 'assessmentOverview.explanationTitle',
    defaultMessage: 'Why do I suggest this?',
  },
})
