import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { sortBy } from 'lodash'
import {
  BulletList,
  BulletListItem,
  Heading,
  Link,
} from '@babylon/web-platform-ui'

import { getRecentProfiles } from '../../api/localStorage'

import styles from './RecentProfiles.module.scss'

const LIMIT = 5

export default function RecentProfiles() {
  const [recentProfiles, setRecentProfiles] = useState([])
  const location = useLocation()

  useEffect(() => {
    setRecentProfiles(
      sortBy(getRecentProfiles(), (p) => p.lastAccessed * -1).slice(0, LIMIT)
    )
  }, [])

  if (recentProfiles.length === 0) {
    return null
  }

  return (
    <div>
      <div className={styles.heading}>
        <Heading size="sm">Recent Profiles</Heading>
      </div>
      <BulletList as="ul">
        {recentProfiles.map((profile) => {
          const { pathname } = profile

          return (
            <BulletListItem key={pathname}>
              <Link href={`${pathname}${location.search}`}>{pathname}</Link>
            </BulletListItem>
          )
        })}
      </BulletList>
    </div>
  )
}
