const messageTypes = {
  CARD: 'card',
  CHOICES: 'choices',
  DATE: 'date',
  PHONE: 'phone',
  SYMPTOM: 'symptom',
  TEXT: 'text',
  TEXT_AND_FILES: 'text_and_files',
}

export default messageTypes
