import React from 'react'
import { Select as UIComponentSelect } from '@babylon/web-platform-ui'

const Select = ({ error, label, name, onChange, options, value }: Props) => (
  <UIComponentSelect
    errorMessage={error}
    label={label}
    name={name}
    // @ts-ignore
    onChange={onChange}
    value={value}
  >
    {options.map((option) => (
      <option key={option.label} value={option.value}>
        {option.label}
      </option>
    ))}
  </UIComponentSelect>
)

interface Props
  extends Pick<
    React.ComponentProps<typeof UIComponentSelect>,
    'label' | 'name'
  > {
  error?: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: {
    label: string
    value: string
  }[]
  value: string
}

export default Select
