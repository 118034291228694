import React from 'react'
import { Link } from '@babylon/web-platform-ui'

const LinkWrapper = ({ children, className, href, style, testId }: Props) => {
  const onClick = () => window.open(href, '_blank', 'noopener,noreferrer')

  if (className || style) {
    return (
      <button
        className={className}
        data-testid={testId}
        onClick={onClick}
        role="link"
        style={style}
        type="button"
      >
        {children}
      </button>
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      as="button"
      data-testid={testId}
      onClick={onClick}
      role="link"
      type="button"
    >
      {children}
    </Link>
  )
}

type Props = {
  children: React.ReactChild
  className?: string
  href: string
  style?: React.CSSProperties
  testId?: string
}

export default LinkWrapper
