/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Box, Link, Spinner, useColorTheme } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'
import styles from './PrivacySettings.module.scss'

import { QuestionInterface } from '../../constants/types'
import PrivacySettingItem, {
  OnCheckedChangeParams,
} from '../PrivacySettingItem/PrivacySettingItem'
import messages from './messages'
import PrivacyNoticeModal from '../PrivacyNoticeModal'
import {
  getPrivacySettingsData,
  PrivacySettingsDataInterface,
  setPrivacySettingData,
} from '../../questions/privacySettings/privacySettings'
import ErrorMessage from '../ErrorMessage'

type StateSettingError = Record<string, boolean> | null

const PrivacySettings = () => {
  const translate = useFormatMessage()
  const { colors } = useColorTheme()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [settingError, setSettingError] = useState<StateSettingError>(null)

  const {
    isLoading: shouldShowSpinner,
    data: privacyData,
    error: screenError,
    refetch: refetchPrivacyPreferences,
  } = useQuery<PrivacySettingsDataInterface, UseQueryError>(
    [translate],
    getPrivacySettingsData,
    { cacheTime: 0 }
  )

  const handleModalOpeningState = () => {
    setIsModalOpen((prevState) => !prevState)
  }

  const onCheckedChange = async (e: OnCheckedChangeParams) => {
    const isUpdated = await setPrivacySettingData({
      noticeVersionId: e.settingId,
      accepted: e.value,
    })

    if (!isUpdated) {
      setSettingError({
        [e.settingId]: true,
      })
    } else {
      setSettingError(null)
    }
  }

  const renderSpinner = () => (
    <div className={styles.spinnerWrapper}>
      <Spinner />
    </div>
  )

  const renderScreenErrorMessage = () => {
    if (!screenError) {
      return null
    }

    return (
      <div>
        <ErrorMessage error={screenError.message} />
        <Link
          as="button"
          type="button"
          onClick={() => refetchPrivacyPreferences()}
        >
          {translate(messages.privacySettingsReloadLink)}
        </Link>
      </div>
    )
  }

  const renderSettingsInputs = () => {
    if (privacyData?.settings?.length) {
      return privacyData?.settings?.map((field) => {
        let error

        if (settingError?.[field.name]) {
          error = {
            timestamp: Date.now(),
            message: translate(messages.privacySettingUpdateErrorMessage),
          }
        }

        return (
          <PrivacySettingItem
            key={field.name}
            label={field.label}
            data-testid={`privacy-setting-${field.name}`}
            name={field.name}
            checked={field.checked}
            linkText={field.linkText}
            shortText={field.shortText}
            modalText={field.modalText}
            errorMessage={error}
            onCheckedChange={onCheckedChange}
          />
        )
      })
    }

    return null
  }

  const renderPrivacyPolicyModalLink = () => {
    if (privacyData?.privacyPolicyText) {
      return (
        <Box
          className={styles.ModalLink}
          bgColor={colors.background.bannerGrey}
        >
          <Link
            as="button"
            type="button"
            rightIcon="Right"
            data-testid="privacy-settings-view-privacy-policy"
            onClick={handleModalOpeningState}
          >
            {translate(messages.viewPolicy)}
          </Link>
          <PrivacyNoticeModal
            isOpen={isModalOpen}
            longText={privacyData.privacyPolicyText || ''}
            onClose={handleModalOpeningState}
          />
        </Box>
      )
    }

    return null
  }

  return (
    <div>
      {shouldShowSpinner && renderSpinner()}
      {!shouldShowSpinner && renderScreenErrorMessage()}
      {!shouldShowSpinner && renderSettingsInputs()}
      {!shouldShowSpinner && renderPrivacyPolicyModalLink()}
    </div>
  )
}

interface UseQueryError {
  message: string
}

export interface PrivacySettingsProps {
  question: Partial<QuestionInterface>
}

export default PrivacySettings
