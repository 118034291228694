import { microservicesClient } from '../client'
import { ConditionInterface } from '../../constants/types'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const getConditions = ({ conversationId }: Props) => {
  const baseUrl = getBaseChatscriptUrl()

  return microservicesClient
    .get<Response>(
      `${baseUrl}/conversations/${conversationId}/content/conditions`
    )
    .then((response) => response.data.conditions)
}

interface Props {
  conversationId: string
}

type Response = {
  conditions: ConditionInterface[]
}

export default getConditions
