import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Button } from '@babylon/web-platform-ui'

import forgottenPassword from '../../../../../questions/forgottenPassword'
import { GoToQuestionInterface } from '../../../../../constants/types'
import messages from './messages'
import styles from './ForgottenPassword.module.scss'

const ForgottenPassword = ({ goToQuestion }: Props) => {
  const translate = useFormatMessage()

  const onClick = () => {
    goToQuestion(forgottenPassword(translate))
  }

  return (
    <div className={styles.container}>
      <Button isFullWidth onClick={onClick} variant="secondary">
        {translate(messages.forgottenPassword)}
      </Button>
    </div>
  )
}

interface Props {
  goToQuestion: (newQuestion: GoToQuestionInterface) => void
}

export default ForgottenPassword
