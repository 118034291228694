import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'question.registerName.heading',
    defaultMessage: 'Let’s set-up your profile, it only takes a minute.',
  },
  firstName: {
    id: 'question.registerName.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'question.registerName.lastName',
    defaultMessage: 'Last name',
  },
  submitButton: {
    id: 'question.registerName.submitButton',
    defaultMessage: 'Next',
  },
})
