import React from 'react'
import { logException } from '@babylon/sentry'

import {
  CardInterface,
  QuestionOptionInterface,
} from '../../../../constants/types'
import Banner from '../Banner'
import { useProductConfig } from '../../../../api'

const AdvicePanel = ({ cards, handleCallToAction }: Props) => {
  const { loadingQuery, data, error } = useProductConfig()
  let coronavirusBannerEnabled: boolean | undefined

  if (!loadingQuery && !error) {
    coronavirusBannerEnabled = data?.coronavirusBannerEnabled
  }

  if (error) {
    logException(error)
  }

  // This panel only displays the card which doesn't have a banner_id
  const generalCard = cards.find((card) => !card.banner_id)

  if (!coronavirusBannerEnabled || !generalCard) {
    return null
  }

  return <Banner card={generalCard} handleCallToAction={handleCallToAction} />
}

interface Props {
  cards: CardInterface[]
  handleCallToAction: (option: QuestionOptionInterface) => void
}

export default AdvicePanel
