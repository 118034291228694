import { IS_INTERNAL } from './config'

const uiConfig = {
  showAccessory: IS_INTERNAL,
  showCustomSummary: !IS_INTERNAL,
  showDisclaimer: !IS_INTERNAL,
  showHelpTips: IS_INTERNAL,
  showProfiles: IS_INTERNAL,
  showResetAction: IS_INTERNAL,
  showServices: IS_INTERNAL,
  title: IS_INTERNAL ? 'Chatbot Test UI' : 'Symptom Checker',
}

export default uiConfig
