import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { dnaTheme, ThemeProvider, Heading } from '@babylon/web-platform-ui'

import { withAdalLoginApi } from '../../auth'
import Loading from '../../components/Loading'

import Home from '../Home'
import Chatbot from '../Chatbot'
import config from '../../config'
import uiConfig from '../../uiConfig'

import styles from './styles.module.scss'

import '@babylon/fonts/Visuelt'
import '@babylon/fonts/Inter'

const App = () => (
  <BrowserRouter>
    <Helmet>
      <title>{uiConfig.title}</title>
    </Helmet>
    <div>
      <div className={styles.header}>
        <Heading as="h1">{uiConfig.title}</Heading>
      </div>

      <div className={styles.content}>
        <Route path="/" exact component={Home} />
        <Route path="/:gender/:age/:type" exact component={Chatbot} />
        <Route path="/:gender/:age/:type/:data+" exact component={Chatbot} />
      </div>
    </div>
  </BrowserRouter>
)

const Error = (err) => <div>Error {err.message}</div>

const AppWrapper = () => {
  const MainApp = config.IS_INTERNAL
    ? withAdalLoginApi(App, Loading, Error)
    : App

  return (
    <ThemeProvider theme={dnaTheme}>
      <MainApp />
    </ThemeProvider>
  )
}

export default AppWrapper
