import { APP_NAME } from '../config'

const getBaseChatscriptUrl = (memberUuid?: string) => {
  const prefix = APP_NAME === 'rwanda_web' ? 'chatscript-rw' : 'chatscript'

  return memberUuid ? `/${prefix}/v3/member/${memberUuid}` : `/${prefix}/v3`
}

// eslint-disable-next-line import/prefer-default-export
export { getBaseChatscriptUrl }
