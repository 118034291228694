import React from 'react'
import { useFormatMessage } from '@babylon/intl'

import {
  QuestionInterface,
  QuestionOptionInterface,
} from '../../constants/types'
import CallToActionButton from './CallToActionButton'
import CallToActionDisclaimer from './CallToActionDisclaimer'
import { getOptionForPGMAction } from '../../util/callToActions'

import styles from './CallToActionsAssessment.module.scss'

const CallToActionsAssessment = ({
  handleCallToAction,
  loading,
  question,
  onOptionImpression,
  selectedCtaLabel,
  setSelectedCtaLabel,
}: Props) => {
  const translate = useFormatMessage()
  const primaryAction = question.pgmReport?.triage?.action
  const primaryCallToAction = primaryAction ? (
    <div className={styles.buttonV2}>
      <CallToActionButton
        option={getOptionForPGMAction(primaryAction, translate)}
        handleCallToAction={handleCallToAction}
        isPrimary
        loading={loading}
        onOptionImpression={onOptionImpression}
        selectedCtaLabel={selectedCtaLabel}
        setSelectedCtaLabel={setSelectedCtaLabel}
      />
    </div>
  ) : null
  const primaryDisclaimer = primaryAction?.data.disclaimer ? (
    <CallToActionDisclaimer action={primaryAction} />
  ) : null

  const additionalActions = question.pgmReport?.triage?.additional_actions || []
  const additionalCallToActions = additionalActions
    ? additionalActions.map((additionalAction) => (
        <div className={styles.buttonV2} key={additionalAction.label}>
          <CallToActionButton
            option={getOptionForPGMAction(additionalAction, translate)}
            handleCallToAction={handleCallToAction}
            isPrimary={false}
            loading={loading}
            onOptionImpression={onOptionImpression}
            selectedCtaLabel={selectedCtaLabel}
            setSelectedCtaLabel={setSelectedCtaLabel}
          />
        </div>
      ))
    : []
  const options = question.options
    ? question.options.map((option) => (
        <div className={styles.buttonV2} key={option.label}>
          <CallToActionButton
            option={option}
            handleCallToAction={handleCallToAction}
            isPrimary={false}
            loading={loading}
            onOptionImpression={onOptionImpression}
            selectedCtaLabel={selectedCtaLabel}
            setSelectedCtaLabel={setSelectedCtaLabel}
          />
        </div>
      ))
    : []
  const additionalDisclaimers = additionalActions
    ? additionalActions
        .filter((additionalAction) => additionalAction?.data.disclaimer)
        .map((additionalAction) => (
          <CallToActionDisclaimer
            action={additionalAction}
            key={`${additionalAction.label}_disclaimer`}
          />
        ))
    : []

  return (
    <>
      <div className={styles.buttonListV2}>
        {primaryCallToAction}
        {additionalCallToActions}
        {options}
      </div>
      {primaryDisclaimer}
      {additionalDisclaimers}
    </>
  )
}

interface Props {
  handleCallToAction: (option: QuestionOptionInterface) => void
  loading: boolean
  question: QuestionInterface
  onOptionImpression?: (option: QuestionOptionInterface) => void
  selectedCtaLabel?: string
  setSelectedCtaLabel: (cta: string) => void
}

export default CallToActionsAssessment
