import { useIntl } from '@babylon/intl'

import messages from './messages'

const pillMessages = [
  messages.pillHeadache,
  messages.pillAbdominalPain,
  messages.pillTummyPain,
  messages.pillCough,
  messages.pillMusclePain,
  messages.pillSoreThroat,
  messages.pillEarPain,
  messages.pillDizziness,
]

const usePillMessages = () => {
  const intl = useIntl()
  const isUS = intl.locale.endsWith('US')

  if (!isUS) {
    return pillMessages
  }

  return pillMessages.filter((message) => message !== messages.pillTummyPain)
}

export default usePillMessages
