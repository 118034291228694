import { defineMessages } from 'react-intl'

export default defineMessages({
  label: {
    id: 'HelpText.label',
    defaultMessage: 'See an explanation',
  },
  submit: {
    id: 'HelpText.submit',
    defaultMessage: 'OK',
  },
})
