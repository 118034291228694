import { defineMessages } from 'react-intl'

const baseId = 'getClassricReportHeadings'

export default defineMessages({
  topDiffMoreThanOne: {
    id: `${baseId}.topDiffMoreThanOne`,
    defaultMessage:
      'People with symptoms similar to yours usually have the following conditions:',
  },
  topDiffOnlyOne: {
    id: `${baseId}.topDiffOnlyOne`,
    defaultMessage:
      'People with symptoms similar to yours usually have the following condition:',
  },
  lessLikelyMoreThanOne: {
    id: `${baseId}.lessLikelyMoreThanOne`,
    defaultMessage: 'Another possible cause of these symptoms is:',
  },
  lessLikelyOnlyOne: {
    id: `${baseId}.lessLikelyOnlyOne`,
    defaultMessage: 'Other possible causes of these symptoms include:',
  },
  notLikelyMoreThanOne: {
    id: `${baseId}.notLikelyMoreThanOne`,
    defaultMessage: 'A possible cause to your symptoms is:',
  },
  notLikelyOnlyOne: {
    id: `${baseId}.notLikelyOnlyOne`,
    defaultMessage: 'Possible causes to your symptoms are the following:',
  },
  makeQuestionHelpText: {
    id: 'makeQuestion.helpText',
    defaultMessage: 'What does this mean?',
  },
})
