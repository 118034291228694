/* to add an environment variable:

- add it as a key in this file -> `module.exports`
  - provide a fallback value for the local environment, if required
- add it as a key in `chatbot-test-ui/entrypoint.sh` -> `vars`
- add it as a key, and provide a value, in every relevant environment config here:
  https://github.com/Babylonpartners/manifests/tree/master/services/chatbot-test-ui
*/

const {
  AD_CLIENT_ID,
  AD_TENANT,
  GRAPHQL_ENDPOINT,
  MICROSERVICES_URL,
} = window.babylonConfig
const env = process.env.REACT_APP_ENV || 'dev'

const appNameQueryString = window.location.search.match(
  /app_id=([a-zA-Z_0-9-]+)/
)
const appNameOverride = appNameQueryString ? appNameQueryString[1] : null

const appLocaleQueryString = window.location.search.match(
  /app_locale=([a-zA-Z_0-9-]+)/
)
const appLocaleOverride = appLocaleQueryString ? appLocaleQueryString[1] : null

const sessionQueryString = window.location.search.match(
  /session_id=([a-zA-Z_0-9-]+)/
)
const sessionId = sessionQueryString ? sessionQueryString[1] : null

// The AD_CLIENT_ID is used to auth against Azure active directory which
// provides authentication for use with chatscript.
const finalConfig = {
  AD_CLIENT_ID: AD_CLIENT_ID || 'deb5a55f-84d2-4911-9be3-adc354ee99c1',
  AD_TENANT: AD_TENANT || '13ed09d5-4e1e-4341-83e8-86b81aad50f8',
  APP_ID: appNameOverride || 'babylon',
  APP_LOCALE: appLocaleOverride || 'en-GB',
  GRAPHQL_ENDPOINT:
    GRAPHQL_ENDPOINT ||
    `https://localhost.${env}.babylontech.co.uk:6443/graphql`,
  MICROSERVICES_URL:
    MICROSERVICES_URL || `https://services-uk.${env}.babylontech.co.uk`,
  IS_INTERNAL: sessionId === null,
}

window.__FINAL_CONFIG

module.exports = finalConfig
