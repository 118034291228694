import React, { useState } from 'react'
import { Button, Text, Radio } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'

import { QuestionOptionInterface } from '../../../constants/types'
import OptionPicker from '../../OptionPicker'
import ArrowRight from '../../ArrowRight'

import { disclaimerForOption } from './utils'
import { SelectInputProps } from './SelectInputProps'
import messages from './messages'

import styles from './SelectInputV2.module.scss'

const SelectInputV2 = (props: SelectInputProps) => {
  const {
    onSelect,
    loading,
    legend,
    navigatingBack,
    previousAnswer,
    question,
    isOutcome,
    setError,
  } = props
  const translate = useFormatMessage()
  const [selected, setSelected] = useState<QuestionOptionInterface | null>(null)

  if (isOutcome) return null

  const options = question.options || []
  const buttonOnClick = () => {
    if (!selected) {
      setError(translate(messages.errorRequired))
      return
    }
    onSelect(selected!)
  }
  const hasSelectedOptions = !!previousAnswer
  const multiChoice = options?.length > 1
  const disclaimers = options
    .filter((option) => disclaimerForOption(option))
    .map((option) => (
      <div className={styles.disclaimer} key={`${option.value}_disclaimer`}>
        <Text>{disclaimerForOption(option)}</Text>
      </div>
    ))

  return (
    <div className={styles.container}>
      {disclaimers}
      {multiChoice ? (
        <OptionPicker
          legend={legend}
          options={options}
          renderOption={(opt) => (
            <Radio
              variant="bordered"
              id={opt.value}
              label={opt.label}
              value={opt.value}
              data-testid={opt.label}
              onChange={() => {
                setSelected(opt)
                setError('')
              }}
              isChecked={selected === opt}
              isDisabled={hasSelectedOptions}
            />
          )}
          buttonIsDisabled={navigatingBack || hasSelectedOptions}
          buttonIsLoading={!navigatingBack && loading}
          buttonOnClick={buttonOnClick}
        />
      ) : (
        <Button
          data-testid="submit-button"
          isDisabled={navigatingBack || hasSelectedOptions}
          isLoading={!navigatingBack && loading}
          loadingText=""
          onClick={() => onSelect(options[0])}
          rightIcon={<ArrowRight />}
        >
          {options[0].label}
        </Button>
      )}
    </div>
  )
}

export default SelectInputV2
