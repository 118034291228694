import React, { useState } from 'react'
import PGMReport from '@babylon/pgm-report'

import { APP_LOCALE } from '../../api/config'
import {
  GoToQuestionInterface,
  QuestionInterface,
  QuestionOptionInterface,
} from '../../constants/types'
import pgmDiagnosisQuestion from '../../questions/pgmDiagnosis'
import CallToActionsAssessment from './CallToActionsAssessment'
import CallToActionsDiagnosis from './CallToActionsDiagnosis'

const PGMReportWrapper = ({
  goToQuestion,
  handleCallToAction,
  loading,
  question,
  onOptionImpression,
}: Props) => {
  const [selectedCtaLabel, setSelectedCtaLabel] = useState<string>()

  const goToDiagnosis = (diagnosisId: string) => {
    if (question.pgmReport) {
      goToQuestion(pgmDiagnosisQuestion(question.pgmReport, diagnosisId))
    }
  }

  const callToActionsAssessment = (
    <CallToActionsAssessment
      handleCallToAction={handleCallToAction}
      loading={loading}
      question={question}
      onOptionImpression={onOptionImpression}
      selectedCtaLabel={selectedCtaLabel}
      setSelectedCtaLabel={setSelectedCtaLabel}
    />
  )

  const callToActionsDiagnosis = question?.pgmReport?.diagnosis?.conditions?.reduce<{
    [key: string]: JSX.Element
  }>((accumulator, current) => {
    if (current.triage?.actions?.length) {
      accumulator[current.id] = (
        <CallToActionsDiagnosis
          actions={current.triage.actions}
          handleCallToAction={handleCallToAction}
          loading={loading}
          onOptionImpression={onOptionImpression}
          selectedCtaLabel={selectedCtaLabel}
          setSelectedCtaLabel={setSelectedCtaLabel}
        />
      )
    }

    return accumulator
  }, {})

  const callToActions = {
    assessment: callToActionsAssessment,
    diagnosis: callToActionsDiagnosis,
  }

  return (
    <PGMReport
      appLocale={APP_LOCALE}
      callToActions={callToActions}
      controlled
      diagnosisId={question.pgmDiagnosisId}
      pgmAssessment={question.pgmReport}
      onSelectDiagnosis={goToDiagnosis}
      onShowExplanation={goToDiagnosis}
    />
  )
}

interface Props {
  goToQuestion: (newQuestion: GoToQuestionInterface) => void
  handleCallToAction: (option: QuestionOptionInterface) => void
  loading: boolean
  question: QuestionInterface
  onOptionImpression?: (option: QuestionOptionInterface) => void
}

export default PGMReportWrapper
