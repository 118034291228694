import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal'
import axios from 'axios'
import { AD_TENANT, AD_CLIENT_ID } from '../config'

const GRAPH_URL = 'https://graph.microsoft.com'
const adalConfig = {
  tenant: AD_TENANT,
  clientId: AD_CLIENT_ID,
  endpoints: {
    api: GRAPH_URL,
  },
  postLogoutRedirectUri: window.location.origin,
  redirectUri: window.location.origin,
  cacheLocation: 'sessionStorage',
}
export const authContext = new AuthenticationContext(adalConfig)

export const logout = () => {
  authContext.logOut()
}

const adalApiFetch = (url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, axios, url, options)

export const getAdalUser = async () => adalApiFetch(`${GRAPH_URL}/v1.0/me`, {})

export const withAdalLoginApi = withAdalLogin(
  authContext,
  adalConfig.endpoints.api
)
