import React from 'react'
import { dnaTheme, ThemeProvider } from '@babylon/web-platform-ui'

const withTheme = <P extends object>(
  Component: (props: P) => JSX.Element | null
) => (props: P) => (
  <ThemeProvider theme={dnaTheme}>
    <Component {...props} />
  </ThemeProvider>
)

export default withTheme
