/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { Box, Link, Text, useColorTheme } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'
import styles from './PrivacySettingsNavBox.module.scss'
import {
  ChatscriptMessageInputCard,
  GoToQuestionInterface,
} from '../../constants/types'
import { getPrivacySettingsInitialQuestionData } from '../../questions/privacySettings/privacySettings'

const PrivacySettingsNavBox = ({
  data,
  onGoToQuestion,
}: PrivacySettingsNavBoxProps) => {
  const { colors } = useColorTheme()
  const translate = useFormatMessage()

  const handleOnSettingsClick = () => {
    const initialQuestionData = getPrivacySettingsInitialQuestionData(translate)
    onGoToQuestion(initialQuestionData)
  }

  return (
    <Box
      className={styles.privacySettingsNavBox}
      bgColor={colors.background.bannerGrey}
    >
      <aside data-testid="privacy-settings-nav-box">
        <Text>{data?.params?.text}</Text>
        <Link
          data-testid="privacy-settings-link"
          as="button"
          type="button"
          onClick={handleOnSettingsClick}
        >
          {data?.params?.title}
        </Link>
      </aside>
    </Box>
  )
}

export interface PrivacySettingsNavBoxProps {
  data: ChatscriptMessageInputCard
  onGoToQuestion: (newQuestion: GoToQuestionInterface) => void
}

export default PrivacySettingsNavBox
