import React from 'react'
import Linkify from 'react-linkify'
import { useFormatMessage } from '@babylon/intl'
import {
  Box,
  Heading,
  HeadingProps,
  Link,
  Text,
  useColorTheme,
  BulletList,
  BulletListItem,
} from '@babylon/web-platform-ui'

import getHeadings from '../../utils/getClassicReportHeadings'

import LinkWrapper from '../LinkWrapper'
import ConditionSummary from '../ConditionSummary'
import messages from './messages'
import { AssessmentOverviewProps } from './AssessmentOverviewProps'

import styles from './AssessmentOverviewV2.module.scss'

const H1 = (props: HeadingProps) => (
  <Heading as="h1" size="md">
    {props.children}
  </Heading>
)
const H2 = (props: HeadingProps) => (
  <Heading as="h2" size="sm">
    {props.children}
  </Heading>
)

const AssessmentOverview = ({
  assessment: { triage, diagnosis = {}, triage_enabled },
  callToActions,
  onSelectDiagnosis,
  onViewExplanation,
}: AssessmentOverviewProps) => {
  const { colors } = useColorTheme()
  const translate = useFormatMessage()

  // Legacy PGM
  const { conditions = [], unexplained_symptoms = [] } = diagnosis
  const likelyConditions = conditions.filter((d) => d.is_top_differential)
  const lessLikelyConditions = conditions.filter((d) => !d.is_top_differential)
  const headings = getHeadings(
    likelyConditions,
    lessLikelyConditions,
    translate
  )
  const unexplainedSymptoms = unexplained_symptoms.map((s) => s.layman_name)
  const hasUnexplainedSymptoms = unexplained_symptoms.length > 0

  // Triage
  let softTriage

  if (triage_enabled && triage) {
    softTriage = !!triage.soft_triage_text
  }

  return (
    <>
      <Box className={styles.disclaimer} bgColor={colors.background.bannerGrey}>
        <Text>{translate(messages.medicalAdviceDisclaimer)}</Text>
      </Box>

      {!triage_enabled && <H1>{translate(messages.summaryTitle)}</H1>}

      {softTriage && <H1>{translate(messages.headingOne)}</H1>}

      {softTriage && (
        <div className={styles.softOutcome}>
          {triage.soft_triage_text.split(/\n+/g).map((para) => (
            // @ts-ignore
            <Linkify component={LinkWrapper} key={para}>
              <div className={styles.softOutcomePara}>
                <Text>{para}</Text>
              </div>
            </Linkify>
          ))}
        </div>
      )}

      {triage_enabled && !softTriage && (
        <>
          <H2>{translate(messages.explanationTitle)}</H2>
          <Text>{triage.explanation}</Text>
        </>
      )}

      <div className={styles.possibleCauses}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link as="button" onClick={onViewExplanation} type="button">
          {translate(messages.possibleCausesLink)}
        </Link>
      </div>

      {!triage_enabled && (
        <div className={styles.secondaryHeading}>
          <H2>{headings.mostLikelyTitle}</H2>
        </div>
      )}

      {(triage_enabled ? conditions : likelyConditions).map((condition) => (
        <ConditionSummary
          key={condition.id}
          condition={condition}
          onSelectDiagnosis={onSelectDiagnosis}
          triageEnabled={triage_enabled}
          distressing={condition.might_cause_distress}
          softTriage={softTriage}
        />
      ))}

      {!triage_enabled && (
        <div className={styles.secondaryHeading}>
          <H2>{headings.lessLikelyTitle}</H2>
        </div>
      )}

      {!triage_enabled &&
        lessLikelyConditions.map((condition) => (
          <ConditionSummary
            key={condition.id}
            condition={condition}
            onSelectDiagnosis={onSelectDiagnosis}
            triageEnabled={triage_enabled}
            distressing={condition.might_cause_distress}
          />
        ))}

      {hasUnexplainedSymptoms && (
        <div className={styles.secondaryHeading}>
          <H2>{translate(messages.headingTwo)}</H2>
        </div>
      )}

      {hasUnexplainedSymptoms && (
        <BulletList>
          {unexplainedSymptoms.map((s) => (
            <BulletListItem key={s}>{s}</BulletListItem>
          ))}
          <BulletListItem>
            {translate(messages.seekMedicalAdviceNotice)}
          </BulletListItem>
        </BulletList>
      )}

      {conditions.length > 0 && (
        <div className={styles.triageDisclaimer}>
          <Text>{translate(messages.triageDisclaimer)}</Text>
        </div>
      )}

      {callToActions && <div className={styles.ctas}>{callToActions}</div>}
    </>
  )
}

export default AssessmentOverview
