import React, { ChangeEvent } from 'react'
import classNames from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { TextInput, Icon, useColorTheme } from '@babylon/web-platform-ui'

import ConditionList from './ConditionList'
import NoResults from './NoResults'
import useConditionSearch from './useConditionSearch'

import messages from './messages'
import styles from './styles.module.scss'

const ConditionSearch = () => {
  const { colors } = useColorTheme()
  const translate = useFormatMessage()
  const {
    conditions,
    isFetching,
    inputValue,
    setInputValue,
  } = useConditionSearch()

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const showNoResults = !isFetching && conditions.length === 0

  return (
    <>
      <div className={classNames(styles.inputWrapper, styles.inputWrapperV2)}>
        <TextInput
          label={translate(messages.conditionSearchLabel)}
          onChange={handleInputChange}
          value={inputValue}
          type="text"
          placeholder={translate(messages.conditionSearchPlaceholder)}
          data-testid="condition-search-input"
          rightElement={
            <button
              className={styles.clear}
              onClick={() => setInputValue('')}
              type="button"
            >
              <Icon icon="Close" color={colors.brand.secondary} />
            </button>
          }
        />
      </div>
      {showNoResults ? (
        <NoResults />
      ) : (
        <ConditionList listData={conditions} inputValue={inputValue} />
      )}
    </>
  )
}

export default ConditionSearch
