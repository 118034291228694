import { microservicesClient } from '../client'

export interface NoticeVersion {
  noticeVersionId: string
  version: number
  noticeId: string
  title: string
  summary: string
  settingsText: string
  linkText: string
  longText: string
}

export interface PrivacySetting {
  accepted: boolean
  noticeVersion: NoticeVersion
}

const getPrivacySettings = (): Promise<PrivacySetting[]> =>
  microservicesClient
    .get<PrivacySetting[]>('privacy-service/v1/me/settings')
    .then((response) => response.data)

export default getPrivacySettings
