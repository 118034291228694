export default (
  question,
  processor,
  answer,
  originalQuestion,
  conversation
) => ({
  nextQuestion: {
    ...question,
    conversationContext: {
      ...conversation.nextQuestion.conversationContext,
    },
    answerProcessorOptions: {
      ...question.answerProcessorOptions,
      processor,
      answer,
      question: originalQuestion,
    },
  },
  answeredQuestions: conversation.answeredQuestions,
})
