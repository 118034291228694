/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Box, Checkbox, Link, useColorTheme } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'
import PrivacyNoticeModal from '../PrivacyNoticeModal'
import styles from './PrivacySettingItem.module.scss'

const PrivacySettingItem = ({
  label,
  name,
  modalText,
  checked,
  linkText,
  shortText,
  errorMessage,
  onCheckedChange,
}: PrivacySettingItemProps) => {
  const [isChecked, setChecked] = useState(checked)
  const [isPrivacyItemModalOpen, setIsPrivacyItemModalOpen] = useState(false)

  const { colors } = useColorTheme()

  useEffect(() => {
    if (errorMessage) {
      setChecked((prevState) => {
        const handlerIsChecked = !prevState

        return handlerIsChecked
      })
    }
  }, [errorMessage])

  const translate = useFormatMessage()

  const onHandlePrivacyModalDisplay = () => {
    setIsPrivacyItemModalOpen((prevState) => !prevState)
  }

  const onChange = () => {
    setChecked((prevState) => {
      const handlerIsChecked = !prevState
      onCheckedChange({ settingId: name, value: handlerIsChecked })

      return handlerIsChecked
    })
  }

  const linkLabel = linkText || `${translate(messages.view)} ${label}`

  return (
    <>
      <Box
        className={styles.privacySettingItem}
        bgColor={colors.background.bannerGrey}
      >
        <Checkbox
          aria-label={label}
          label={label}
          data-testid={`privacy-setting-${name}`}
          name={name}
          isChecked={isChecked}
          hintText={shortText}
          errorMessage={errorMessage?.message}
          onChange={onChange}
        >
          <Link
            as="button"
            type="button"
            data-testid={`privacy-settings-view-${name}`}
            onClick={onHandlePrivacyModalDisplay}
          >
            {linkLabel}
          </Link>
        </Checkbox>
      </Box>
      <PrivacyNoticeModal
        isOpen={isPrivacyItemModalOpen}
        longText={modalText}
        onClose={onHandlePrivacyModalDisplay}
      />
    </>
  )
}

export interface OnCheckedChangeParams {
  settingId: string
  value: boolean
}

export interface PrivacySettingItemProps {
  label: string
  name: string
  modalText: string
  checked: boolean
  linkText: string
  shortText?: string
  errorMessage?: { timestamp: number; message: string }
  onCheckedChange: (e: OnCheckedChangeParams) => void
}

export default PrivacySettingItem
