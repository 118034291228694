import { last } from 'lodash'

import { continueConversationInterfaceProps } from '../../constants/types'
import { enableCustomAuth } from '../../util/auth'
import formatConversationStep from '../../util/formatConversationStep'
import getInProgressConversation from '../../util/getInProgressConversation'
import processChatscriptResponse from '../../util/processChatscriptResponseRest'

const continueConversation = async ({
  auth0Client,
  conversationId,
  memberUuid,
  userId,
}: continueConversationInterfaceProps) => {
  enableCustomAuth(auth0Client)

  const newConversation = await getInProgressConversation({
    conversationId,
    memberUuid,
  })

  const context = {
    conversationId: newConversation.conversation.id,
    // @ts-ignore
    elementId: last(newConversation.messages).id,
    lastEtag: newConversation.etag,
    memberUuid,
    userId,
    status: newConversation.conversation.status,
  }

  const processed = await processChatscriptResponse(context, newConversation)

  const { nextQuestion } = formatConversationStep(processed, context)

  return nextQuestion
}

export default continueConversation
