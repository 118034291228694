import React, { ReactNode } from 'react'
import {
  Icon,
  IconProps,
  useColorTheme,
  useTheme,
} from '@babylon/web-platform-ui'

const getIcon = (newIcon: IconProps['icon']): ReactNode => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColorTheme()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { config: dnaConfig } = useTheme()
  const iconColor =
    dnaConfig?.cssVarPrefix === 'rio'
      ? colors.brand.primary
      : colors.brand.secondary

  return <Icon icon={newIcon} color={iconColor} />
}

const getIconForDecision = (decision: string) => {
  switch (decision) {
    case 'GP':
    case 'GP_URGENT':
    case 'DOCTOR':
      return getIcon('MedicalSpecialist')
    case 'HOSPITAL':
      return getIcon('Hospital')
    case 'HOSPITAL_URGENT':
    case 'AMBULANCE':
      return getIcon('Ambulance')
    case 'SELF_CARE':
    case 'HOME':
      return getIcon('Home')
    case 'PHARMACY':
      return getIcon('Hospital')
    default:
      return undefined
  }
}

export default getIconForDecision
