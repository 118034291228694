import { defineMessages } from 'react-intl'

export default defineMessages({
  buttonSubmit: {
    id: 'textInput.button.submit',
    defaultMessage: 'Submit',
  },
  errorRequired: {
    id: 'textInput.errorRequired',
    defaultMessage: 'This field is required',
  },
})
