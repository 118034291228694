import { QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // This stale time is basically how long the query response is cached for -> set to 30minutes
      staleTime: 1800000,
    },
  },
})

const getQueryClient = () => queryClient

export default getQueryClient
