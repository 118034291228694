import { getConversation, getConversationMessages } from '../api'
import { NewConversation } from '../constants/types'

const getInProgressConversation = async ({
  conversationId,
  memberUuid,
}: Props): Promise<NewConversation> => {
  const [conversation, { etag, messages }] = await Promise.all([
    getConversation({ conversationId, memberUuid }),
    getConversationMessages({ conversationId, memberUuid }),
  ])

  return {
    conversation,
    etag,
    messages: messages.reverse(),
  }
}

interface Props {
  conversationId: string
  memberUuid?: string
}

export default getInProgressConversation
