import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Heading, Link } from '@babylon/web-platform-ui'

import AnonymousChatbot from '../../components/AnonymousChatbot'
import uiConfig from '../../uiConfig'
import { addRecentProfile } from '../../api/localStorage'

export default function ChatbotView({ match }) {
  const location = useLocation()
  const {
    params: { age, data, gender, type },
  } = match

  useEffect(() => {
    const { pathname } = location

    addRecentProfile(pathname)
  }, [location])

  return (
    <div>
      {uiConfig.showResetAction && (
        <Heading size="sm">
          <span>
            {gender}, {age}, {type}
            {data ? `, ${data}` : ''} -{' '}
          </span>
          <Link href={`/${location.search}`}>reset</Link>
        </Heading>
      )}

      <AnonymousChatbot age={age} data={data} gender={gender} type={type} />
    </div>
  )
}
