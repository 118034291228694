import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'privacySettings.heading',
    defaultMessage: 'Privacy preferences',
  },
  view: {
    id: 'privacySettings.view',
    defaultMessage: 'View',
  },
  viewPolicy: {
    id: 'privacySettings.viewPolicy',
    defaultMessage: 'View Privacy Policy',
  },
  privacySettingsReloadLink: {
    id: 'privacySettings.privacySettingsReloadLink',
    defaultMessage: 'Reload privacy preferences',
  },
  privacySettingUpdateErrorMessage: {
    id: 'privacySettings.privacySettingUpdateErrorMessage',
    defaultMessage: 'Network error: Unable to update setting',
  },
})
