import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'question.registerAbout.heading',
    defaultMessage:
      'Hi {name}, good to meet you.\n\nWe’re going to be asking you a few more questions in order to provide you with the most accurate medical advice.',
  },
  country: {
    id: 'question.registerAbout.country',
    defaultMessage: 'Country',
  },
  dateOfBirth: {
    id: 'question.registerAbout.dateOfBirth',
    defaultMessage: 'Date of birth',
  },
  gender: {
    id: 'question.registerAbout.gender',
    defaultMessage: 'Gender',
  },
  genderMale: {
    id: 'question.registerAbout.genderMale',
    defaultMessage: 'Male',
  },
  genderFemale: {
    id: 'question.registerAbout.genderFemale',
    defaultMessage: 'Female',
  },
  submitButton: {
    id: 'question.registerAbout.submitButton',
    defaultMessage: 'Next',
  },
})
