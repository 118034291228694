import React, { lazy, Suspense } from 'react'
import LazyFallback from '../../LazyFallback'

// This is used to split the react-google-maps library to it's own chunk, only used by less than 1% of users
const DynamicNearbyPlacesMapInput = lazy(
  () =>
    import(
      /* webpackChunkName: "nearby-places-map-input" */ './NearbyPlacesMapInput'
    )
)

const LazyInput = ({ ...props }) => (
  <Suspense fallback={<LazyFallback />}>
    <DynamicNearbyPlacesMapInput {...props} />
  </Suspense>
)

export default LazyInput
