import { logException } from '@babylon/sentry'

import handleChatscriptError from '../util/handleChatscriptError'
import { inputTypes, messageTypes } from '../constants'

import { replyWithMessage, getFeedbackModalData } from '../api'

const MESSAGE_TYPE_FROM_INPUT_TYPE = {
  [inputTypes.DATE_SELECT]: messageTypes.DATE,
  [inputTypes.DRAFT]: messageTypes.TEXT_AND_FILES,
  [inputTypes.MULTI_SELECT]: messageTypes.CHOICES,
  [inputTypes.PHONE_INPUT]: messageTypes.PHONE,
  [inputTypes.SELECT]: messageTypes.CHOICES,
  [inputTypes.SYMPTOM_SELECT]: messageTypes.SYMPTOM,
  [inputTypes.TEXT]: messageTypes.TEXT,
}

const chatscriptProcessor = () => async (conversation, answer, translate) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const question = conversation.nextQuestion
  const context = question.conversationContext
  const { conversationId, lastEtag, memberUuid } = context

  let replyMessageType =
    MESSAGE_TYPE_FROM_INPUT_TYPE[question.question.type] || messageTypes.TEXT

  if (replyMessageType === messageTypes.SYMPTOM && !answer.text) {
    replyMessageType = messageTypes.CARD
  }

  let conversationStep

  try {
    conversationStep = await replyWithMessage({
      conversationId,
      etag: lastEtag,
      memberUuid,
      replyMessageType,
      answer,
      context,
    })
  } catch (error) {
    return handleChatscriptError({
      context,
      chatscriptError: error,
      conversation,
      translate,
    })
  }

  const { nextQuestion, createdElementId, feedbackUrl } = conversationStep

  let feedbackModalData

  if (feedbackUrl) {
    await getFeedbackModalData(feedbackUrl)
      .then((res) => {
        feedbackModalData = res.data
      })
      .catch((error) => {
        logException(error)
      })
  }

  return {
    nextQuestion: { ...nextQuestion, feedbackModalData },
    answeredQuestions: [
      ...conversation.answeredQuestions,
      {
        question: {
          ...question,
          conversationContext: {
            ...question.conversationContext,
            createdElementId,
          },
        },
        answer,
      },
    ],
  }
}

export default chatscriptProcessor
