import React from 'react'
import { useFormatMessage } from '@babylon/intl'

import { LinkifyTextV2 } from '../../LinkifyText'

import messages from './messages'
import styles from './TextQuestionV2.module.scss'

const TextQuestionV2 = ({
  heading,
  id,
  isOutcome,
  paragraphs,
  error,
}: Props) => {
  const translate = useFormatMessage()
  const ariaLabel = error
    ? translate(messages.errorLabel, { heading })
    : undefined

  return (
    <div id={id} className={styles.questionContainer}>
      <div className={styles.headingV2}>
        <LinkifyTextV2 type={isOutcome ? 'h2' : 'h1'} aria-label={ariaLabel}>
          {heading}
        </LinkifyTextV2>
      </div>
      {paragraphs.map((para) => (
        <div className={styles.bodyV2} key={para}>
          <LinkifyTextV2 type="body">{para}</LinkifyTextV2>
        </div>
      ))}
    </div>
  )
}

interface Props {
  heading: string
  id?: string
  isOutcome?: boolean
  paragraphs: string[]
  error?: string
}

export default TextQuestionV2
