import React, { useState } from 'react'
import { Checkbox, Link, Text } from '@babylon/web-platform-ui'

import { PrivacyNoticeInterface } from '../../../../../constants/types'
import PrivacyNoticeModal from '../../../../PrivacyNoticeModal'

import styles from './TermsAndConditions.module.scss'

const TermsAndConditions = ({
  error,
  onChange,
  policy,
  privacyPolicy,
}: Props) => {
  const [checked, setChecked] = useState(false)
  const [selectedNotice, setSelectedNotice] = useState<PrivacyNoticeInterface>()

  const updateChecked = () => {
    setChecked((prevState) => {
      const isChecked = !prevState
      onChange(isChecked)

      return isChecked
    })
  }

  return (
    <>
      <Checkbox
        className={styles.checkbox}
        data-testid="terms_and_conditions"
        errorMessage={error}
        isChecked={checked}
        label={
          <>
            {policy.onboardingText}{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              as="button"
              onClick={() => setSelectedNotice(policy)}
              type="button"
            >
              {policy.linkText}
            </Link>
          </>
        }
        name="terms_and_conditions"
        onChange={updateChecked}
      />
      <div className={styles.linkText}>
        <Text>
          {privacyPolicy.onboardingText}{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as="button"
            onClick={() => setSelectedNotice(privacyPolicy)}
            type="button"
          >
            {privacyPolicy.linkText}
          </Link>
        </Text>
      </div>
      <PrivacyNoticeModal
        isOpen={!!selectedNotice}
        longText={selectedNotice?.longText!}
        onClose={() => setSelectedNotice(undefined)}
      />
    </>
  )
}

interface Props {
  error?: string
  onChange: (e: any) => any
  policy: PrivacyNoticeInterface
  privacyPolicy: PrivacyNoticeInterface
}

export default TermsAndConditions
