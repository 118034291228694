import React, { useState } from 'react'

import { Button } from '@babylon/web-platform-ui'

import TrackOption from '../TrackOption'
import { QuestionOptionInterface } from '../../constants/types'

import styles from './OptionList.module.scss'

const OptionList = ({
  options,
  onSelect,
  navigatingBack,
  onOptionImpression,
  loading,
}: OptionsListProps) => {
  const [selected, setSelected] = useState<QuestionOptionInterface | null>(null)

  return (
    <div className={styles.optionList}>
      {options.map((option) => (
        <TrackOption
          key={option.value}
          option={option}
          onOptionImpression={onOptionImpression}
        >
          <Button
            variant="secondary"
            data-testid={option.label}
            isDisabled={navigatingBack || selected?.value === option.value}
            isFullWidth
            isLoading={
              !navigatingBack && selected?.value === option.value && loading
            }
            onClick={() => {
              setSelected(option)
              onSelect(option)
            }}
          >
            {option.label}
          </Button>
        </TrackOption>
      ))}
    </div>
  )
}

interface OptionsListProps {
  options: QuestionOptionInterface[]
  onOptionImpression?: (option: QuestionOptionInterface) => void
  onSelect: (option: QuestionOptionInterface) => void
  navigatingBack?: boolean
  loading?: boolean
}

export default OptionList
