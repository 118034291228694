import React from 'react'

import ConfigureChatbot from '../../components/ConfigureChatbot'
import Disclaimer from '../../components/Disclaimer'
import HelpTips from '../../components/HelpTips'
import RecentProfiles from '../../components/RecentProfiles'
import uiConfig from '../../uiConfig'
import styles from './Home.module.scss'

const App = () => (
  <main className={styles.main}>
    <ConfigureChatbot />

    {uiConfig.showProfiles && <RecentProfiles />}
    {uiConfig.showDisclaimer && <Disclaimer />}
    {uiConfig.showHelpTips && <HelpTips />}
  </main>
)

export default App
