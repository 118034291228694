import { microservicesClient } from '../client'

export interface NoticeVersion {
  noticeVersionId: string // UUID
  version: number
  noticeId: string // UUID
  title: string // eg. 'Privacy Policy'
  summary: string // HTML formatted string
  longText: string // HTML formatted string
  acceptButtonText: string // eg. 'Got it'
  declineButtonText: string
  readMoreButtonText: string // eg. 'Learn more'
  popupTitle: string
  popupText: string
  chatbotText: string
  updateTitle: string // eg. 'We've updated our Privacy Policy'
  updateText: string // eg. 'Find out how Babylon collects, uses and manages your data in our Privacy Policy.'
  onboardingText: string // eg. 'Find out how Babylon collects, uses and manages your data in our'
  settingsText: string
  linkText: string // eg. 'Privacy Policy'
  errorTitle: string
  errorText: string
  locale: string // eg. 'en'
  createdAt: string // eg. '2021-08-31T16:26:52.746565'
  deletedAt: string
  mandatory: boolean
  defaultLocale: string // eg. 'en'
  defaultDecision: boolean
  noticeType: string // eg. 'Privacy Policy'
}

export enum NoticeVersionName {
  PrivacyPolicy = 'PRIVACY_POLICY',
}

const getPrivacyNoticeVersionByName = (
  name = NoticeVersionName.PrivacyPolicy
) => {
  const url = `/privacy-service/v1/noticeVersions/named/${name}`

  return microservicesClient
    .get<NoticeVersion>(url)
    .then((response) => response.data)
}

export default getPrivacyNoticeVersionByName
