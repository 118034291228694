import { defineMessages } from 'react-intl'

export default defineMessages({
  possibleCausesHeader: {
    id: 'possibleCauses.header',
    defaultMessage: 'Possible Causes',
  },
  possibleCausesParagraphOne: {
    id: 'possibleCauses.paragraphOne',
    defaultMessage: `The possible causes are those that the Babylon algorithm suggests as
    most likely, given the answers you entered. They are listed in the order
    of their match to your answers and how common the causes are. The order
    is not an indication of your individual likelihood of having the
    condition.`,
  },
  possibleCausesParagraphTwo: {
    id: 'possibleCauses.paragraphTwo',
    defaultMessage: `Possible causes are for information only, and are not a medical
    diagnosis or a replacement for seeing a doctor.`,
  },
  possibleCausesParagraphThree: {
    id: 'possibleCauses.paragraphThree',
    defaultMessage: `If you are at all concerned about your health, please seek medical
    advice.`,
  },
})
