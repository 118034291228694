import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

const sendFeedback = (translate: IntlFormatters['formatMessage']) => {
  const feedbackOptions = [
    translate(messages.optionOne),
    translate(messages.optionTwo),
    translate(messages.optionThree),
    translate(messages.optionFour),
    translate(messages.optionFive),
    translate(messages.optionSix),
  ]

  return {
    question: {
      fields: [
        {
          label: translate(messages.subHeading),
          name: 'issue',
          options: feedbackOptions.map((option) => ({
            label: option,
            value: option,
          })),
          type: 'radio',
        },
        {
          label: translate(messages.textFieldLabel),
          name: 'comment',
          sideEffect: (formState: any) => {
            if (!formState.issue.value) {
              return {
                ...formState,
                issue: {
                  error: null,
                  valid: true,
                  value: translate(messages.optionSix),
                },
              }
            }

            return formState
          },
          type: 'textarea',
        },
        {
          label: translate(messages.buttonSend),
          props: {
            isResponsive: true,
          },
          type: 'submit',
        },
      ],
      text: translate(messages.heading),
      type: inputTypes.FORM,
      undoable: true,
    },
    answerProcessorName: 'userFeedbackProcessor',
    answerProcessorOptions: {},
  }
}

export default sendFeedback
