import { event, initialize } from 'react-ga'
import { EventTracker, EventTrackerParams } from '@babylon/tracking/gtag'

import { GOOGLE_TRACKING_ID, TRACKING_ENABLED } from '../../api/config'

const canBeTriggered = !!GOOGLE_TRACKING_ID && TRACKING_ENABLED

let trackEventOverride: EventTracker

const initTracking = () => {
  if (canBeTriggered) {
    initialize(GOOGLE_TRACKING_ID)
  }
}

const setTrackEventOverride = (_trackEventOverride: EventTracker) => {
  trackEventOverride = _trackEventOverride
}

const trackEvent = (args: EventTrackerParams) => {
  if (trackEventOverride) {
    trackEventOverride({ ...args })
  } else if (canBeTriggered) {
    event({ ...args })
  }
}

export { initTracking, setTrackEventOverride, trackEvent }
