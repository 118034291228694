/* eslint jsx-a11y/no-onchange: 0 */
import React from 'react'

// TODO: [TSC-521]: replace with phone input from web-platform-ui
import { PhoneInput } from '@babylon/ui'

import CheckboxInput from './fields/CheckboxInput'
import DateInput from './fields/DateInput'
import ForgottenPassword from './fields/ForgottenPassword'
import TermsAndConditions from './fields/TermsAndConditions'
import SubmitButton from './fields/SubmitButton'
import WelcomeScreen from './fields/WelcomeScreen'
import LoginLink from './fields/LoginLink'
import Paragraph from './fields/Paragraph'
import Pills from './fields/Pills'
import RadioGroup from './fields/RadioGroup'
import Select from './fields/Select'
import Textarea from './fields/Textarea'
import TextInput from './fields/TextInput'

import styles from './styles.module.scss'

const componentMap = {
  checkbox: CheckboxInput,
  select: Select,
  radio: RadioGroup,
  pills: Pills,
  date: DateInput,
  phone: PhoneInput,
  forgotten_password: ForgottenPassword,
  terms_and_conditions: TermsAndConditions,
  paragraph: Paragraph,
  textarea: Textarea,
  submit: SubmitButton,
  welcome_screen: WelcomeScreen,
  login_link: LoginLink,
}

const createOnChangeFn = (onChange, { name }) => (value) => {
  let onChangeArg

  if (value.target) {
    // Is an event
    onChangeArg = {
      ...value,
      target: { ...value.target, name, value: value.target.value },
    }
  } else {
    // Is just the value
    onChangeArg = { target: { name, value } }
  }

  return onChange(onChangeArg)
}

class DynamicForm extends React.Component {
  constructor(props) {
    super()

    this.state = this.mapFieldsToState(props.question.fields)
  }

  componentDidMount() {
    this.onFieldUpdate()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.onFieldUpdate()
    }
  }

  onFieldUpdate = () => {
    const simplifiedFields = Object.keys(this.state).reduce((fields, field) => {
      fields[field] = this.state[field].value

      return fields
    }, {})

    this.props.onValueChange(simplifiedFields)
  }

  onSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }

    this.props.onSubmit()
  }

  mapFieldsToState = (fields) =>
    fields.reduce((stateFields, field) => {
      if (field.name) {
        stateFields[field.name] = {
          valid: true,
          value: field.value || '',
          error: field.error,
          sideEffect: field.sideEffect,
        }
      }

      return stateFields
    }, {})

  updateField = (e) => {
    const currentState = this.state[e.target.name]
    const sideEffect = currentState.sideEffect || ((state) => state)
    const updatedState = sideEffect({
      ...this.state,
      [e.target.name]: {
        ...currentState,
        valid: true,
        value: e.target.value,
        error: this.state[e.target.name].error,
      },
    })

    this.setState(updatedState, () => this.onFieldUpdate())
  }

  updateFields = (fields, cb) => {
    this.setState(
      {
        ...this.state,
        ...Object.keys(fields).reduce((updated, key) => {
          updated[key] = {
            valid: true,
            value: fields[key],
            error: this.state[key].error,
          }

          return updated
        }, {}),
      },
      () => {
        this.onFieldUpdate()

        if (typeof cb === 'function') {
          setImmediate(cb)
        }
      }
    )
  }

  getAdditionalProps = ({ field, goToQuestion, loading }) => {
    let additionalPropsForType = {}

    if (field.type === 'submit') {
      additionalPropsForType = {
        loading,
        'data-testid': 'dynamic-form-submit-button',
      }
    } else if (field.type === 'radio') {
      additionalPropsForType = {
        className: null,
        multiSelect: false,
      }
    } else if (field.type === 'pills') {
      additionalPropsForType = {
        multiSelect: false,
      }
    } else if (field.type === 'textarea') {
      additionalPropsForType = {
        'data-testid': 'dynamic-form-textarea',
      }
    } else if (field.type === 'forgotten_password') {
      additionalPropsForType = {
        goToQuestion,
      }
    } else if (field.type === 'welcome_screen') {
      additionalPropsForType = {
        loading,
        goToQuestion,
        submitForm: this.onSubmit,
      }
    } else if (field.type === 'login_link') {
      additionalPropsForType = {
        goToQuestion,
      }
    } else if (field.type === 'password') {
      additionalPropsForType = {
        autoComplete: 'off',
        helpText: field.tip,
      }
    }

    if (field.props) {
      additionalPropsForType = {
        ...additionalPropsForType,
        ...field.props,
      }
    }

    return additionalPropsForType
  }

  render() {
    const {
      goToQuestion,
      loading,
      onSubmit,
      question,
      setError,
      style,
    } = this.props

    return (
      <form
        key="formInput"
        className={styles.form}
        style={style}
        onSubmit={this.onSubmit}
        noValidate
      >
        {question.fields.map((field) => {
          const { name, type, error } = field
          const fieldInState = this.state[name]
          const Component = componentMap[type] || TextInput
          const additionalPropsForType = this.getAdditionalProps({
            field,
            goToQuestion,
            loading,
            onSubmit,
          })
          const fieldKey = `${name}-${type}`
          const fieldValue = fieldInState ? fieldInState.value : ''
          const fieldErrors = error ? [error] : []
          const onFieldChange = createOnChangeFn(this.updateField, field)

          return (
            <Component
              {...field}
              key={fieldKey}
              className={styles.field}
              onChange={onFieldChange}
              value={fieldValue}
              errors={fieldErrors}
              setError={setError}
              {...additionalPropsForType}
            />
          )
        })}
      </form>
    )
  }
}

DynamicForm.defaultProps = {
  submitLabel: 'Confirm',
}

export default DynamicForm
