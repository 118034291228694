import { rubyClient } from '../client'

const resetPassword = (email) =>
  rubyClient.post('/api/v1/passwords', {
    patient: {
      email,
    },
  })

export default resetPassword
