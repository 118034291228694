import {
  getIframeCookie,
  kongTokenIsValid,
  kongTokenRefreshDue,
  login as authLogin,
  logout as authLogout,
  refreshKongToken,
  refreshTokenIsValid,
} from '@babylon/babylon-auth'

let userId: number | null = null

export const login = async (email: string, password: string) => {
  const response = await authLogin(email, password, 'Patient')

  userId = response.data.userId
}

export const logout = async () => {
  userId = null
  await authLogout()
}

export const tokenIsValid = async () => {
  const autologinInfo = await getIframeCookie('autologin_info')

  return kongTokenIsValid('patient', autologinInfo)
}

export const activeUser = async () => userId

export const tokenCanBeRefreshed = async () => {
  const autologinInfo = await getIframeCookie('autologin_info')

  return refreshTokenIsValid('patient', autologinInfo)
}

export const tokenRefreshDue = async () => {
  const autologinInfo = await getIframeCookie('autologin_info')

  kongTokenRefreshDue('patient', autologinInfo)
}

export const refreshToken = async () => {
  const response = await refreshKongToken('patient')

  userId = response.data.userId
}

export const requestHeaders = async () => {
  const autologinInfo = (await getIframeCookie('autologin_info')) || {}

  return autologinInfo.csrf_token
    ? { 'X-Security-Token': autologinInfo.csrf_token }
    : {}
}
