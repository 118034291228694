import React from 'react'
import { BabylonIntl } from '@babylon/intl'

import { APP_LOCALE } from '../../api/config'

const pluralRules = (language: string) =>
  // @ts-ignore
  Intl.PluralRules
    ? Promise.resolve()
    : Promise.all([
        import(
          /* webpackChunkName: "intl-pluralrules-polyfill" */
          '@formatjs/intl-pluralrules/polyfill'
        ),
        import(
          /* webpackInclude: /\/(en|zh)\.js/i */
          /* webpackChunkName: "intl-pluralrules.[request]" */
          `@formatjs/intl-pluralrules/locale-data/${language}`
        ),
      ])

const relativeTimeFormat = (language: string) =>
  Intl.RelativeTimeFormat
    ? Promise.resolve()
    : Promise.all([
        import(
          /* webpackChunkName: "intl-relativetimeformat-polyfill" */
          '@formatjs/intl-relativetimeformat/polyfill'
        ),
        import(
          /* webpackInclude: /\/(en|zh)\.js/i */
          /* webpackChunkName: "intl-relativetimeformat.[request]" */
          `@formatjs/intl-relativetimeformat/locale-data/${language}`
        ),
      ])

const getMessagesOnLocaleChange = async (locale: string) => {
  try {
    const language = locale.split('_')[0]
    const [messagesData] = await Promise.all([
      import(
        /* webpackChunkName: "messages.[request]" */
        `../../messages/${locale.replace('-', '_')}.json`
      ),
      pluralRules(language),
      relativeTimeFormat(language),
    ])

    if (messagesData && messagesData.default) {
      return messagesData.default
    }
  } catch (error) {
    console.error(error)
  }

  return {}
}

const withIntl = <P extends object>(
  Component: (props: P) => JSX.Element | null
) => (props: P) => (
  <BabylonIntl
    textComponent="span"
    initialLocale={APP_LOCALE}
    getMessagesOnLocaleChange={getMessagesOnLocaleChange}
  >
    <Component {...props} />
  </BabylonIntl>
)

export default withIntl
