import axios from 'axios'

let loggedIn = false
let accessToken: string = ''
let tokenExpiry: number = 0

export const login = async () => {
  loggedIn = true
}

export const logout = () => {
  loggedIn = false
}

export const tokenIsValid = () => loggedIn

export const activeUser = () => null

export const tokenCanBeRefreshed = tokenIsValid

export const tokenRefreshDue = () => false

export const refreshToken = () => {}

const getToken = async () => {
  if (!accessToken || tokenExpiry <= Date.now()) {
    const { data } = await axios.post(
      `${window?.canvasLauncher?.auth0Url}/oauth/token`,
      {
        grant_type: 'refresh_token',
        client_id: window?.canvasLauncher?.clientId,
        refresh_token: window?.canvasLauncher?.refreshToken,
        scope: 'clinical offline_access',
      }
    )
    accessToken = data.access_token
    tokenExpiry = Date.now() + data.expires_in * 1000
    return accessToken
  } else {
    return accessToken
  }
}

export const requestHeaders = async () => {
  // Automatically refreshes upon expiry or uses previous one in memory
  const token = await getToken()
  const headers = token ? { Authorization: `Bearer ${token}` } : {}

  return headers
}

export const insideCanvasLauncher = () => !!window?.canvasLauncher
