import { logException } from '@babylon/sentry'

import { microservicesClient } from '../client'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const reportUserFeedback = ({
  comment,
  conversationId,
  elementId,
  issue,
  memberUuid,
}: Props) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .post<Response>(
      `${baseUrl}/conversations/${conversationId}/messages/${elementId}/report`,
      {
        issue,
        comment: comment || '',
      }
    )
    .then((response) => response.data)
    .catch((error) => {
      logException(error)
    })
}

interface Props {
  comment?: string
  conversationId: string
  elementId: string
  issue: string
  memberUuid?: string
}

type Response = null

export default reportUserFeedback
