import React from 'react'
import { Text } from '@babylon/web-platform-ui'

import styles from './Paragraph.module.scss'

const Paragraph = ({ label }: Props) =>
  label
    .split(/\n+/)
    .filter(Boolean)
    .map((line) => (
      <div className={styles.paragraph} key={line}>
        <Text>{line}</Text>
      </div>
    ))

interface Props {
  label: string
}

export default Paragraph
