import React, { AriaAttributes } from 'react'
import Linkify from 'react-linkify'
import { Heading, Text } from '@babylon/web-platform-ui'

import LinkWrapper from '../LinkWrapper'
import { matchDecorator } from './utils'

const LinkifyWrapper = ({ children }: { children: React.ReactNode }) => (
  <Linkify
    componentDecorator={(decoratedHref, decoratedText, key) => (
      <LinkWrapper href={decoratedHref} key={key}>
        {decoratedText}
      </LinkWrapper>
    )}
    matchDecorator={matchDecorator}
  >
    {children}
  </Linkify>
)

interface LinkifyTextV2Props extends AriaAttributes {
  type: 'body' | 'h1' | 'h2'
  children: React.ReactNode
}

export const LinkifyTextV2 = ({
  type,
  children,
  'aria-label': ariaLabel,
}: LinkifyTextV2Props) => {
  if (type !== 'body') {
    return (
      <Heading as={type} size="sm" aria-label={ariaLabel}>
        <LinkifyWrapper>{children}</LinkifyWrapper>
      </Heading>
    )
  }

  return (
    <Text aria-label={ariaLabel}>
      <LinkifyWrapper>{children}</LinkifyWrapper>
    </Text>
  )
}

export default LinkifyTextV2
