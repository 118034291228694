import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getConditions } from '../../../api'
import { ConditionByLetter } from '../../../constants/types'
import { currentConversationId } from '../../../redux/selectors'

import {
  groupConditionsByLetter,
  filterConditionsByLetter,
  reduceConditionsToList,
} from './utils'

const useConditionSearch = () => {
  const [inputValue, setInputValue] = useState('')
  const [isFetching, setIsFetching] = useState(true)
  const [
    conditionsByLetter,
    setConditionsByLetter,
  ] = useState<ConditionByLetter>({})
  const conversationId = useSelector(currentConversationId)

  useEffect(() => {
    getConditions({ conversationId })
      .then((response) => {
        setConditionsByLetter(groupConditionsByLetter(response))
      })
      .finally(() => setIsFetching(false))
  }, [conversationId])

  const filteredConditionsByLetter = inputValue
    ? filterConditionsByLetter(conditionsByLetter, inputValue)
    : conditionsByLetter

  return {
    conditions: reduceConditionsToList(filteredConditionsByLetter, inputValue),
    isFetching,
    inputValue,
    setInputValue,
  }
}

export default useConditionSearch
