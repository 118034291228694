import React from 'react'
import {
  PasswordInput as UIComponentPasswordInput,
  TextInput as UIComponentTextInput,
} from '@babylon/web-platform-ui'

import styles from './TextInput.module.scss'

const TextInput = ({ error, label, name, onChange, type, value }: Props) => {
  const Component =
    type === 'password' ? UIComponentPasswordInput : UIComponentTextInput

  return (
    <div className={styles.container}>
      <Component
        errorMessage={error}
        label={label}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
      />
    </div>
  )
}

interface Props
  extends Pick<
    React.ComponentProps<typeof UIComponentTextInput>,
    'label' | 'name' | 'onChange' | 'type' | 'value'
  > {
  error?: string
}

export default TextInput
