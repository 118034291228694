import React, { Fragment, ReactNode } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Box, Button, Divider, useColorTheme } from '@babylon/web-platform-ui'

import { QuestionOptionInterface } from '../../constants/types'
import ArrowRight from '../ArrowRight'
import messages from './messages'

import styles from './OptionPicker.module.scss'

const OptionPicker = ({
  buttonIsDisabled,
  buttonIsLoading,
  buttonOnClick,
  legend,
  options,
  renderOption,
}: Props) => {
  const translate = useFormatMessage()
  const { colors } = useColorTheme()

  return (
    <div className={styles.container}>
      <fieldset className={styles.fieldset}>
        <Box
          className={styles.content}
          backgroundColor={colors.background.backgroundPrimary}
        >
          {legend && <legend className={styles.legend}>{legend}</legend>}
          <div className={styles.group}>
            {options.map((option) => (
              <Fragment key={option.label}>
                <div className={styles.optionItem}>{renderOption(option)}</div>
                <Divider variant="thin" />
              </Fragment>
            ))}
          </div>

          <Button
            data-testid="submit-button"
            isDisabled={buttonIsDisabled}
            isLoading={buttonIsLoading}
            loadingText=""
            onClick={buttonOnClick}
            rightIcon={<ArrowRight />}
          >
            {translate(messages.optionPickerSubmitButton)}
          </Button>
        </Box>
      </fieldset>
    </div>
  )
}

interface Props {
  legend?: string
  options: QuestionOptionInterface[]
  buttonIsDisabled?: boolean
  buttonIsLoading?: boolean
  buttonOnClick: () => void
  renderOption: (option: QuestionOptionInterface) => ReactNode
}

export default OptionPicker
