import React from 'react'
import { Text } from '@babylon/web-platform-ui'

import styles from './styles.module.scss'

const UserTestingSummaryAction = () => (
  <div className={styles.wrapper}>
    <Text>Thank you, this is the end of the symptom check.</Text>
    <Text>Please continue with the next task.</Text>
  </div>
)

export default UserTestingSummaryAction
