import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ChatbotWeb from '@babylon/chatbot-web'
import { Spinner } from '@babylon/web-platform-ui'

import { startConversation } from '../../api/chatbot'
import { ConversationTypes } from '../../constants/enums'
import ChatbotAccessory from '../ChatbotAccessory'
import UserTestingSummaryAction from '../UserTestingSummaryAction'
import uiConfig from '../../uiConfig'

import styles from './styles.module.scss'

const getHealthcheckData = (flowId) => ({
  flags: {
    skip_previously_answered: false,
  },
  flow_id: flowId,
})

const AnonymousChatbot = ({ age, data, gender, type }) => {
  const location = useLocation()
  const [initialQuestion, setInitialQuestion] = useState(null)

  useEffect(() => {
    let conversationData = null

    if (type === ConversationTypes.HEALTHCHECK) {
      conversationData = getHealthcheckData(data)
    }

    startConversation(age, gender, type, conversationData).then(
      setInitialQuestion
    )
  }, [age, data, gender, type])

  const resetChatbot = () => {
    window.location.href = `/${location.search}`
  }

  let SummaryAction

  const Accessory = ChatbotAccessory

  if (uiConfig.showCustomSummary) {
    SummaryAction = UserTestingSummaryAction
  }

  return initialQuestion ? (
    <div className={styles.chatbot}>
      <ChatbotWeb
        Accessory={uiConfig.showAccessory && Accessory}
        initialQuestion={initialQuestion}
        onGoToStart={resetChatbot}
        startInOverlay
        SummaryAction={SummaryAction}
        wrappers={{ redux: true }}
      />
    </div>
  ) : (
    <div className={styles.loading}>
      <Spinner size="lg" />
    </div>
  )
}

export default AnonymousChatbot
