import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import styleValueToNumber from '../../util/styleValueToNumber'

import styles from './SlideTransition.module.scss'

const TRANSITION_DURATION = styleValueToNumber(styles.transitionDuration)
const TRANSITION_DELAY = styleValueToNumber(styles.transitionDelay)

const useMounted = () => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => setHasMounted(true), [])

  return hasMounted
}

const SlideTransition = ({ children, didGoBack, transitionKey }: Props) => {
  const hasMounted = useMounted()
  const timeout = TRANSITION_DURATION + TRANSITION_DELAY

  return (
    <TransitionGroup
      className={cx(styles.root, {
        [styles.didGoBack]: didGoBack,
      })}
      appear
    >
      {hasMounted && (
        <CSSTransition
          key={transitionKey}
          timeout={timeout}
          appear
          classNames={styles}
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

interface Props {
  children: React.ReactNode
  didGoBack: boolean
  transitionKey: string
}

export default SlideTransition
