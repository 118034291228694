import React, { ReactNode } from 'react'
import { useInViewEffect } from 'react-hook-inview'
import { QuestionOptionInterface } from '../../constants/types'

const TrackOption = ({
  onOptionImpression,
  option,
  children,
  className,
}: TrackOptionProps) => {
  const ref = useInViewEffect(
    ([entry], observer) => {
      // Disposes in-view observer
      // so onOptionImpression is only hit once
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)
        onOptionImpression && onOptionImpression(option)
      }
    },
    { threshold: 0.5 }
  )

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
}

interface TrackOptionProps {
  children: ReactNode
  className?: string
  onOptionImpression?: (option: QuestionOptionInterface) => void
  option: QuestionOptionInterface
}

export default TrackOption
