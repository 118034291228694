import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'question.triageIntro.heading',
    defaultMessage: 'Before I can help, I need to take some details from you.',
  },
  continueTriage: {
    id: 'question.triageIntro.continueTriage',
    defaultMessage: 'Get started',
  },
  login: {
    id: 'question.triageIntro.login',
    defaultMessage: 'I already have an account',
  },
})
