import React from 'react'
import { Button } from '@babylon/web-platform-ui'

import TrackOption from '../TrackOption'
import { QuestionOptionInterface } from '../../constants/types'

const CallToActionButton = ({
  option,
  handleCallToAction,
  isPrimary,
  loading,
  onOptionImpression,
  selectedCtaLabel,
  setSelectedCtaLabel,
}: Props) => {
  const testId = `${isPrimary ? 'primary' : 'secondary'}-cta`
  const variant = isPrimary ? 'primary' : 'secondary'

  const handleClick = () => {
    setSelectedCtaLabel(option.label)
    handleCallToAction(option)
  }

  return (
    <TrackOption onOptionImpression={onOptionImpression} option={option}>
      <Button
        data-testid={testId}
        isDisabled={loading}
        isFullWidth
        isLoading={loading && selectedCtaLabel === option.label}
        loadingText=""
        onClick={handleClick}
        variant={variant}
      >
        {option.label}
      </Button>
    </TrackOption>
  )
}

interface Props {
  option: QuestionOptionInterface
  handleCallToAction: (option: QuestionOptionInterface) => void
  isPrimary: boolean
  loading: boolean
  onOptionImpression?: (option: QuestionOptionInterface) => void
  selectedCtaLabel?: string
  setSelectedCtaLabel: (cta: string) => void
}

export default CallToActionButton
