import React from 'react'
import { ChipList, ChipOptions } from '@babylon/web-platform-ui'

import ErrorMessage from '../../../../ErrorMessage'

const Pills = ({ error, label, name, onChange, options, value }: Props) => (
  <div>
    {error && <ErrorMessage error={error} textStyle="caption" />}
    <ChipList
      aria-label={label}
      chips={options}
      name={name}
      onChipSelect={(selectedChips) => onChange(selectedChips[0] as string)}
      selectType="one"
      value={[value]}
    />
  </div>
)

interface Props extends Pick<React.ComponentProps<typeof ChipList>, 'name'> {
  error?: string
  label: string
  onChange: (selectedChip: string) => void
  options: ChipOptions[]
  value: string
}

export default Pills
