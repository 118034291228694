import { replyWithMessageInterfaceProps } from '../../constants/types'
import {
  replyWithMessageRest,
  createChatscriptReplyMessageRest,
} from '../requests/replyWithMessage'
import processChatscriptResponseRest from '../../util/processChatscriptResponseRest'
import formatConversationStep from '../../util/formatConversationStep'

const replyWithMessage = async ({
  context,
  replyMessageType,
  answer,
  ...props
}: replyWithMessageInterfaceProps) => {
  const message = await createChatscriptReplyMessageRest(
    replyMessageType,
    answer
  )
  const response = await replyWithMessageRest({ ...props, message })
  const processed = await processChatscriptResponseRest(context, response)

  return formatConversationStep(processed, context)
}

export default replyWithMessage
