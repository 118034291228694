import { microservicesClient } from '../client'
import { ChatscriptMessage } from '../../constants/types'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const submitRating = ({
  comment,
  conversationId,
  etag,
  messageId,
  rating,
}: Props) => {
  const baseUrl = getBaseChatscriptUrl()

  return microservicesClient
    .post<Response>(
      `${baseUrl}/conversations/${conversationId}/messages/${messageId}/rate`,
      { value: rating, comment },
      {
        headers: {
          'If-Match': etag,
        },
      }
    )
    .then((response) => response.data)
}

interface Props {
  comment?: string
  conversationId: string
  etag: string
  messageId: string
  rating: number
}

type Response = {
  message: ChatscriptMessage
}

export default submitRating
