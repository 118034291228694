import React from 'react'
import { Heading, Text } from '@babylon/web-platform-ui'

import { useFormatMessage } from '@babylon/intl'
import messages from './messages'

import styles from './PossibleCauseExplanationV2.module.scss'

const PossibleCauseExplanation = () => {
  const translate = useFormatMessage()

  return (
    <div>
      <div className={styles.heading}>
        <Heading as="h1" size="md">
          {translate(messages.possibleCausesHeader)}
        </Heading>
      </div>
      <div className={styles.text}>
        <Text>{translate(messages.possibleCausesParagraphOne)}</Text>
        <Text>{translate(messages.possibleCausesParagraphTwo)}</Text>
        <Text>{translate(messages.possibleCausesParagraphThree)}</Text>
      </div>
    </div>
  )
}

export default PossibleCauseExplanation
