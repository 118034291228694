import moment from 'moment'

import { microservicesClient } from '../../client'
import {
  ChatscriptConversation,
  ChatscriptMessage,
  startNewConversationApiProps,
} from '../../../constants/types'
import { getBaseChatscriptUrl } from '../../util/baseUrls'

const startNewConversation = ({
  memberUuid,
  options = {},
}: startNewConversationApiProps) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .post<Response>(`${baseUrl}/conversations`, options, {
      headers: {
        'Time-Zone-Offset': moment().format('Z'),
      },
    })
    .then((response) => ({
      ...response.data,
      etag: response.headers.etag as string,
    }))
}

type Response = {
  conversation: ChatscriptConversation
  messages: ChatscriptMessage[]
}

export default startNewConversation
