import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'privacySettings.heading',
    defaultMessage: 'Privacy preferences',
  },
  privacySettingsNetworkErrorMessage: {
    id: 'privacySettings.privacySettingsNetworkErrorMessage',
    defaultMessage:
      'Network error: Unable to retrieve privacy preferences data at this time.',
  },
})
