import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'sendFeedback.heading',
    defaultMessage: 'Give feedback',
  },
  subHeading: {
    id: 'sendFeedback.subHheading',
    defaultMessage:
      'Let me know what you think so I can improve things for you.',
  },
  optionOne: {
    id: 'sendFeedback.option.one',
    defaultMessage: 'I don’t understand this',
  },
  optionTwo: {
    id: 'sendFeedback.option.two',
    defaultMessage: 'I’m not sure why you asked me this',
  },
  optionThree: {
    id: 'sendFeedback.option.three',
    defaultMessage: 'This makes me feel uncomfortable',
  },
  optionFour: {
    id: 'sendFeedback.option.four',
    defaultMessage: 'This is repetitive',
  },
  optionFive: {
    id: 'sendFeedback.option.five',
    defaultMessage: 'None of the answers apply to me',
  },
  optionSix: {
    id: 'sendFeedback.option.six',
    defaultMessage: 'Something else',
  },
  textFieldLabel: {
    id: 'sendFeedback.textFieldLabel',
    defaultMessage: 'Leave a comment',
  },
  buttonSend: {
    id: 'sendFeedback.buttonSend',
    defaultMessage: 'Send feedback',
  },
})
