import getRegions from '../api/requests/getRegions'
import registerAbout from '../questions/registerAbout'
import addDataToFields from '../util/addDataToFields'

const validate = (fields) => {
  const errors = {}
  ;['first_name', 'last_name'].forEach((f) => {
    if (!fields[f]) {
      errors[f] = 'This field is required'
    }
  })

  return errors
}

const registerProcessor = () => async (conversation, fields, translate) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const registerForm = conversation.nextQuestion
  const context = registerForm.conversationContext
  const errors = validate(fields)
  let nextQuestion

  if (Object.keys(errors).length) {
    nextQuestion = addDataToFields(registerForm, fields, errors)

    return Promise.resolve({
      nextQuestion,
      answeredQuestions: conversation.answeredQuestions,
    })
  }

  const regions = await getRegions()
  nextQuestion = registerAbout(fields.first_name, regions.data, translate)

  return {
    nextQuestion: {
      ...nextQuestion,
      answerProcessorOptions: {
        ...nextQuestion.answerProcessorOptions,
        fields,
      },
      conversationContext: context,
    },
    answeredQuestions: [
      ...conversation.answeredQuestions,
      {
        question: addDataToFields(registerForm, fields, {}),
        answer: fields,
      },
    ],
  }
}

export default registerProcessor
