const setConversationId = (conversationId: string) => ({
  type: 'CHATBOT.SET_CONVERSATION_ID',
  conversationId,
})

const setStarRating = (rating: number) => ({
  type: 'CHATBOT.SET_STAR_RATING',
  rating,
})

export { setConversationId, setStarRating }
