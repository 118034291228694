let loggedIn = false

export const login = async () => {
  loggedIn = true
}

export const logout = () => {
  loggedIn = false
}

export const tokenIsValid = () => loggedIn

export const activeUser = () => (loggedIn ? 12345 : null)

export const tokenCanBeRefreshed = () => loggedIn

export const tokenRefreshDue = () => false

export const refreshToken = () => {}

export const requestHeaders = async () => ({})
