import React, { FormEvent } from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Button,
  TextInput as UIComponentTextInput,
} from '@babylon/web-platform-ui'

import { TextInputProps } from './types'
import ArrowRight from '../../ArrowRight'
import styles from './TextInputV2.module.scss'
import messages from './messages'

const TextInput = ({
  enabled = false,
  loading,
  onSubmit,
  onTextInputChange,
  previousAnswer,
  startAdornment,
  submitLabel,
  value,
  setError,
  wrapWithDiv = false,
}: TextInputProps) => {
  const translate = useFormatMessage()
  const hasPreviousValue = !!previousAnswer
  const previousValue = previousAnswer?.name || ''

  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onTextInputChange(e.target.value)
    setError && setError('')
  }

  const handleSubmit = (e: FormEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!value || !value.trim()) {
      setError && setError(translate(messages.errorRequired))
      return
    }

    onSubmit()
  }

  const Wrapper = wrapWithDiv ? 'div' : 'form'

  return (
    <Wrapper className={styles.container} onSubmit={handleSubmit}>
      <UIComponentTextInput
        data-testid="text-input"
        isDisabled={hasPreviousValue}
        leftElement={startAdornment}
        onChange={handleTextInputChange}
        value={value || previousValue}
      />

      <Button
        data-testid="formSubmit"
        isDisabled={!enabled && hasPreviousValue}
        isLoading={loading}
        loadingText=""
        onClick={handleSubmit}
        rightIcon={<ArrowRight />}
        type="submit"
      >
        {submitLabel || translate(messages.buttonSubmit)}
      </Button>
    </Wrapper>
  )
}

export default TextInput
