import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Heading } from '@babylon/web-platform-ui'

import messages from './messages'
import styles from './OutcomeHeading.module.scss'

const OutcomeHeading = () => {
  const translate = useFormatMessage()

  return (
    <div className={styles.container}>
      <Heading as="h1" size="md">
        {translate(messages.heading)}
      </Heading>
    </div>
  )
}

export default OutcomeHeading
