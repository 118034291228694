/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Box, useColorTheme } from '@babylon/web-platform-ui'

import ErrorMessage from '../ErrorMessage'
import HelpTextButton from '../HelpTextButton'
import LeafletLink from '../LeafletLink'
import OutcomeHeading from '../OutcomeHeading'
import PGMReport from '../PGMReport'
import StarRating from '../StarRating'

import TextQuestion from '../questionTypes/TextQuestion'

import AutoSuggestInput from '../inputTypes/AutoSuggestInput'
import ConditionSearch from '../inputTypes/ConditionSearch'
import DynamicFormInput from '../inputTypes/DynamicForm'
import MissingInput from '../inputTypes/MissingInput'
import MultiSelectInput from '../inputTypes/MultiSelectInput'
import NearbyPlacesMapInput from '../inputTypes/NearbyPlacesMapInput'
import SelectInput from '../inputTypes/SelectInput'
import StandaloneDateInput from '../inputTypes/StandaloneDateInput'
import StandalonePhoneInput from '../inputTypes/StandalonePhoneInput'
import TextInput from '../inputTypes/TextInput'
import TextFileInput from '../inputTypes/TextFileInput'
import FeedbackButton from '../FeedbackButton'
import OptionList from '../OptionList'

import { inputTypes } from '../../constants'
import {
  GoToQuestionInterface,
  QuestionOptionInterface,
  QuestionInterface,
  ContextInterface,
} from '../../constants/types'
import sanitize from '../../util/sanitize'

import styles from './QuestionCard.module.scss'
import PrivacySettings from '../PrivacySettings'
import PrivacySettingsNavBox from '../PrivacySettingsNavBox'
import { getSettingsCard } from '../../questions/privacySettings/privacySettings'

const INPUT_COMPONENT_FOR_TYPE = {
  [inputTypes.CONDITION_SEARCH]: ConditionSearch,
  [inputTypes.DATE_SELECT]: StandaloneDateInput,
  [inputTypes.DRAFT]: TextFileInput,
  [inputTypes.FORM]: DynamicFormInput,
  [inputTypes.MAP]: NearbyPlacesMapInput,
  [inputTypes.MULTI_SELECT]: MultiSelectInput,
  [inputTypes.NOOP]: () => null,
  [inputTypes.PASSWORD]: TextInput,
  [inputTypes.PHONE_INPUT]: StandalonePhoneInput,
  [inputTypes.SELECT]: SelectInput,
  [inputTypes.SYMPTOM_SELECT]: AutoSuggestInput,
  [inputTypes.TEXT]: TextInput,
  [inputTypes.PRIVACY_SETTINGS]: PrivacySettings,
  $default: MissingInput,
}

const QuestionCard = ({
  goToQuestion,
  handleCallToAction,
  hasRatedQuestion,
  loading,
  onSelect,
  onSubmit,
  onTextInputChange,
  onValueChange,
  previousAnswer,
  question,
  reset,
  sendStarRating,
  SummaryAction,
  value,
  onOptionImpression,
  navigatingBack,
  context,
  showFeedbackButton,
  onFeedbackClick,
}: Props) => {
  const [customError, setCustomError] = useState('')
  const { colors } = useColorTheme()

  const InputComponent =
    INPUT_COMPONENT_FOR_TYPE[question.type] || INPUT_COMPONENT_FOR_TYPE.$default

  const questionRef = useRef<any>(null)

  useEffect(() => {
    questionRef?.current?.focus()
  }, [question])

  const showCustomAction =
    question && question.pgmReport && !question.pgmDiagnosisId

  const lines = question.text?.split(/\n+/).filter(Boolean)
  const questionTitle = lines?.[0]
  const questionParagraphs = lines?.slice(1)
  const isOutcome = context?.status === 'finished'
  const isCheckbaseOutcomeV2 = isOutcome && !question.pgmReport
  const options = question.options || []
  const error = customError || question.error

  const settingsCard = getSettingsCard(question)

  return (
    <div className={styles.root} role="region" aria-labelledby={question.id}>
      <div tabIndex={0} ref={questionRef}>
        {isCheckbaseOutcomeV2 && <OutcomeHeading />}

        <Box
          className={classNames({ [styles.outcomeBox]: isCheckbaseOutcomeV2 })}
          backgroundColor={
            isCheckbaseOutcomeV2
              ? colors.background.backgroundPrimary
              : undefined
          }
        >
          {questionTitle && (
            <TextQuestion
              paragraphs={questionParagraphs}
              id={question.id}
              heading={questionTitle}
              isOutcome={isOutcome}
              error={error}
            />
          )}

          {error && (
            <div aria-live="polite">
              <ErrorMessage error={error} />
            </div>
          )}

          {isCheckbaseOutcomeV2 && (
            <OptionList
              options={options}
              onOptionImpression={onOptionImpression}
              onSelect={onSelect}
              navigatingBack={navigatingBack}
              loading={loading}
            />
          )}
        </Box>
      </div>

      {question.helpText && (
        <HelpTextButton
          conversationId={context?.conversationId}
          helpText={question.helpText}
        />
      )}

      {question.bodyHTML && (
        <div
          className={styles.bodyHTML}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitize(question.bodyHTML) }}
        />
      )}

      {question.pgmReport && (
        <PGMReport
          goToQuestion={goToQuestion}
          handleCallToAction={handleCallToAction}
          loading={loading}
          question={question}
          onOptionImpression={onOptionImpression}
        />
      )}

      {question.starRating && (
        <StarRating
          submitRating={sendStarRating}
          hasAlreadyRated={hasRatedQuestion}
        />
      )}

      {question.leaflet?.title && <LeafletLink leaflet={question.leaflet} />}

      {showCustomAction && SummaryAction ? (
        <SummaryAction chatbotProps={{ reset }} />
      ) : (
        <InputComponent
          key={question.error ? 'had error' : 'input'} // change the key if there was an error. this unsets any state below
          question={question}
          onSelect={onSelect} // SelectInput only
          onOptionImpression={onOptionImpression} // SelectInput only
          onSubmit={onSubmit}
          previousAnswer={previousAnswer}
          goToQuestion={goToQuestion}
          handleCallToAction={handleCallToAction}
          loading={loading}
          onValueChange={onValueChange} // DynamicForm only
          value={value}
          onTextInputChange={onTextInputChange}
          legend={questionTitle}
          navigatingBack={navigatingBack}
          isOutcome={isOutcome}
          setError={setCustomError}
        />
      )}

      {settingsCard && (
        <PrivacySettingsNavBox
          data={settingsCard}
          onGoToQuestion={goToQuestion}
        />
      )}

      {showFeedbackButton && <FeedbackButton onClick={onFeedbackClick} />}
    </div>
  )
}

interface Props {
  goToQuestion: (newQuestion: GoToQuestionInterface) => void
  handleCallToAction: (option: QuestionOptionInterface) => void
  hasRatedQuestion?: boolean
  loading: boolean
  onSelect: (option: QuestionOptionInterface) => void
  onSubmit: (answer?: any) => void
  onTextInputChange: (s: string) => void
  onValueChange: (fields: any) => void
  previousAnswer: any
  question: QuestionInterface
  reset: () => void
  sendStarRating: (ratingParams: { rating: number; comment?: string }) => void
  SummaryAction?: React.ElementType
  value: any
  onOptionImpression?: (option: QuestionOptionInterface) => void
  navigatingBack: boolean
  context?: ContextInterface
  showFeedbackButton?: boolean
  onFeedbackClick: () => void
}

export default QuestionCard
