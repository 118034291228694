import loginQuestion from '../questions/login'
import registerName from '../questions/registerName'
import authAccessor from '../util/auth'

import { startNewConversation } from '../api'
import getPrivacyScreens from '../util/getPrivacyScreens'
import displayPrivacyScreen from '../util/displayPrivacyScreen'

const welcomeProcessor = () => async (conversation, answer, translate) => {
  const auth = authAccessor()

  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  if (typeof answer === 'object') {
    await auth.refreshToken()
    const userId = await auth.activeUser()
    const gdprScreens = await getPrivacyScreens()

    if (gdprScreens.length) {
      return displayPrivacyScreen(
        gdprScreens[0],
        'welcomeProcessor',
        answer,
        conversation.nextQuestion,
        conversation
      )
    }

    if (answer.action === 'startConversation') {
      const nextQuestion = await startNewConversation(userId)

      return {
        nextQuestion,
        answeredQuestions: [
          ...conversation.answeredQuestions,
          { question: conversation.nextQuestion, answer },
        ],
      }
    }
  }

  let nextQuestion

  if (answer === 'login') {
    nextQuestion = loginQuestion(translate)
  } else if (answer === 'continue_triage') {
    nextQuestion = registerName(translate)
  }

  return Promise.resolve({
    nextQuestion: {
      ...nextQuestion,
      conversationContext: conversation.nextQuestion.conversationContext,
    },
    answeredQuestions: [
      ...conversation.answeredQuestions,
      { question: conversation.nextQuestion, answer },
    ],
  })
}

export default welcomeProcessor
