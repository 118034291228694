import React, { useEffect } from 'react'
import { useStore } from 'react-redux'
import { useFormatMessage } from '@babylon/intl'
import { EventTracker } from '@babylon/tracking/gtag'

import { getProductConfig } from './api'
import {
  ContextInterface,
  ContextStatus,
  QuestionInterface,
} from './constants/types'
import chatbotProvider from './ChatbotProvider'
import welcomeQuestion from './questions/welcome'
import * as answerProcessors from './answerProcessors'
import Chatbot from './Chatbot'
import reducers from './redux/reducers'
import { setTrackEventOverride } from './util/tracking'
import getQueryClient from './util/getQueryClient'

const ChatbotContainer = ({
  Accessory,
  actionHandler,
  initialQuestion,
  LogoComponent,
  logoUrl,
  onConversationStatusChange,
  onGoToStart,
  processors = answerProcessors,
  startInOverlay,
  SummaryAction,
  trackEvent,
}: Props) => {
  const store = useStore()
  // the store is extended with injectReducer
  // @ts-ignore
  store.injectReducer('chatbot', reducers)
  const queryClient = getQueryClient()

  useEffect(() => {
    if (trackEvent) {
      setTrackEventOverride(trackEvent)
    }
  }, [trackEvent])

  useEffect(() => {
    queryClient.prefetchQuery('getProductConfig', getProductConfig)
  }, [queryClient])

  const translate = useFormatMessage()
  const question = initialQuestion || welcomeQuestion(translate)

  const ChatbotProvider = chatbotProvider({
    initialQuestion: question,
    answerProcessors: processors,
    actionHandler,
    translate,
  })(Chatbot)

  return (
    <ChatbotProvider
      initialQuestion={question}
      onConversationStatusChange={onConversationStatusChange}
      onGoToStart={onGoToStart}
      Accessory={Accessory}
      SummaryAction={SummaryAction}
      LogoComponent={LogoComponent}
      logoUrl={logoUrl}
      startInOverlay={startInOverlay}
    />
  )
}

interface Props {
  Accessory?: React.ReactElement
  actionHandler?: () => void
  initialQuestion?: {
    conversationContext: {
      conversationId: string
    }
    question: QuestionInterface
  }
  LogoComponent?: React.ReactElement
  logoUrl?: string
  onConversationStatusChange?: (status: ContextStatus) => void
  onGoToStart?: (context: ContextInterface) => void
  processors?: any
  startInOverlay?: boolean
  SummaryAction?: () => void
  trackEvent?: EventTracker
}

export default ChatbotContainer
