import axios from 'axios'
import { setAuthConfig } from '@babylon/babylon-auth'
import { envUrl } from '@babylon/babylon-env'

import { APP_NAME, CORE_URL, MICROSERVICES_URL } from './config'
import getHeaders from './getHeaders'
import authAccessor from '../util/auth'

const microservicesBaseURL = envUrl('MICROSERVICES_URL') || MICROSERVICES_URL

const rubyClient = axios.create({
  withCredentials: true,
  baseURL: CORE_URL,
})

const microservicesClient = axios.create({
  withCredentials: true,
  baseURL: microservicesBaseURL,
})

setAuthConfig({
  axiosOpts: () => ({ headers: { 'X-App-Name': APP_NAME } }),
})

// When we make requests, refresh the token if it's running out
const authInterceptor = async (axiosConfig) => {
  const auth = authAccessor()
  const tokenIsValid = await auth.tokenIsValid()
  const tokenCanBeRefreshed = await auth.tokenCanBeRefreshed()
  const tokenRefreshDue = await auth.tokenRefreshDue()

  if ((!tokenIsValid && tokenCanBeRefreshed) || tokenRefreshDue) {
    await auth.refreshToken()
  }

  return axiosConfig
}

const headerInterceptor = async (axiosConfig) => ({
  ...axiosConfig,
  headers: {
    ...axiosConfig.headers,
    ...(await getHeaders()),
  },
})

;[headerInterceptor, authInterceptor].forEach((interceptor) => {
  microservicesClient.interceptors.request.use(interceptor)
  rubyClient.interceptors.request.use(interceptor)
})

export { rubyClient, microservicesClient }
