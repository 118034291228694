import { defineMessages } from 'react-intl'

export default defineMessages({
  back: {
    id: 'goBackButton',
    defaultMessage: 'Back',
  },
  exit: {
    id: 'Header.exit',
    defaultMessage: 'Exit',
  },
  feedback: {
    id: 'Header.feedback',
    defaultMessage: 'Feedback',
  },
})
