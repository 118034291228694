import { getPrivacyNoticeVersions } from '../api'
import authAccessor from './auth'

import { inputTypes } from '../constants'

const formatPolicyAsQuestion = (policy) => ({
  question: {
    progress: 0,
    type: inputTypes.SELECT,
    text: `${policy.updateTitle}\n${policy.updateText}`,
    undoable: false,
    options: [
      policy.acceptButtonText && {
        value: 'GDPR_ACCEPT',
        label: policy.acceptButtonText,
      },
      policy.declineButtonText && {
        value: 'GDPR_DECLINE',
        label: policy.declineButtonText,
      },
    ].filter((val) => val),
    leaflet: {
      summary: '',
      content_provider: '',
      longText: policy.longText,
      title: policy.readMoreButtonText,
    },
  },
  answerProcessorName: 'gdprProcessor',
  answerProcessorOptions: {
    policy,
  },
})

const getPrivacyScreens = async ({ email, password } = {}) => {
  const auth = authAccessor()
  const tokenIsValid = await auth.tokenIsValid()
  const tokenCanBeRefreshed = await auth.tokenCanBeRefreshed()

  if (!tokenIsValid && tokenCanBeRefreshed) {
    await auth.refreshToken()
  } else if (!tokenIsValid && !tokenCanBeRefreshed) {
    await auth.login(email, password)
  }

  // Fetch the ones that we need to enter and format them as questions
  const policies = await getPrivacyNoticeVersions()

  return policies.data.map(formatPolicyAsQuestion)
}

export default getPrivacyScreens
export { formatPolicyAsQuestion }
