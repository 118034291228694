import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { Box, useColorTheme } from '@babylon/web-platform-ui'

import styles from './Overlay.module.scss'

const portalRoot = document.body

let hackyGlobalSetFocusPaused:
  | React.Dispatch<React.SetStateAction<boolean | undefined>>
  | undefined

const setFocusPausedState = (newState: boolean) => {
  if (hackyGlobalSetFocusPaused) {
    hackyGlobalSetFocusPaused(newState)
  }
}

const Overlay = ({ children, className, ...rest }: Props) => {
  const [portalEl] = useState(document.createElement('div'))
  const [focusPaused, setFocusPaused] = useState<boolean>()
  const { colors } = useColorTheme()

  useEffect(() => {
    portalEl.setAttribute('class', cx(styles.root, className))
    portalEl.setAttribute('aria-modal', 'true')
    portalEl.setAttribute('id', 'chatbot-web-overlay-root')

    if (!portalRoot.contains(portalEl)) {
      portalRoot.style.overflow = 'hidden'
      portalRoot.appendChild(portalEl)
    }

    return () => {
      portalRoot.style.overflow = 'visible'
      portalRoot.removeChild(portalEl)
    }
  }, [className, portalEl])

  useEffect(() => {
    if (hackyGlobalSetFocusPaused) {
      // eslint-disable-next-line no-console
      console.warn(
        '<Overlay> tried to set the global setFocusPaused, but it was already defined. Behaviour is now undefined'
      )
    }

    hackyGlobalSetFocusPaused = setFocusPaused

    return () => {
      hackyGlobalSetFocusPaused = undefined
    }
  }, [])

  return createPortal(
    <FocusTrap
      focusTrapOptions={{ clickOutsideDeactivates: true, initialFocus: false }}
      paused={focusPaused}
    >
      <Box
        className={styles.content}
        {...rest}
        backgroundColor={colors.background.backgroundSecondary}
      >
        {children}
      </Box>
    </FocusTrap>,
    portalEl
  )
}

interface Props {
  children: React.ReactNode
  className: string
}

export default Overlay
export { setFocusPausedState }
