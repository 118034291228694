export enum ChatscriptMessageInputType {
  Card = 'card',
  Choices = 'choices',
  Date = 'date',
  Phone = 'phone',
  Symptom = 'symptom',
  Text = 'text',
  TextAndFiles = 'text_and_files',
}

export enum ChatscriptMessageSender {
  Babylon = 'babylon',
  User = 'user',
}

export enum ChatscriptMessageType {
  Diagnosis = 'diagnosis',
  Leaflet = 'leaflet',
  Notice = 'notice',
  Text = 'text',
}

export enum LocationTypes {
  AccidentAndEmergency = 'accident_and_emergency',
  Pharmacies = 'pharmacies',
  SexualHealthServices = 'sexual_health_services',
}

export enum PGMActionTypes {
  appview = 'appview',
  custom = 'custom',
  location = 'location',
  phone = 'phone',
  webview = 'webview',
}

export enum PrivacyNoticeTypes {
  Marketing = 'marketing',
  PrivacyPolicy = 'privacy policy',
  TermsAndConditions = 'terms and conditions',
}

export enum AppviewActionTypes {
  BookConsultation = 'book-consultation',
  ClinicalChat = 'clinical-chat',
  MonitorCovidCarePlan = 'monitor-covid-care-plan',
  StartConversation = 'start-conversation',
  HaveAccount = 'have_account',
  Healthcheck = 'healthcheck',
  PrivacyPolicyScreen = 'babylon://privacy-policy-screen',
}

export enum LocationActionTypes {
  FindPlaceEmergencyRoom = 'emergency-room',
  FindPlaceEyeCasualty = 'eye-casualty',
  FindPlaceHospital = 'hospital',
  FindPlacePharmacy = 'pharmacy',
  FindPlaceSTDClinic = 'std-clinic',
  FindPlaceUrgentCare = 'urgent-care',
}

export enum CallToActionTypes {
  Ask = 'ask',
  Appview = 'appview',
  CallSamaritans = 'call_samaritans',
  Custom = 'custom',
  Email = 'email',
  Phone = 'phone',
  SearchConditions = 'search_conditions',
  URL = 'url',
  WebView = 'webview',
  Default = 'default',
}

export enum ConversationStatus {
  Finished = 'finished',
  Started = 'started',
}
