import { defineMessages } from 'react-intl'

export default defineMessages({
  header: {
    id: 'commonSearches.header',
    defaultMessage: 'Or select a common symptom',
  },
  pillHeadache: {
    id: 'commonSearches.pill.headache',
    defaultMessage: 'Headache',
  },
  pillAbdominalPain: {
    id: 'commonSearches.pill.abdominalPain',
    defaultMessage: 'Abdominal pain',
  },
  pillTummyPain: {
    id: 'commonSearches.pill.tummyPain',
    defaultMessage: 'Tummy pain',
  },
  pillCough: {
    id: 'commonSearches.pill.cough',
    defaultMessage: 'Cough',
  },
  pillMusclePain: {
    id: 'commonSearches.pill.musclePain',
    defaultMessage: 'Muscle pain',
  },
  pillSoreThroat: {
    id: 'commonSearches.pill.soreThroat',
    defaultMessage: 'Sore throat',
  },
  pillEarPain: {
    id: 'commonSearches.pill.earPain',
    defaultMessage: 'Ear pain',
  },
  pillDizziness: {
    id: 'commonSearches.pill.dizziness',
    defaultMessage: 'Dizziness',
  },
})
