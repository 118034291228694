import React from 'react'
import PropTypes from 'prop-types'
import { logException } from '@babylon/sentry'

import { useProductConfig } from '../../../../../api'
import { CallToActionTypes } from '../../../../../constants/enums'
import { getOptionForPGMAction } from '../../../../../util/callToActions'
import SearchConditionsPanel from '../SearchConditionsPanel'

const SearchConditionsContainer = ({ handleCallToAction, isLoading }) => {
  const { loadingQuery, data, error } = useProductConfig()

  const handleButtonClick = () =>
    handleCallToAction(
      getOptionForPGMAction({ type: CallToActionTypes.SearchConditions })
    )

  let conditionSearchEnabled = false

  if (!loadingQuery && !error) {
    conditionSearchEnabled = data.conditionSearchEnabled
  }

  if (error) {
    logException(error)
  }

  if (!conditionSearchEnabled) {
    return null
  }

  return (
    <SearchConditionsPanel isLoading={isLoading} onClick={handleButtonClick} />
  )
}

SearchConditionsContainer.propTypes = {
  handleCallToAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default SearchConditionsContainer
