import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { DateInput as UIComponentDateInput } from '@babylon/web-platform-ui'

import { APP_LOCALE } from '../../../../../api/config'
import messages from './messages'

const DateInput = ({ error, label, onChange }: Props) => {
  const translate = useFormatMessage()

  return (
    <UIComponentDateInput
      errorMessage={error}
      label={label}
      locale={APP_LOCALE}
      onChange={(date: string) => onChange(date)}
      dayLabel={translate(messages.dayLabel)}
      monthLabel={translate(messages.monthLabel)}
      yearLabel={translate(messages.yearLabel)}
    />
  )
}

interface Props {
  error?: string
  label: string
  onChange: (date: string) => void
}

export default DateInput
