import { defineMessages } from 'react-intl'

export default defineMessages({
  submitLabel: {
    id: 'DateInput.submitLabel',
    defaultMessage: 'Submit',
  },
  errorRequired: {
    id: 'DateInput.errorRequired',
    defaultMessage: 'This field is required',
  },
  invalidDate: {
    id: 'DateInput.invalidDate',
    defaultMessage: 'Please enter a date between {minDate} and {maxDate}',
  },
})
