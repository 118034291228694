import React from 'react'
import { Spinner } from '@babylon/web-platform-ui'

import styles from './LazyFallback.module.scss'

const LazyFallback = () => (
  <div className={styles.fallback}>
    <Spinner data-testid="loading" size="lg" variant="secondary" />
  </div>
)

export default LazyFallback
