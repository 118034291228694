import React from 'react'

import styles from './MissingInput.module.scss'

const MissingInput = ({ question }: Props) => {
  const message = `Missing input for type ${question.type}`
  console.warn(message) // eslint-disable-line no-console

  return process.env.NODE_ENV === 'development' ? (
    <div className={styles.message}>{message}</div>
  ) : null
}

interface Props {
  question: {
    type: string
  }
}

export default MissingInput
