import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import { AppviewActionTypes, CallToActionTypes } from '../../constants/enums'

import messages from './messages'

const triageIntro = (translate: IntlFormatters['formatMessage']) => ({
  question: {
    options: [
      {
        label: translate(messages.continueTriage),
        value: 'continue_triage',
        variant: 'primary',
      },
      {
        action: {
          type: CallToActionTypes.Appview,
          data: { view: AppviewActionTypes.HaveAccount },
        },
        label: translate(messages.login),
        value: 'login',
      },
    ],
    text: translate(messages.heading),
    type: inputTypes.SELECT,
    undoable: true,
  },
  answerProcessorName: 'welcomeProcessor',
  answerProcessorOptions: {},
})

export default triageIntro
