import { useFormatMessage } from '@babylon/intl'
import React from 'react'

import {
  PGMActionInterface,
  QuestionOptionInterface,
} from '../../constants/types'
import CallToActionButton from './CallToActionButton'
import CallToActionDisclaimer from './CallToActionDisclaimer'
import { getOptionForPGMAction } from '../../util/callToActions'

import styles from './CallToActionsDiagnosis.module.scss'

const CallToActionsDiagnosis = ({
  actions,
  handleCallToAction,
  loading,
  onOptionImpression,
  selectedCtaLabel,
  setSelectedCtaLabel,
}: Props) => {
  const translate = useFormatMessage()
  const callToActions = actions.map((action) => (
    <div className={styles.buttonContainer}>
      <CallToActionButton
        option={getOptionForPGMAction(action, translate)}
        handleCallToAction={handleCallToAction}
        isPrimary
        key={action.label}
        loading={loading}
        onOptionImpression={onOptionImpression}
        selectedCtaLabel={selectedCtaLabel}
        setSelectedCtaLabel={setSelectedCtaLabel}
      />
    </div>
  ))
  const disclaimers = actions
    .filter((action) => action?.data.disclaimer)
    .map((action) => (
      <CallToActionDisclaimer
        action={action}
        key={`${action.label}_disclaimer`}
      />
    ))

  return (
    <>
      {callToActions}
      {disclaimers}
    </>
  )
}

interface Props {
  actions: PGMActionInterface[]
  handleCallToAction: (option: QuestionOptionInterface) => void
  loading: boolean
  onOptionImpression?: (option: QuestionOptionInterface) => void
  selectedCtaLabel?: string
  setSelectedCtaLabel: (cta: string) => void
}

export default CallToActionsDiagnosis
