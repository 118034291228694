import { defineMessages } from 'react-intl'

export default defineMessages({
  emergencyRoom: {
    id: 'question.findNearby.emergencyRoom',
    defaultMessage: 'Emergency Room',
  },
  eyeCasualty: {
    id: 'question.findNearby.eyeCasualty',
    defaultMessage: 'Eye Hospital',
  },
  hospital: {
    id: 'question.findNearby.hospital',
    defaultMessage: 'Hospitals',
  },
  pharmacy: {
    id: 'question.findNearby.pharmacy',
    defaultMessage: 'Pharmacies',
  },
  stdClinic: {
    id: 'question.findNearby.stdClinic',
    defaultMessage: 'STD Clinic',
  },
  urgentCare: {
    id: 'question.findNearby.urgentCare',
    defaultMessage: 'Urgent Care',
  },
})
