import { microservicesClient } from '../client'
import { ChatscriptMessage } from '../../constants/types'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const getConversationMessages = ({ conversationId, memberUuid }: Props) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .get<Response>(
      `${baseUrl}/conversations/${conversationId}/messages?order=desc`
    )
    .then((response) => ({
      ...response.data,
      etag: response.headers.etag as string,
    }))
}

interface Props {
  conversationId: string
  memberUuid?: string
}

type Response = {
  messages: ChatscriptMessage[]
}

export default getConversationMessages
