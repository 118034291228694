import React from 'react'
import { array, bool, object, func, shape, string } from 'prop-types'

import DiagnosisOverview from './components/DiagnosisOverview'
import AssessmentOverview from './components/AssessmentOverview'
import PossibleCauseExplanation from './components/PossibleCauseExplanation'
import IntlWrapper from './IntlWrapper'

class PGMReport extends React.Component {
  constructor() {
    super()

    this.state = {
      diagnosisId: null,
    }
  }

  selectDiagnosis = (diagnosisId) => {
    this.setState({ diagnosisId }, () => {
      // fire custom event, if provided by the containing app
      diagnosisId &&
        this.props.onSelectDiagnosis &&
        this.props.onSelectDiagnosis(diagnosisId)
    })
  }

  showExplanation = () => {
    const diagnosisId = 'explanation'
    this.setState({ diagnosisId }, () => {
      // fire custom event, if provided by the containing app
      this.props.onShowExplanation && this.props.onShowExplanation(diagnosisId)
    })
  }

  render() {
    const { appLocale, callToActions, controlled, pgmAssessment } = this.props
    const diagnosisId =
      this.props.diagnosisId || controlled
        ? this.props.diagnosisId
        : this.state.diagnosisId

    let mainComponent

    if (diagnosisId === 'explanation') {
      mainComponent = <PossibleCauseExplanation />
    } else if (diagnosisId) {
      mainComponent = (
        <DiagnosisOverview
          callToActions={callToActions?.diagnosis[diagnosisId]}
          condition={pgmAssessment.diagnosis.conditions.find(
            (c) => c.id === diagnosisId
          )}
        />
      )
    } else {
      mainComponent = (
        <AssessmentOverview
          assessment={pgmAssessment}
          callToActions={callToActions?.assessment}
          onSelectDiagnosis={this.selectDiagnosis}
          onViewExplanation={this.showExplanation}
        />
      )
    }

    return <IntlWrapper appLocale={appLocale}>{mainComponent}</IntlWrapper>
  }
}

PGMReport.propTypes = {
  appLocale: string,
  callToActions: shape({
    assesment: array,
    diagnosis: object,
  }),
  controlled: bool,
  diagnosisId: string,
  onSelectDiagnosis: func,
  onShowExplanation: func,
  pgmAssessment: shape({
    diagnosis: { condition: string },
  }),
}

export default PGMReport
