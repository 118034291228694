import { useQuery } from 'react-query'

import getProductConfig from './getProductConfig'

const useProductConfig = () => {
  const { isLoading: loadingQuery, data, error } = useQuery(
    'getProductConfig',
    getProductConfig
  )
  return { loadingQuery, data, error }
}

export default useProductConfig
