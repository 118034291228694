import { Auth0Client } from '@auth0/auth0-spa-js'

let client: Auth0Client | null = null

export const initialise = async (newClient: Auth0Client) => {
  if (!client) {
    client = newClient
  }
}

export const login = async (email?: string) =>
  client?.loginWithRedirect({
    scope: 'openid',
    mode: 'sign-in',
    appState: { targetUrl: window.location.pathname },
    ...(email ? { login_hint: email } : {}),
  })

export const logout = () => {
  client?.logout()
}

export const tokenIsValid = async () => {
  try {
    await client?.checkSession()

    return true
  } catch {
    return false
  }
}

export const activeUser = async () => {
  const auth0User = await client?.getUser()

  return auth0User ? auth0User['https://babylonhealth.com/user'] : null
}

export const tokenCanBeRefreshed = tokenIsValid

export const tokenRefreshDue = () => false

export const refreshToken = () => {}

export const handleRedirectCallback = async () =>
  client?.handleRedirectCallback()

export const requestHeaders = async () => {
  const token = await client?.getTokenSilently()

  return tokenIsValid() ? { Authorization: `Bearer ${token}` } : {}
}
