import React, { lazy, Suspense, useEffect } from 'react'
import { Button, PopOver, useDisclosure } from '@babylon/web-platform-ui'

import { setFocusPausedState } from '../Overlay'

import styles from './CardContent.module.scss'

const DynamicLottieAnimation = lazy(
  () => import(/* webpackChunkName: "lottie-react-web" */ 'lottie-react-web')
)

const CardContent = ({
  callToActionText,
  IconAnimationData,
  title,
  content,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    onOpen()
  }, [onOpen])

  useEffect(() => {
    if (isOpen) {
      setFocusPausedState(true)
    } else {
      setFocusPausedState(false)
    }

    return () => setFocusPausedState(false)
  }, [isOpen])

  return (
    <PopOver isOpen={isOpen} onClose={onClose}>
      <div className={styles.cardContent}>
        {IconAnimationData && (
          <div className={styles.cardContent__icon}>
            <Suspense fallback={<div>Loading...</div>}>
              <DynamicLottieAnimation
                options={{
                  animationData: IconAnimationData,
                }}
                /* [Hack] Why is the `eventListeners` prop set to an empty array? */
                eventListeners={[]}
                /*
                TLRD; Corrects a bug when unmounting the dynamically imported lottie-react-web dependency.
                ---
                Dependency`lottie-react-web` iterates over an eventListeners prop when unmounting.
                This eventListeners prop is implicitly defined by the library if you load the library synchronously,
                but used in conjunction with `React.Lazy` and `import()` the is undefined when unmounting the component.
                ---
                Setting eventListeners to an empty Array prevents the error.
                */
              />
            </Suspense>
          </div>
        )}
        <div className={styles.cardContent__title}>{title}</div>
        <div className={styles.cardContent__body}>{content}</div>
        {callToActionText && (
          <Button isFullWidth type="button" onClick={onClose}>
            {callToActionText}
          </Button>
        )}
      </div>
    </PopOver>
  )
}

interface Props {
  callToActionText?: string
  content: string
  IconAnimationData?: any
  title: string
}

export default CardContent
