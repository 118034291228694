import { get } from 'lodash'
import { questionWithError } from '../api'

import addDataToFields from '../util/addDataToFields'
import { trackUserLoggedIn } from '../util/tracking'

import loginAndStartConversation from '../util/loginAndStartConversation'
import getPrivacyScreens from '../util/getPrivacyScreens'
import displayPrivacyScreen from '../util/displayPrivacyScreen'

const validate = (fields) => {
  const errors = {}

  if (!fields.email) {
    errors.email = 'An email address is required'
  }

  if (!fields.password) {
    errors.password = 'A password is required'
  }

  return errors
}

const loginProcessor = () => async (conversation, fields) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const handleError = (err) => {
    const questionWithValues = addDataToFields(
      loginForm,
      fields,
      validationErrors
    )
    const errorMessage = get(
      err,
      'message',
      'Something went wrong. Please try again later.'
    )

    return {
      nextQuestion: questionWithError(questionWithValues, errorMessage),
      answeredQuestions: [...conversation.answeredQuestions],
    }
  }

  const loginForm = conversation.nextQuestion
  const validationErrors = validate(fields)
  let nextQuestion

  if (Object.keys(validationErrors).length) {
    nextQuestion = addDataToFields(loginForm, fields, validationErrors)

    return Promise.resolve({
      nextQuestion,
      answeredQuestions: conversation.answeredQuestions,
    })
  }

  try {
    const gdprScreens = await getPrivacyScreens({
      email: fields.email,
      password: fields.password,
    })

    if (gdprScreens.length) {
      return displayPrivacyScreen(
        gdprScreens[0],
        'loginProcessor',
        fields,
        loginForm,
        conversation
      )
    }
  } catch (err) {
    return handleError(err)
  }

  try {
    const payload = await loginAndStartConversation(
      fields.email,
      fields.password
    )

    trackUserLoggedIn()

    return {
      nextQuestion: payload,
      answeredQuestions: [
        ...conversation.answeredQuestions,
        {
          question: addDataToFields(loginForm, fields, validationErrors),
          answer: fields,
        },
      ],
    }
  } catch (err) {
    return handleError(err)
  }
}

export default loginProcessor
