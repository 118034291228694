import messages from './messages'

const getHeadings = (likelyConditions, lessLikelyConditions, translate) => {
  let mostLikelyTitle = null
  let lessLikelyTitle = null

  const keys = {
    top_diff_title__more_than_one: translate(messages.topDiffMoreThanOne),
    top_diff_title__only_one: translate(messages.topDiffOnlyOne),
    other_less_likely_diff_title__only_one: translate(
      messages.lessLikelyOnlyOne
    ),
    other_less_likely_diff_title__more_than_one: translate(
      messages.lessLikelyMoreThanOne
    ),
    only_not_likely_diff_title___only_one: translate(messages.notLikelyOnlyOne),
    only_not_likely_diff_title___more_than_one: translate(
      messages.notLikelyMoreThanOne
    ),
  }

  if (likelyConditions.length > 1 && lessLikelyConditions.length === 1) {
    mostLikelyTitle = keys.top_diff_title__more_than_one
    lessLikelyTitle = keys.other_less_likely_diff_title__only_one
  } else if (likelyConditions.length === 1 && lessLikelyConditions.length > 1) {
    mostLikelyTitle = keys.top_diff_title__only_one
    lessLikelyTitle = keys.other_less_likely_diff_title__more_than_one
  } else if (
    likelyConditions.length === 1 &&
    lessLikelyConditions.length === 1
  ) {
    mostLikelyTitle = keys.top_diff_title__only_one
    lessLikelyTitle = keys.other_less_likely_diff_title__only_one
  } else if (!likelyConditions.length && lessLikelyConditions.length) {
    lessLikelyTitle = keys.only_not_likely_diff_title___more_than_one
  } else if (!likelyConditions.length && lessLikelyConditions.length === 1) {
    lessLikelyTitle = keys.only_not_likely_diff_title___only_one
  } else if (!lessLikelyConditions.length && likelyConditions.length === 1) {
    mostLikelyTitle = keys.top_diff_title__only_one
  } else {
    mostLikelyTitle = keys.top_diff_title__more_than_one
  }

  return { mostLikelyTitle, lessLikelyTitle }
}

export default getHeadings
