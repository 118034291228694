import { Auth0Client } from '@auth0/auth0-spa-js'

import * as cookieAuth from './cookieAuth'
import * as mockAuth from './mockAuth'
import * as tokenAuth from './tokenAuth'
import * as cyrusAuth from './cyrusAuth'
import * as mfAuth from './mfAuth'
import * as canvasLauncherAuth from './canvasLauncherAuth'

let activeAuth:
  | typeof cookieAuth
  | typeof mockAuth
  | typeof cyrusAuth
  | typeof canvasLauncherAuth
  | typeof tokenAuth = cookieAuth

export const enableMockAuth = () => {
  activeAuth = mockAuth
}

export const enableCustomAuth = async (client?: Auth0Client) => {
  // If we are inside micro front end
  if (mfAuth.insideMicroFrontend()) {
    activeAuth = mfAuth
  }

  // If auth0 client given, enable auth0 client
  if (client) {
    await tokenAuth.initialise(client)
    activeAuth = tokenAuth
  }

  // If we are inside cyrus app
  if (cyrusAuth.insideCyrus()) {
    activeAuth = cyrusAuth
  }

  // If we are inside canvas launcher
  if (canvasLauncherAuth.insideCanvasLauncher()) {
    activeAuth = canvasLauncherAuth
  }
}

export default () => activeAuth
