import React, { FormEvent, useState } from 'react'
import moment, { Moment } from 'moment'
import { useFormatMessage } from '@babylon/intl'
import { Button } from '@babylon/web-platform-ui'

import DateInput from '../DynamicForm/fields/DateInput'
import ArrowRight from '../../ArrowRight'
import { DateParams, QuestionInterface } from '../../../constants/types'
import { APP_LOCALE } from '../../../api/config'

import messages from './messages'

import styles from './StandaloneDateInputV2.module.scss'

const DEFAULT_OPTIONS: DateParams = {
  minDate: `${new Date().getFullYear() - 126}-01-01` as string,
  maxDate: moment().format('YYYY-MM-DD'),
}

const getDateParams = (question?: QuestionInterface) => {
  const { minDate, maxDate } = question?.dateParams || DEFAULT_OPTIONS
  return { minDate: moment(minDate), maxDate: moment(maxDate) }
}

const format = (mo: Moment) => mo.toDate().toLocaleDateString(APP_LOCALE)

const StandaloneDateInput = ({
  loading,
  label,
  onSubmit,
  question,
  setError,
}: Props) => {
  const { minDate, maxDate } = getDateParams(question)
  const [date, setDate] = useState('')
  const translate = useFormatMessage()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!date) {
      setError(translate(messages.errorRequired))
      return
    }

    const mo = moment(date)
    const isValid =
      mo.isValid() && mo.isSameOrAfter(minDate) && mo.isSameOrBefore(maxDate)

    if (!isValid) {
      setError(
        translate(messages.invalidDate, {
          minDate: format(minDate),
          maxDate: format(maxDate),
        })
      )
      return
    }

    onSubmit(date)
  }

  const onChange = (newDate: string) => {
    setError('')
    setDate(newDate)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DateInput label={label} onChange={onChange} />
      <div className={styles.button}>
        <Button
          data-testid="date-input-submit-button"
          isLoading={loading}
          loadingText=""
          rightIcon={<ArrowRight />}
          type="submit"
        >
          {translate(messages.submitLabel)}
        </Button>
      </div>
    </form>
  )
}

interface Props {
  label: string
  loading: boolean
  onSubmit: (date: string) => void
  question?: QuestionInterface
  setError: (error: string) => void
}

export default StandaloneDateInput
