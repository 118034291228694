import { QuestionOptionInterface } from '../../../constants/types'
import { CallToActionTypes } from '../../../constants/enums'

const disclaimerForOption = (option: QuestionOptionInterface) => {
  if (option.action?.type === CallToActionTypes.WebView) {
    return option.action.data.disclaimer
  }

  return null
}

export { disclaimerForOption }
