import {
  processedConversationStepInterface,
  ContextInterface,
} from '../constants/types'

const formatConversationStep = (
  processed: processedConversationStepInterface,
  context: ContextInterface
) => {
  const {
    lastNewMessageId,
    lastUserMessageId,
    etag,
    progress,
    nextQuestion,
    feedbackUrl,
    status,
  } = processed

  return {
    nextQuestion: {
      question: {
        progress,
        ...nextQuestion,
      },
      answerProcessorName: 'chatscriptProcessor',
      answerProcessorOptions: {},
      conversationContext: {
        ...context,
        status,
        lastEtag: etag,
        elementId: lastNewMessageId,
      },
    },
    createdElementId: lastUserMessageId,
    feedbackUrl,
  }
}

export default formatConversationStep
