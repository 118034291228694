import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

const login = (translate: IntlFormatters['formatMessage']) => ({
  question: {
    fields: [
      {
        label: translate(messages.email),
        name: 'email',
        type: 'email',
      },
      {
        label: translate(messages.password),
        name: 'password',
        type: 'password',
      },
      {
        label: translate(messages.submitButton),
        type: 'submit',
      },
      {
        type: 'forgotten_password',
      },
    ],
    text: translate(messages.heading),
    type: inputTypes.FORM,
    undoable: true,
  },
  answerProcessorName: 'loginProcessor',
  answerProcessorOptions: {},
  conversationContext: {},
})

export default login
