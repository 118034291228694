/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Heading, Link } from '@babylon/web-platform-ui'

import messages from './messages'
import styles from './SearchConditionsPanelV2.module.scss'

const SearchConditionsPanel = ({ isLoading, onClick }: Props) => {
  const translate = useFormatMessage()

  return (
    <div className={styles.panel}>
      <div className={styles.question}>
        <Heading size="xs">{translate(messages.question)}</Heading>
      </div>
      <Link
        as="button"
        data-testid="condition-search-button"
        disabled={isLoading}
        onClick={onClick}
        type="button"
        variant="standalone"
      >
        {translate(messages.callToAction)}
      </Link>
    </div>
  )
}

interface Props {
  isLoading: boolean
  onClick: () => void
}

export default SearchConditionsPanel
