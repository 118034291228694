import React from 'react'
import {
  BulletList,
  BulletListItem,
  Heading,
  Text,
} from '@babylon/web-platform-ui'

import styles from './HelpTips.module.scss'

const HelpTips = () => (
  <div>
    <div className={styles.heading}>
      <Heading size="sm">Advanced Usage</Heading>
    </div>
    <Text>
      By adding query string parameters to the url you can test extra
      functionality:
    </Text>
    <BulletList as="ul">
      <BulletListItem>
        <Text as="code">session_id=test</Text> - make this tool accessible to
        the public
      </BulletListItem>
      <BulletListItem>
        <Text as="code">app_id=nhs-rbft</Text> - use a different app name
        (default is babylon)
      </BulletListItem>
      <BulletListItem>
        <Text as="code">app_locale=pt-BR</Text> - use a different locale
        (default is en-GB
      </BulletListItem>
    </BulletList>
    <Text>For example, add the following to the end of the url:</Text>
    <Text as="code">?session_id=test&app_locale=pt-BR</Text>
  </div>
)

export default HelpTips
