/* eslint-disable camelcase */
import React, { useState } from 'react'
import { Button, Heading, Text } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'

import {
  CardInterface,
  QuestionOptionInterface,
  SuggestionInterface,
} from '../../../../constants/types'
import Banner from '../Banner'
import ArrowRight from '../../../ArrowRight'
import messages from './messages'

import styles from './SelectedSuggestionV2.module.scss'

const SelectedSuggestionV2 = ({
  cards,
  handleCallToAction,
  loading,
  onClick,
  suggestion,
}: Props) => {
  const translate = useFormatMessage()
  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false)
  const { banner_ids, description, name } = suggestion

  const handleClickSuggestion = () => {
    setIsSuggestionClicked(true)
    onClick()
  }

  const isSuggestionDisabled = !isSuggestionClicked && loading
  const isSuggestionLoading = isSuggestionClicked && loading

  return (
    <div className={styles.selectedSuggestion}>
      <div className={styles.card}>
        <div className={styles.heading}>
          <Heading size="sm" data-testid="selected-suggestion-title">
            {name}
          </Heading>
        </div>
        <Text data-testid="selected-suggestion-description">{description}</Text>
        <div className={styles.suggestionButton}>
          <Button
            isDisabled={isSuggestionDisabled}
            isLoading={isSuggestionLoading}
            loadingText=""
            onClick={handleClickSuggestion}
            variant="secondary"
            rightIcon={<ArrowRight />}
          >
            {translate(messages.suggestionCardButton)}
          </Button>
        </div>
      </div>
      {banner_ids && (
        <div className={styles.bannerList}>
          {banner_ids.map((banner_id) => {
            const bannerCard = cards.find(
              (card) => card.banner_id === banner_id
            )

            if (!bannerCard) {
              return null
            }

            return (
              <Banner
                key={banner_id}
                card={bannerCard}
                handleCallToAction={handleCallToAction}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

interface Props {
  cards: CardInterface[]
  handleCallToAction: (option: QuestionOptionInterface) => void
  loading: boolean
  onClick: () => void
  suggestion: SuggestionInterface
}

export default SelectedSuggestionV2
