const readFileAsync = (file) => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.addEventListener('load', (data) => {
      resolve(data.target.result)
    })

    reader.addEventListener('error', (err) => {
      reject(err)
    })

    // Avoid the reader to crash if user click on cancel
    try {
      reader.readAsDataURL(file)
    } catch (error) {
      console.warn('Error:', error)
    }
  })
}

export default readFileAsync
