import React from 'react'
import { Icon, Text, useColorTheme } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'

import messages from './messages'
import styles from './ErrorMessage.module.scss'

const ErrorMessage = ({ error, textStyle }: Props) => {
  const translate = useFormatMessage()
  const { colors } = useColorTheme()

  return (
    <div className={styles.container}>
      <Icon icon="AlertError" aria-label={translate(messages.iconLabel)} />
      <Text textStyle={textStyle} color={colors.status.error}>
        {error}
      </Text>
    </div>
  )
}

interface Props {
  error: string
  textStyle?: React.ComponentProps<typeof Text>['textStyle']
}

export default ErrorMessage
