import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Select, Text, TextInput } from '@babylon/web-platform-ui'

import { ConversationTypes } from '../../constants/enums'

import styles from './styles.module.scss'

const setValue = (setter) => (ev) => setter(ev.target.value)

const ConfigureChatbot = () => {
  const [showErrors, setShowErrors] = useState(false)
  const [gender, setGender] = useState('male')
  const [age, setAge] = useState('')
  const [type, setType] = useState(ConversationTypes.GENERAL)
  const [data, setData] = useState()
  const location = useLocation()

  const isDataValid = type === ConversationTypes.HEALTHCHECK ? !!data : true
  const isValid = age.length && gender.length && isDataValid

  let baseUrl = `/${gender}/${age}/${type}`

  if (data) {
    baseUrl += `/${data}`
  }

  return (
    <div className={styles.container}>
      <Text>Please enter gender and age to begin</Text>

      <Select label="Gender" value={gender} onChange={setValue(setGender)}>
        <option value="male">Male</option>
        <option value="female">Female</option>
      </Select>

      <TextInput
        value={age}
        label="Age"
        type="number"
        errorMessage={showErrors && !age ? 'Required' : undefined}
        onChange={setValue(setAge)}
        onBlur={() => setShowErrors(true)}
      />

      <Select
        label="Conversation type"
        value={type}
        onChange={setValue(setType)}
        name="type"
      >
        <option value={ConversationTypes.GENERAL}>
          {ConversationTypes.GENERAL}
        </option>
        <option value={ConversationTypes.HEALTHCHECK}>
          {ConversationTypes.HEALTHCHECK}
        </option>
        <option value={ConversationTypes.INFORMATION}>
          {ConversationTypes.INFORMATION}
        </option>
      </Select>

      {type === ConversationTypes.HEALTHCHECK && (
        <TextInput
          value={data}
          label="flow_id"
          type="text"
          errorMessage={showErrors && !isDataValid ? 'Required' : undefined}
          onChange={setValue(setData)}
          onBlur={() => setShowErrors(true)}
        />
      )}

      <Button
        as="a"
        isDisabled={!isValid}
        isFullWidth
        href={`${baseUrl}/${location.search}`}
      >
        Start
      </Button>
    </div>
  )
}

export default ConfigureChatbot
