import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import { PrivacyNoticeTypes } from '../../constants/enums'
import { QuestionFieldInterface } from '../../constants/types'
import { getProductConfig } from '../../api'
import messages from './messages'

interface notice {
  onboardingText?: string
  noticeType: string
  noticeVersionId: string
}

const getNoticeByType = (notices: notice[], type: PrivacyNoticeTypes) =>
  notices.find(({ noticeType }) => noticeType.toLowerCase() === type)

const registerAccount = async (
  fields: QuestionFieldInterface,
  notices: notice[],
  translate: IntlFormatters['formatMessage']
) => {
  const termsAndConditionsPolicy = getNoticeByType(
    notices,
    PrivacyNoticeTypes.TermsAndConditions
  )
  const privacyPolicy = getNoticeByType(
    notices,
    PrivacyNoticeTypes.PrivacyPolicy
  )
  let marketingPolicy

  const config = await getProductConfig()
  const isMarketingEnabled = config?.marketingOptInEnabled

  if (isMarketingEnabled) {
    marketingPolicy = getNoticeByType(notices, PrivacyNoticeTypes.Marketing)
  }

  return {
    question: {
      fields: [
        {
          label: translate(messages.email),
          name: 'email',
          type: 'email',
        },
        {
          label: translate(messages.password),
          name: 'password',
          tip: translate(messages.passwordTip),
          type: 'password',
        },
        {
          name: 'terms_and_conditions_accepted',
          props: {
            policy: termsAndConditionsPolicy,
            privacyPolicy,
          },
          type: 'terms_and_conditions',
        },
        marketingPolicy && {
          label: marketingPolicy.onboardingText,
          name: marketingPolicy.noticeVersionId,
          type: 'checkbox',
        },
        {
          label: translate(messages.submitButton),
          type: 'submit',
        },
      ].filter(Boolean),
      progress: 0.9,
      text: translate(messages.heading),
      type: inputTypes.FORM,
      undoable: true,
    },
    answerProcessorName: 'registerProcessor',
    answerProcessorOptions: {
      fields,
      policyIds: [termsAndConditionsPolicy?.noticeVersionId],
      optionalPolicyIds: [marketingPolicy?.noticeVersionId],
    },
    conversationContext: {},
  }
}

export default registerAccount
