const inputTypes = {
  CONDITION_SEARCH: 'CONDITION_SEARCH',
  DATE_SELECT: 'DATE_SELECT',
  DRAFT: 'DRAFT',
  FORM: 'FORM',
  MAP: 'MAP',
  MULTI_SELECT: 'MULTI_SELECT',
  NOOP: 'NOOP',
  PASSWORD: 'PASSWORD',
  PHONE_INPUT: 'PHONE_INPUT',
  SELECT: 'SELECT',
  SYMPTOM_SELECT: 'SYMPTOM_SELECT',
  TEXT: 'TEXT',
  PRIVACY_SETTINGS: 'PRIVACY_SETTINGS',
}

export default inputTypes
