const simpleQuestionProcessor = () => async (conversation, answer) => {
  const options = conversation.nextQuestion.answerProcessorOptions
  const context = conversation.nextQuestion.conversationContext
  let nextQuestion = options.nextQuestion

  if (!nextQuestion) {
    throw new Error(
      'simpleQuestionProcessor requires an option of nextQuestion which was not given'
    )
  }

  if (typeof nextQuestion === 'function') {
    nextQuestion = await nextQuestion()
  }

  return {
    nextQuestion: {
      ...nextQuestion,
      conversationContext: {
        ...nextQuestion.conversationContext,
        ...context,
      },
    },
    answeredQuestions: [
      ...conversation.answeredQuestions,
      { question: conversation.nextQuestion, answer },
    ],
  }
}

export default simpleQuestionProcessor
