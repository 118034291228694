import React, { useState, useEffect } from 'react'

import styles from './styles.module.scss'

export default function ChatbotAccessory({ question, context }) {
  const [caseId, setCaseId] = useState(null)
  const questionText = question.text

  useEffect(() => {
    const match =
      questionText.match && questionText.match(/case id: ([\w]{5})$/)

    match && setCaseId(match[1])
  }, [questionText])

  return (
    <div className={styles.root}>
      <h2>Debug Info</h2>

      <p>
        <strong>conversationId:</strong> <code>{context.conversationId}</code>
        <br />
        <strong>questionId:</strong>{' '}
        <code>{question.id || <em>no question ID</em>}</code>
        {caseId && (
          <>
            <br />
            <strong>case ID:</strong> <code>{caseId}</code>
          </>
        )}
      </p>
    </div>
  )
}
