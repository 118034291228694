import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import { LocationActionTypes, LocationTypes } from '../../constants/enums'
import messages from './messages'

const sourceTypeMap = {
  [LocationActionTypes.FindPlaceEmergencyRoom]: {
    message: messages.emergencyRoom,
    placeType: LocationTypes.AccidentAndEmergency,
  },
  [LocationActionTypes.FindPlaceEyeCasualty]: {
    message: messages.eyeCasualty,
    placeType: LocationTypes.AccidentAndEmergency,
  },
  [LocationActionTypes.FindPlaceHospital]: {
    message: messages.hospital,
    placeType: LocationTypes.AccidentAndEmergency,
  },
  [LocationActionTypes.FindPlacePharmacy]: {
    message: messages.pharmacy,
    placeType: LocationTypes.Pharmacies,
  },
  [LocationActionTypes.FindPlaceSTDClinic]: {
    message: messages.stdClinic,
    placeType: LocationTypes.SexualHealthServices,
  },
  [LocationActionTypes.FindPlaceUrgentCare]: {
    message: messages.urgentCare,
    placeType: LocationTypes.AccidentAndEmergency,
  },
}

const findNearby = (
  sourceType: LocationActionTypes,
  translate: IntlFormatters['formatMessage']
) => {
  const { message, placeType } = sourceTypeMap[sourceType]

  return {
    question: {
      placeType,
      text: translate(message),
      type: inputTypes.MAP,
      undoable: true,
    },
  }
}

export default findNearby
