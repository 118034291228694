import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

const conditionSearch = (translate: IntlFormatters['formatMessage']) => ({
  question: {
    text: translate(messages.heading),
    type: inputTypes.CONDITION_SEARCH,
    undoable: true,
  },
})

export default conditionSearch
