import React, { useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Button, PopOver } from '@babylon/web-platform-ui'

import { setFocusPausedState } from '../Overlay'
import sanitize from '../../util/sanitize'
import messages from './messages'

const PrivacyNoticeModal = ({ isOpen, longText, onClose }: Props) => {
  const translate = useFormatMessage()

  useEffect(() => {
    if (isOpen) {
      setFocusPausedState(true)
    } else {
      setFocusPausedState(false)
    }

    return () => setFocusPausedState(false)
  }, [isOpen])

  return (
    <PopOver
      primaryButton={
        <Button onClick={onClose} key="close-modal">
          {translate(messages.close)}
        </Button>
      }
      isOpen={isOpen}
      onClose={onClose}
      variant="large"
    >
      <div
        data-testid="noticeHtml"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: sanitize(longText),
        }}
      />
    </PopOver>
  )
}

interface Props {
  isOpen: boolean
  longText: string
  onClose: () => void
}

export default PrivacyNoticeModal
