import { last } from 'lodash'
import { inputTypes } from '../constants'
import questionFromChatbotElement from './questionFromChatbotElement'

import { trackCompletedChat, trackRatingDisplayed } from './tracking'

import {
  replyWithMessageResponseRest,
  ChatscriptMessage,
} from '../constants/types'

import { ChatscriptMessageSender } from '../constants/enums'

import { getPgmAssessment } from '../api'

const groupMessagesBySender = (messages: ChatscriptMessage[]) => {
  const user: ChatscriptMessage[] = []
  let foreign: ChatscriptMessage[] = []

  // Get the last group of foreign messages since the last user message
  // if the messages are:
  //  - 1: (user)
  //  - 2: (foreign)
  //  - 3: (user)
  //  - 4: (foreign)
  //  - 5: (user)
  //  - 6: (foreign)
  //  - 7: (foreign)
  //  - 8: (foreign)
  // then it should return { foreign: [6, 7, 8], user: [1, 3, 5] }
  if (messages) {
    messages.forEach((msg) => {
      if (msg.sender === ChatscriptMessageSender.User) {
        foreign = []
        user.push(msg)
      } else {
        foreign.push(msg)
      }
    })
  }

  return { user, foreign }
}

const processChatscriptResponseRest = async (
  context: any,
  response: replyWithMessageResponseRest
) => {
  const { user: userMessages, foreign: newMessages } = groupMessagesBySender(
    response.messages
  )

  const lastUserMessage = last(userMessages)
  const lastNewMessage = last(newMessages)

  const nextQuestion = await questionFromChatbotElement(
    newMessages,
    lastUserMessage?.undoable
  )

  if (nextQuestion.starRating) {
    trackRatingDisplayed()
  }

  const progressValue = response.conversation.progress

  let progress =
    progressValue === null ? null : Math.max(0.01, progressValue / 100)

  if (nextQuestion.type === inputTypes.DRAFT) {
    // TODO add types
    // This overrides the text provided as a placeholder for the "text and files" input so there's no pre-filled input box.
    // @ts-ignore
    nextQuestion.value = ''
  }

  if (lastNewMessage?.type === 'diagnosis') {
    const { conversationId } = context
    // TODO add types
    // this one is needed, we use the object to display the PGMReport component at the end of a Triage flow here
    // https://github.com/babylonhealth/monoweb/blob/6ab9aabdc5af2725905168376990b98cc6c844e0/chatbot-web/src/components/QuestionCard/QuestionCard.tsx#L100
    // @ts-ignore
    nextQuestion.pgmReport = await getPgmAssessment({ conversationId })

    progress = null
  }

  if (response.conversation.status === 'finished') {
    const { conversationId } = context
    trackCompletedChat(conversationId)
  }

  return {
    lastNewMessageId: lastNewMessage?.id,
    lastUserMessageId: lastUserMessage?.id,
    etag: response.etag,
    progress,
    nextQuestion,
    feedbackUrl: response?.status?.feedback_url,
    status: response.conversation.status,
  }
}

export default processChatscriptResponseRest
