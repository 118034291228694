import { defineMessages } from 'react-intl'

export default defineMessages({
  diagnosisReportHeadingTwo: {
    id: 'diagnosesReportHeadingTwo',
    defaultMessage: 'Why this could be a potential cause?',
  },
  diagnosisReportParagraph: {
    id: 'diagnosisReportParagraph',
    defaultMessage:
      'Some of your answers indicate that this condition could be the cause:',
  },
  nhsChoices: {
    id: 'nhsChoices',
    defaultMessage: 'Read more on NHS Choices',
  },
})
