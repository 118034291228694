import { microservicesClient } from '../client'

const getProductConfig = async (): Promise<ChatbotProductConfig> => {
  const { chatbot, consumer_web } = await microservicesClient
    .get('product-config/v1/app-config')
    .then((response) => response.data)

  return {
    appointmentBookingEnabled: consumer_web?.appointment?.booking?.enabled,
    conditionSearchEnabled: chatbot?.web?.condition_search_enabled,
    coronavirusBannerEnabled: chatbot?.web?.coronavirus_banner_enabled,
    marketingOptInEnabled: chatbot?.web?.marketing_opt_in_enabled,
  }
}

interface ChatbotProductConfig {
  appointmentBookingEnabled?: boolean
  conditionSearchEnabled?: boolean
  coronavirusBannerEnabled?: boolean
  marketingOptInEnabled?: boolean
}

export default getProductConfig
