import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Text } from '@babylon/web-platform-ui'

import messages from './messages'
import styles from './styles.module.scss'

const NoResults = () => {
  const translate = useFormatMessage()

  return (
    <div className={styles.noResults} data-testid="no-results">
      <Text>{translate(messages.noResultsOne)}</Text>
      <Text>{translate(messages.noResultsTwo)}</Text>
    </div>
  )
}

export default NoResults
