import { microservicesClient } from '../client'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const getConversationUser = ({ conversationId, memberUuid }: Props) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .get<Response>(`${baseUrl}/conversations/${conversationId}/user`)
    .then((response) => response.data)
}

interface Props {
  conversationId: string
  memberUuid?: string
}

interface Response {
  patient: {
    uuid: string
  }
  user: {
    uuid: string
  }
}

export default getConversationUser
