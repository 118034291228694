import { combineReducers } from 'redux'

let reducers

const initialState = {}

const combine = (reducers) => {
  const reducerNames = Object.keys(reducers)
  Object.keys(initialState).forEach((key) => {
    if (reducerNames.indexOf(key) === -1) {
      reducers[key] = (state = initialState[key]) => state
    }
  })

  return combineReducers(reducers)
}

const createReducers = () => {
  reducers = {}

  return combine(reducers)
}

const getReducers = () => reducers

export { initialState, getReducers }
export default createReducers
