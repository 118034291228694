import React, { useState } from 'react'

import TextInput from '../TextInput'
import FileInputButton from './FileInputButton'
import Thumbnail from '../../Thumbnail'

import { TextInputProps } from '../TextInput/types'
import { ImageFile } from './types'
import styles from './TextFileInput.module.scss'

const TextFileInput = ({ value, onSubmit, ...props }: TextInputProps) => {
  const [inputValue, setInputValue] = useState(value)
  const [files, setFiles] = useState<ImageFile[]>([])

  const addFiles = (newFiles: ImageFile[]) => {
    setFiles([...files, ...newFiles])
  }

  const removeFile = (index: number) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const handleSubmit = () => {
    const data = new FormData()
    data.append('draft', inputValue)

    files.forEach((f) => data.append('files', f))

    onSubmit(data)
  }

  const fileLimitReached = files.length >= 3

  return (
    <>
      <TextInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        value={inputValue}
        onTextInputChange={setInputValue}
        onSubmit={handleSubmit}
        startAdornment={
          <FileInputButton
            disabled={fileLimitReached}
            onFileUpload={addFiles}
          />
        }
      />
      <div className={styles.thumbnails_container}>
        {files.map((f, index) => (
          <Thumbnail
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            src={f.preview}
            index={index}
            onRemove={removeFile}
          />
        ))}
      </div>
    </>
  )
}

export default TextFileInput
