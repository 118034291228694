const { APP_ID } = window.babylonConfig || {}

let appNameOverride

// Overrides
if (typeof window === 'object') {
  const appNameQueryString = window.location.search.match(
    /app_id=([a-zA-Z_0-9-]+)/
  )
  appNameOverride = appNameQueryString ? appNameQueryString[1] : undefined
}

const APP_NAME = appNameOverride || APP_ID || 'babylon'

export { APP_NAME }
