import React from 'react'

const NHSIcon = ({ className }: NHSIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <defs>
      <path
        d="M0 17V7h24v10H0zm9.65-9.035H7.677L6.58 13.547h-.022L4.956 7.965H2.347l-1.67 8.049h1.971l1.088-5.57h.022l1.635 5.57h2.6l1.656-8.05zm7.436 0h-2.094l-.616 3.08H11.9l.616-3.08h-2.094l-1.624 8.049h2.093l.695-3.447h2.476l-.694 3.447h2.093l1.625-8.05zm6.015.22c-.527-.254-1.433-.358-2.397-.358-1.714 0-3.484.623-3.484 2.7 0 2.27 3.035 1.785 3.035 3.077 0 .83-1.018.958-1.668.958-.683 0-1.513-.185-1.926-.463l-.493 1.638c.683.22 1.612.416 2.419.416 1.814 0 3.82-.577 3.82-2.802 0-2.445-3.036-2.019-3.036-3.16 0-.646.672-.772 1.5-.772.773 0 1.322.173 1.725.368l.505-1.603z"
        id="a"
      />
    </defs>
    <use fill="#035BBB" xlinkHref="#a" fillRule="evenodd" />
  </svg>
)

type NHSIconProps = {
  className?: string
}

export default NHSIcon
