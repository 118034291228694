import { questionWithError, startNewConversation } from '../api'
import messages from './messages'

const chatbot = (
  answerProcessors,
  initialQuestion,
  answerProcessorOptions,
  translate
) => {
  const bot = {
    conversation: {
      nextQuestion: initialQuestion,
      answeredQuestions: [],
      answerProcessorOptions,
    },
    answer: async ({ answerValue }) => {
      if (!bot.conversation.nextQuestion) {
        throw new Error('answer method invoked on completed conversation')
      }

      let updatedConversation

      try {
        const processorName = bot.conversation.nextQuestion.answerProcessorName

        const answerProcessor = answerProcessors[processorName]

        if (answerProcessor.length === 2) {
          updatedConversation = await answerProcessor(
            bot.conversation,
            answerValue,
            translate
          )
        } else {
          updatedConversation = await answerProcessor({})(
            bot.conversation,
            answerValue,
            translate
          )
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        updatedConversation = {
          ...bot.conversation,
          nextQuestion: questionWithError(
            bot.conversation.nextQuestion,
            translate(messages.chatbotErrorMessage)
          ),
        }
      }

      bot.conversation = updatedConversation

      return true
    },
    back: async () => {
      const lastQuestion = bot.conversation.answeredQuestions.slice(-1)[0]
        .question

      bot.conversation = {
        nextQuestion: {
          ...lastQuestion,
          conversationContext: {
            ...lastQuestion.conversationContext,

            // 'Forget' that this question has been replied to.
            // Required for back to work properly in PGM Report
            createdElementId: null,
          },
        },
        answeredQuestions: bot.conversation.answeredQuestions.slice(0, -1),
      }

      return true
    },
    reset: async () => {
      bot.conversation = {
        nextQuestion: initialQuestion,
        answeredQuestions: [],
        answerProcessorOptions,
      }

      return true
    },
    startNewConversation: async (type, data) => {
      const {
        memberUuid,
        userId,
        userUuid,
      } = bot.conversation.nextQuestion.conversationContext
      const nextQuestion = await startNewConversation({
        memberUuid,
        options: {
          data,
          type,
        },
        userId,
      })

      bot.conversation = {
        nextQuestion: {
          ...nextQuestion,
          conversationContext: {
            userUuid,
            ...nextQuestion.conversationContext,
          },
        },
        answeredQuestions: [
          ...bot.conversation.answeredQuestions,
          { question: bot.conversation.nextQuestion, answer: null },
        ],
      }
    },
    goToQuestion: async (question, args = []) => {
      if (!bot.conversation.nextQuestion) {
        throw new Error(
          'Attempted to navigate to a question from a completed conversation'
        )
      }

      let nextQuestion = null

      if (typeof question === 'function') {
        nextQuestion = args
          ? await question.apply(this, args)
          : question(translate)
      } else {
        nextQuestion = question
      }

      const context = bot.conversation.nextQuestion?.conversationContext
      const conversationId = context?.conversationId
      const memberUuid = context?.memberUuid
      const userId = context?.userId
      const userUuid = context?.userUuid

      bot.conversation = {
        nextQuestion: {
          ...nextQuestion,
          conversationContext: {
            conversationId,
            memberUuid,
            userId,
            userUuid,
            ...nextQuestion.conversationContext,
          },
        },
        answeredQuestions: [
          ...bot.conversation.answeredQuestions,
          { question: bot.conversation.nextQuestion, answer: null },
        ],
      }
    },
  }

  return bot
}

export default chatbot
