import { createSelector } from 'reselect'

const selectConversationId = (state) => state.chatbot.context.conversationId

const currentConversationId = createSelector(
  [selectConversationId],
  (conversationId) => conversationId
)

export { currentConversationId }
