import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

const forgottenPassword = (translate: IntlFormatters['formatMessage']) => ({
  question: {
    fields: [
      {
        label: translate(messages.email),
        name: 'email',
        type: 'email',
      },
      {
        label: translate(messages.submitButton),
        type: 'submit',
      },
    ],
    text: translate(messages.heading),
    type: inputTypes.FORM,
    undoable: true,
  },
  answerProcessorName: 'passwordResetProcessor',
  answerProcessorOptions: {},
  conversationContext: {},
})

export default forgottenPassword
