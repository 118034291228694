import { defineMessages } from 'react-intl'

export default defineMessages({
  submit: {
    id: 'starRating.submit',
    defaultMessage: 'Send feedback',
  },
  subTitle: {
    id: 'starRating.subTitle',
    defaultMessage: 'Help us improve',
  },
  textPlaceholder: {
    id: 'starRating.textPlaceholder',
    defaultMessage: 'Leave a comment',
  },
  textQuestion: {
    id: 'starRating.textQuestion',
    defaultMessage: 'Is there anything we could improve?',
  },
  thankYou: {
    id: 'starRating.thankYou',
    defaultMessage:
      'Thanks for your rating. Your feedback will help improve the experience.',
  },
  title: {
    id: 'starRating.title',
    defaultMessage: 'How would you rate your experience?',
  },
  starTitle: {
    id: 'starRating.starTitle',
    defaultMessage: '{numberOfStars} stars',
  },
})
