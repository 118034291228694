const addDataToQuestionFields = (question, values, errors) => ({
  ...question,
  question: {
    ...question.question,
    fields: question.question.fields.map((f) => ({
      ...f,
      error: Array.isArray(errors[f.name]) ? errors[f.name][0] : errors[f.name],
      value: values[f.name],
    })),
  },
})

export default addDataToQuestionFields
