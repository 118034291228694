import React from 'react'
import { BabylonIntl } from '@babylon/intl'

import enCAMessages from './messages/en_CA.json'
import enGBMessages from './messages/en_GB.json'
import enUSMessages from './messages/en_US.json'
import esUSMessages from './messages/es_US.json'
import frCAMessages from './messages/fr_CA.json'
import ptBRMessages from './messages/pt_BR.json'
import rwRWMessages from './messages/rw_RW.json'
import zhCNMessages from './messages/zh_CN.json'
import viVNMessages from './messages/vi_VN.json'
import viMessages from './messages/vi.json'

const messagesMap = {
  'en-CA': enCAMessages,
  'en-GB': enGBMessages,
  'en-US': enUSMessages,
  'es-US': esUSMessages,
  'fr-CA': frCAMessages,
  'pt-BR': ptBRMessages,
  'rw-RW': rwRWMessages,
  'zh-CN': zhCNMessages,
  'vi-VN': viVNMessages,
  vi: viMessages,
}

const IntlWrapper = ({ appLocale = 'en-GB', children }) => (
  <BabylonIntl
    textComponent="span"
    initialLocale={appLocale}
    initialMessages={messagesMap[appLocale]}
  >
    {children}
  </BabylonIntl>
)

export default IntlWrapper
