import { IntlFormatters } from 'react-intl'

import { CallToActionTypes, PGMActionTypes } from '../../constants/enums'
import {
  PGMActionInterface,
  QuestionOptionInterface,
  LocationParams,
} from '../../constants/types'
import messages from './messages'

const getOptionForPGMAction = (
  action: PGMActionInterface,
  translate: IntlFormatters['formatMessage'],
  value: string = ''
): QuestionOptionInterface => {
  const { type } = action
  const label = action.label || ''

  if (type === PGMActionTypes.phone) {
    return {
      action: {
        type: CallToActionTypes.Phone,
        data: {
          confirmation_text: translate(messages.callEmergencyServices),
          number: action.data.number!,
        },
      },
      label,
      value,
    }
  }

  if (type === PGMActionTypes.webview) {
    return {
      action: {
        type: CallToActionTypes.WebView,
        data: {
          url: action.data.url!,
        },
      },
      label,
      value,
    }
  }

  if (type === PGMActionTypes.location) {
    return {
      label,
      params: action.data as LocationParams,
      type: PGMActionTypes.location,
      value,
    }
  }

  if (type === PGMActionTypes.custom) {
    return {
      action: {
        type: CallToActionTypes.Custom,
        data: {
          type: action.data.type!,
        },
      },
      label,
      value,
    }
  }

  // This is huge trouble, this triage_outcome and PGMActionInterface really needs to be properly typed, this else case includes things like Appview book appointments
  // TODO grab the types from triage (even chatbot doesnt type it!)
  return {
    // @ts-ignore
    action,
    label,
    value,
  }
}

export default getOptionForPGMAction
