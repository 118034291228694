import React, { useEffect } from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Button,
  Link,
  PopOver,
  Text,
  useDisclosure,
} from '@babylon/web-platform-ui'

import { setFocusPausedState } from '../Overlay'
import { trackExplanationDisplayed } from '../../util/tracking'
import messages from './messages'
import styles from './HelpTextButtonV2.module.scss'

const HelpTextButton = ({ conversationId, helpText }: Props) => {
  const translate = useFormatMessage()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = () => {
    trackExplanationDisplayed(conversationId)
    onOpen()
  }

  useEffect(() => {
    if (isOpen) {
      setFocusPausedState(true)
    } else {
      setFocusPausedState(false)
    }

    return () => setFocusPausedState(false)
  }, [isOpen])

  return (
    <>
      <PopOver
        primaryButton={
          <Button onClick={onClose} variant="secondary">
            {translate(messages.submit)}
          </Button>
        }
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={styles.modalContent}>
          {helpText
            .split(/\n+/)
            .filter(Boolean)
            .map((line) => (
              <Text key={line}>{line}</Text>
            ))}
        </div>
      </PopOver>
      <div className={styles.buttonContainer}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link as="button" onClick={openModal} type="button">
          {translate(messages.label)}
        </Link>
      </div>
    </>
  )
}

interface Props {
  conversationId?: string
  helpText: string
}

export default HelpTextButton
