import React, { useState } from 'react'

import { Checkbox } from '@babylon/web-platform-ui'
import { useFormatMessage } from '@babylon/intl'

import OptionPicker from '../../OptionPicker'
import {
  getDefaultSelectedOptions,
  getKey,
  selectOption,
} from './multiSelectUtils'
import { MultiSelectProps } from './MultiSelectProps'
import messages from './messages'

const MultiSelectInputV2 = ({
  legend,
  navigatingBack,
  loading,
  question,
  onSubmit,
  previousAnswer,
  setError,
}: MultiSelectProps) => {
  const translate = useFormatMessage()
  const options = question.options || []
  const [selected, setSelected] = useState(getDefaultSelectedOptions(options))
  const buttonOnClick = () => {
    if (selected.length === 0) {
      setError(translate(messages.errorRequired))
      return
    }
    onSubmit(selected)
  }
  const hasSelectedOptions = !!previousAnswer

  return (
    <OptionPicker
      legend={legend}
      options={options}
      renderOption={(opt) => {
        const isChecked = selected.includes(opt.value)

        return (
          <Checkbox
            variant="bordered"
            key={getKey(opt.label, isChecked)}
            id={opt.value}
            label={opt.label}
            value={opt.value}
            data-testid={opt.label}
            onChange={() => {
              selectOption(options, setSelected, selected, opt.value)
              setError('')
            }}
            isChecked={isChecked}
            isDisabled={hasSelectedOptions}
          />
        )
      }}
      buttonIsDisabled={navigatingBack || hasSelectedOptions}
      buttonIsLoading={!navigatingBack && loading}
      buttonOnClick={buttonOnClick}
    />
  )
}

export default MultiSelectInputV2
