const initialState = {
  starRating: null,
  conversationId: '',
}

const context = (state = initialState, action) => {
  switch (action.type) {
    case 'CHATBOT.SET_CONVERSATION_ID':
      return {
        ...state,
        starRating: initialState.starRating,
        conversationId: action.conversationId,
      }

    case 'CHATBOT.SET_STAR_RATING':
      return {
        ...state,
        starRating: action.rating,
      }
    default:
      return state
  }
}

export default context
