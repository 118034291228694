import React from 'react'

import { Textarea as WebTextarea } from '@babylon/web-platform-ui'
import styles from './Textarea.module.scss'
import { FieldProps } from '../types'

const TextareaV2 = (props: FieldProps) => {
  const {
    'data-testid': testId,
    errors = [],
    error,
    disabled,
    label,
    name,
    placeholder,
    value,
    onChange,
  } = props

  const errorMessage = errors.length > 0 ? errors.join(' | ') : error

  return (
    <div className={styles.textFieldWrapper}>
      <WebTextarea
        label={label}
        id={name}
        placeholder={placeholder}
        value={value}
        errorMessage={errorMessage}
        isDisabled={disabled}
        data-testid={testId}
        onChange={onChange}
      />
    </div>
  )
}

export default TextareaV2
