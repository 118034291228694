import { last } from 'lodash'

import { NewConversation } from '../constants/types'
import questionFromChatbotElement from './questionFromChatbotElement'

const createInitialQuestion = async ({
  memberUuid,
  newConversation,
  userId,
}: Props) => ({
  question: {
    ...(await questionFromChatbotElement(newConversation.messages)),
  },
  conversationContext: {
    conversationId: newConversation.conversation.id,
    // @ts-ignore
    elementId: last(newConversation.messages).id,
    lastEtag: newConversation.etag,
    memberUuid,
    userId,
    status: newConversation.conversation.status,
  },
  answerProcessorName: 'chatscriptProcessor',
})

interface Props {
  memberUuid?: string
  newConversation: NewConversation
  userId?: string
}

export default createInitialQuestion
