import axios from 'axios'
import cookies from 'js-cookie'

import { createInitialQuestion } from '@babylon/chatbot-web'

import {
  MICROSERVICES_URL,
  GRAPHQL_ENDPOINT,
  APP_ID,
  APP_LOCALE,
} from '../config'

const axiosOptions = ({ csrfToken } = {}) => {
  const headers = {
    'X-App-Name': APP_ID,
    'X-Platform': 'web',
    'Accept-Language': APP_LOCALE,
  }

  if (csrfToken) {
    headers['X-Security-Token'] = csrfToken
  } else {
    const cookie = cookies.getJSON('autologin_info')

    if (cookie && cookie.csrf_token) {
      headers['X-Security-Token'] = cookie.csrf_token
    }
  }

  return {
    headers,
    withCredentials: true,
  }
}

const getDateOfBirth = (age) => {
  const dateOfBirth = new Date()
  dateOfBirth.setFullYear(dateOfBirth.getFullYear() - age)
  // ensure date is 1 day further back to account for timezones
  dateOfBirth.setDate(dateOfBirth.getDate() - 1)

  return dateOfBirth.toISOString().split('T')[0]
}

const testFixturesAuth = async ({ age, gender }) => {
  const { data } = await axios.post(
    `${GRAPHQL_ENDPOINT}/test-fixtures-auth`,
    {
      profile: {
        date_of_birth: getDateOfBirth(age),
        gender,
      },
      testName: 'chatbot-test-ui',
    },
    axiosOptions()
  )

  return {
    userId: data.userId,
    csrfToken: data.autologin_info.csrf_token,
  }
}

const testFixturesConversation = async ({ csrfToken, data, type }) => {
  const body = { type }

  if (data) {
    body.data = data
  }

  return axios.post(
    `${MICROSERVICES_URL}/chatscript/v3/conversations`,
    body,
    axiosOptions({ csrfToken })
  )
}

const getInitialQuestion = async (response, userId) => {
  const nextQuestion = await createInitialQuestion({
    newConversation: {
      conversation: response.data.conversation,
      etag: response.headers.etag,
      messages: response.data.messages,
    },
    userId,
  })
  nextQuestion.question.text = nextQuestion.question.text.replace(
    'Who needs my help?',
    'What symptom would you like to be checked?'
  )

  return nextQuestion
}

const startConversation = async (age, gender, type, data = null) => {
  const { userId, csrfToken } = await testFixturesAuth({ age, gender })
  const response = await testFixturesConversation({ csrfToken, data, type })

  return getInitialQuestion(response, userId)
}

// eslint-disable-next-line import/prefer-default-export
export { startConversation }
