import { defineMessages } from 'react-intl'

export default defineMessages({
  conditionSearchClear: {
    id: 'conditionSearch.clear',
    defaultMessage: 'Clear',
  },
  conditionSearchLabel: {
    id: 'conditionSearch.label',
    defaultMessage: 'Search for a condition',
  },
  conditionSearchPlaceholder: {
    id: 'conditionSearch.placeholder',
    defaultMessage: 'e.g. Asthma',
  },
  noResultsOne: {
    id: 'conditionSearch.noResults.one',
    defaultMessage:
      'Sorry, I couldn’t find any results for that search term. Please try entering the condition again.',
  },
  noResultsTwo: {
    id: 'conditionSearch.noResults.two',
    defaultMessage:
      'Or if you think something is seriously wrong, please seek medical advice straight away.',
  },
})
