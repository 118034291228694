import { microservicesClient } from '../client'
import { PGMReportInterface } from '../../constants/types'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const getPgmAssessment = ({ conversationId }: Props) => {
  const baseUrl = getBaseChatscriptUrl()

  return microservicesClient
    .get<Response>(
      `${baseUrl}/conversations/${conversationId}/attachment/triage_outcome`
    )
    .then((response) => response.data)
}

interface Props {
  conversationId: string
}

type Response = PGMReportInterface

export default getPgmAssessment
