import React from 'react'
import { Spinner } from '@babylon/web-platform-ui'

import styles from './Loading.module.scss'

const Loading = () => (
  <div className={styles.loading}>
    <Spinner size="lg" />
  </div>
)

export default Loading
