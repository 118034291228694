import { QuestionOptionInterface } from '../../../constants/types'

export const canBeSelectedWithOthers = (options: QuestionOptionInterface[]) => (
  val: string
) => options.find((o) => o.value === val)?.multiselect

export const getDefaultSelectedOptions = (options: QuestionOptionInterface[]) =>
  options.filter((opt) => opt.preselected).map((opt) => opt.value)

export const selectOption = (
  options: QuestionOptionInterface[],
  setSelected: (newSelected: string[]) => void,
  selected: string[],
  value: string
) => {
  const targetOption = options.find((o) => o.value === value)
  let newSelection: string[] = []

  if (!targetOption?.multiselect) {
    if (selected.includes(value)) {
      newSelection = []
    } else {
      newSelection = [value]
    }
  } else if (selected.includes(value)) {
    newSelection = selected
      .filter((el) => el !== value)
      .filter(canBeSelectedWithOthers(options))
  } else {
    newSelection = [...selected, value].filter(canBeSelectedWithOthers(options))
  }

  setSelected(newSelection)
}

// Fixes strange quirk where checkbox unit tests fail
export const getKey = (label: string, isChecked: boolean) =>
  process.env.JEST_WORKER_ID !== undefined ? `${label}-${isChecked}` : label
