import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

interface Region {
  id: number
  name: string
}

const registerAbout = (
  name: string,
  regions: Region[],
  translate: IntlFormatters['formatMessage']
) => ({
  question: {
    fields: [
      {
        label: translate(messages.country),
        name: 'region_id',
        options: regions.map((region) => ({
          label: region.name,
          value: region.id.toString(),
        })),
        type: 'select',
        value: regions[0].id.toString(),
      },
      {
        label: translate(messages.dateOfBirth),
        name: 'date_of_birth',
        type: 'date',
      },
      {
        label: translate(messages.gender),
        options: [
          {
            label: translate(messages.genderMale),
            value: 'male',
          },
          {
            label: translate(messages.genderFemale),
            value: 'female',
          },
        ],
        name: 'gender',
        type: 'pills',
      },
      {
        label: translate(messages.submitButton),
        type: 'submit',
      },
      {
        type: 'login_link',
      },
    ],
    progress: 0.73,
    text: translate(messages.heading, { name }),
    type: inputTypes.FORM,
    undoable: true,
  },
  answerProcessorName: 'registerAboutProcessor',
  answerProcessorOptions: {
    regions,
  },
  conversationContext: {},
})

export default registerAbout
