import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Badge, Box, Text, useColorTheme } from '@babylon/web-platform-ui'

import {
  CardInterface,
  QuestionOptionInterface,
} from '../../../../constants/types'
import { CallToActionTypes } from '../../../../constants/enums'
import { getOptionForPGMAction } from '../../../../util/callToActions'

import messages from './messages'
import styles from './BannerV2.module.scss'

const Banner = ({ card, handleCallToAction }: Props) => {
  const translate = useFormatMessage()
  const { colors } = useColorTheme()
  const { action, id, text } = card

  const handleClick = () => {
    if (action) {
      handleCallToAction(getOptionForPGMAction(action, translate))
    } else {
      handleCallToAction({
        action: { type: CallToActionTypes.Default },
        label: '',
        value: id,
      })
    }
  }

  return (
    <button
      className={styles.banner}
      data-testid="symptom-selector-banner"
      onClick={handleClick}
      type="button"
    >
      <Box bgColor={colors.background.bannerGrey}>
        <Badge>{translate(messages.badge)}</Badge>
        <div className={styles.content}>
          {text
            .split(/\n+/)
            .filter(Boolean)
            .map((para) => (
              <Text key={para}>{para}</Text>
            ))}
        </div>
      </Box>
    </button>
  )
}

interface Props {
  card: CardInterface
  handleCallToAction: (option: QuestionOptionInterface) => void
}

export default Banner
