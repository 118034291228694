import { trackEvent } from './utils'

const CATEGORY = 'chatbot'

const trackChatbotExited = (conversationId?: string) => {
  trackEvent({
    action: 'clicked exit chat',
    category: CATEGORY,
    dimension20: conversationId,
  })
}

const trackConversationGoBack = (conversationId?: string) => {
  trackEvent({
    action: 'clicked back arrow',
    category: CATEGORY,
    dimension20: conversationId,
  })
}

const trackConversationStarted = (conversationId: string) => {
  trackEvent({
    action: 'started chat',
    category: CATEGORY,
    dimension20: conversationId,
  })
}

const trackExplanationDisplayed = (conversationId?: string) => {
  trackEvent({
    action: 'clicked see explanation',
    category: CATEGORY,
    dimension20: conversationId,
  })
}

const trackFeedbackDisplayed = (conversationId: string) => {
  trackEvent({
    action: 'clicked leave feedback',
    category: CATEGORY,
    dimension20: conversationId,
  })
}

const trackLeafletOpened = () => {
  trackEvent({
    action: 'clicked view results',
    category: CATEGORY,
    label: 'PGM/Leaflet',
  })
}

const trackCompletedChat = (conversationId: string) => {
  trackEvent({
    action: 'completed chat',
    category: CATEGORY,
    label: 'PGM',
    dimension20: conversationId,
  })
}

const trackRatingDisplayed = () => {
  trackEvent({
    action: 'rating',
    category: CATEGORY,
  })
}

const trackRatingSubmitted = (rating: number) => {
  trackEvent({
    action: 'rating submitted',
    category: CATEGORY,
    label: rating.toString(),
    value: rating,
  })
}

const trackUserLoggedIn = () => {
  trackEvent({
    action: 'logged in',
    category: CATEGORY,
    label: 'Chatbot',
  })
}

const trackUserRegistered = () => {
  trackEvent({
    action: 'completed signup',
    category: CATEGORY,
    label: 'Chatbot',
  })
}

const trackWelcomeScreenStarted = () => {
  trackEvent({
    action: 'started chat',
    category: CATEGORY,
    label: 'UserInput',
  })
}

const trackEventWithConversationId = (
  action: string,
  conversationId: string,
  label?: string
) =>
  trackEvent({
    action,
    category: CATEGORY,
    dimension20: conversationId,
    label,
  })

export {
  trackChatbotExited,
  trackConversationGoBack,
  trackConversationStarted,
  trackExplanationDisplayed,
  trackFeedbackDisplayed,
  trackLeafletOpened,
  trackCompletedChat,
  trackRatingDisplayed,
  trackRatingSubmitted,
  trackUserLoggedIn,
  trackUserRegistered,
  trackWelcomeScreenStarted,
  trackEventWithConversationId,
}
