import { startNewConversationInterfaceProps } from '../../constants/types'
import { enableCustomAuth } from '../../util/auth'
import { startNewConversationRest } from '../requests/startNewConversation'
import createInitialQuestion from '../../util/createInitialQuestion'

const startNewConversation = async ({
  auth0Client,
  memberUuid,
  options,
  userId,
}: startNewConversationInterfaceProps) => {
  enableCustomAuth(auth0Client)

  const newConversation = await startNewConversationRest({
    memberUuid,
    options,
  })

  return createInitialQuestion({
    memberUuid,
    newConversation,
    userId,
  })
}

export default startNewConversation
