import { logException } from '@babylon/sentry'
import { IntlFormatters } from 'react-intl'
import { QueryFunctionContext } from 'react-query'
import {
  getPrivacyNoticeVersionByName,
  getPrivacySettings,
  setPolicyAcceptance,
} from '../../api'
import { NoticeVersionName } from '../../api/requests/getPrivacyNoticeVersionByName'
import { PrivacySetting } from '../../api/requests/getPrivacySettings'
import { inputTypes } from '../../constants'
import { AppviewActionTypes, PGMActionTypes } from '../../constants/enums'
import {
  ChatscriptMessageInputCard,
  GoToQuestionInterface,
  QuestionInterface,
} from '../../constants/types'
import messages from './messages'

interface SetPrivacySettingDataParams {
  noticeVersionId: string
  accepted: boolean
}

interface PrivacySettingInput {
  label: string
  name: string
  linkText: string
  shortText: string
  modalText: string
  checked: boolean
  type: string
}

/**
 * A helper function to determine whether the question object contains specific additional input card metadata for Privacy Settings, and returns the card metadata
 */
const getSettingsCard = (question: QuestionInterface) => {
  const additionalInputs = question
    ?.additionalInputs?.[0] as ChatscriptMessageInputCard

  if (!additionalInputs) {
    return null
  }

  const { type, data } = additionalInputs.params.action || {}

  if (
    type === PGMActionTypes.webview &&
    data?.url === AppviewActionTypes.PrivacyPolicyScreen
  ) {
    return additionalInputs
  }

  return null
}

const getPrivacySettingInputs = (
  privacySettings: PrivacySetting[] | undefined
): PrivacySettingInput[] => {
  // TODO - need to confirm correct privacy action to pass to BE
  if (!privacySettings) {
    return []
  }

  const formattedPrivacySettingsInputs = privacySettings.map((setting) => ({
    label: setting.noticeVersion.title,
    name: setting.noticeVersion.noticeVersionId,
    linkText: setting.noticeVersion.linkText,
    shortText: setting.noticeVersion.settingsText,
    modalText: setting.noticeVersion.longText,
    checked: setting.accepted,
    type: 'checkbox_setting',
  }))

  return formattedPrivacySettingsInputs
}

const getPrivacySettingsInitialQuestionData = (
  translate: IntlFormatters['formatMessage']
): GoToQuestionInterface => ({
  question: {
    type: inputTypes.PRIVACY_SETTINGS,
    undoable: true,
    text: translate(messages.heading),
  },
})

export interface PrivacySettingsDataInterface {
  settings: PrivacySettingInput[]
  privacyPolicyText: string
}

const getPrivacySettingsData = async (
  context: QueryFunctionContext
): Promise<PrivacySettingsDataInterface> => {
  const translate = context.queryKey[0] as IntlFormatters['formatMessage']

  try {
    const [privacySettings, privacyPolicy] = await Promise.all([
      getPrivacySettings(),
      getPrivacyNoticeVersionByName(NoticeVersionName.PrivacyPolicy),
    ])

    const privacySettingsItems = getPrivacySettingInputs(privacySettings)

    return {
      settings: privacySettingsItems,
      privacyPolicyText: privacyPolicy?.longText || '',
    }
  } catch (error) {
    logException(error)

    const message = translate(messages.privacySettingsNetworkErrorMessage)
    throw new Error(message)
  }
}

const setPrivacySettingData = async ({
  noticeVersionId,
  accepted,
}: SetPrivacySettingDataParams): Promise<boolean> =>
  setPolicyAcceptance(noticeVersionId, accepted)
    .then(() => true)
    .catch((error) => {
      logException(error)

      return false
    })

export {
  getPrivacySettingsData,
  setPrivacySettingData,
  getPrivacySettingsInitialQuestionData,
  getSettingsCard,
}
