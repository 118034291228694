import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { ChipList, ChipOptions, Heading } from '@babylon/web-platform-ui'

import messages from './messages'
import usePillMessages from './usePillMessages'
import styles from './CommonSearchesV2.module.scss'

const CommonSearches = ({ onClick }: CommonSearchesProps) => {
  const translate = useFormatMessage()
  const pillMessages = usePillMessages()

  return (
    <section className={styles.commonSearches}>
      <Heading size="3xs">{translate(messages.header)}</Heading>
      <div className={styles.pillContainer}>
        <ChipList
          selectType="one"
          chips={pillMessages.map(
            (message) =>
              ({
                label: translate(message),
                value: translate(message),
              } as ChipOptions)
          )}
          onChipSelect={(options) => onClick(options[0] as string)}
        />
      </div>
    </section>
  )
}

interface CommonSearchesProps {
  onClick: (option: string) => void
}

export default CommonSearches
