import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import {
  Box,
  Heading,
  Text,
  Icon,
  useColorTheme,
} from '@babylon/web-platform-ui'

import getIconForDecision from '../../utils/getIconForDecision'
import messages from './messages'
import { ConditionSummaryProps } from './ConditionSummaryProps'

import styles from './ConditionSummaryV2.module.scss'

const ConditionSummaryV2 = ({
  condition,
  onSelectDiagnosis,
  triageEnabled,
  softTriage,
  distressing,
}: ConditionSummaryProps) => {
  const { colors } = useColorTheme()
  const translate = useFormatMessage()
  let overviewText = null

  if (!triageEnabled) {
    overviewText = `${condition.probability_text} (${condition.short_overview})`
  } else {
    overviewText = condition.short_overview
  }

  const decisionIcon = softTriage
    ? getIconForDecision(condition.triage.icon)
    : null

  return (
    <button
      className={styles.conditionSummary}
      onClick={() => onSelectDiagnosis(condition.id)}
      type="button"
    >
      <Box
        className={styles.inner}
        backgroundColor={colors.background.backgroundPrimary}
      >
        <div className={styles.left}>
          <div className={styles.heading}>
            <Heading size="xs">{condition.layman_name}</Heading>
          </div>

          {distressing && !triageEnabled && (
            <Text>{translate(messages.conditionSummaryDistressing)}</Text>
          )}

          <Text>{overviewText}</Text>

          {!triageEnabled && (
            <Text>{condition.common_treatment.description}</Text>
          )}
        </div>

        <Icon icon="Right" color={colors.functional.graphic} />
      </Box>

      {softTriage && (
        <Box
          className={styles.bottom}
          backgroundColor={colors.background.bannerPurple}
        >
          {decisionIcon}
          <Text>{condition.triage.title}</Text>
        </Box>
      )}
    </button>
  )
}

export default ConditionSummaryV2
