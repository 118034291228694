import React from 'react'
import { Icon, useColorTheme } from '@babylon/web-platform-ui'

import styles from './Thumbnail.module.scss'

const Thumbnail = ({ src, index, onRemove }: Props) => {
  const { colors } = useColorTheme()

  return (
    <div className={styles.thumbnails__thumbnail}>
      <button
        className={styles.thumbnails__close}
        onClick={() => onRemove(index)}
        type="button"
      >
        <Icon
          aria-label="Remove"
          color={colors.functional.graphic}
          fontSize={20}
          icon="Delete"
        />
      </button>
      <div className={styles.thumbnails__thumbnail__img}>
        <img
          src={src}
          alt={`Attachment ${index + 1}`}
          data-testid="thumbnail-img"
        />
      </div>
    </div>
  )
}

interface Props {
  src: string
  index: number
  onRemove: (index: number) => void
}

export default Thumbnail
