import { microservicesClient } from '../client'
import { ChatscriptConversation } from '../../constants/types'
import { getBaseChatscriptUrl } from '../util/baseUrls'

const getConversation = ({ conversationId, memberUuid }: Props) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .get<Response>(`${baseUrl}/conversations/${conversationId}`)
    .then((response) => response.data)
}

interface Props {
  conversationId: string
  memberUuid?: string
}

type Response = ChatscriptConversation

export default getConversation
