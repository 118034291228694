let loggedIn = false

export const login = async () => {
  loggedIn = true
}

export const logout = () => {
  loggedIn = false
}

export const tokenIsValid = () => loggedIn

export const activeUser = () => null

export const tokenCanBeRefreshed = () => loggedIn

export const tokenRefreshDue = () => false

export const refreshToken = () => {}

export const requestHeaders = async () => {
  const token = getToken()

  return token ? { Authorization: `Bearer ${token}` } : {}
}

export const getToken = () => window?.cyrus?.token

export const insideCyrus = () => !!getToken()
