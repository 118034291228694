import React from 'react'
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux'

import store from './store'

const withRedux = <P extends object>(
  Component: (props: P) => JSX.Element | null
) => (props: P) => (
  <ReduxProvider store={store} context={ReactReduxContext}>
    <Component {...props} />
  </ReduxProvider>
)

export default withRedux
