import React from 'react'
import { useFormatMessage } from '@babylon/intl'

import { IconButton } from '@babylon/web-platform-ui'

import messages from './messages'
import heartLogo from '../../assets/heart-logo.svg'

import styles from './Header.module.scss'

const Header = ({
  loading,
  LogoComponent,
  logoUrl,
  onBackClick,
  onExitClick,
  showBackButton,
}: Props) => {
  const translate = useFormatMessage()

  let centreImage

  if (LogoComponent) {
    centreImage = <LogoComponent />
  } else if (logoUrl) {
    centreImage = <img src={logoUrl} alt="" />
  } else {
    centreImage = <img data-testid="babylon-heart" src={heartLogo} alt="" />
  }

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.leftAccessoryV2}>
          {showBackButton && (
            <IconButton
              icon="Back"
              variant="secondary"
              aria-label={translate(messages.back)}
              onClick={onBackClick}
              isDisabled={loading || !showBackButton}
              data-testid="chatbot-back-button"
            />
          )}
        </div>
        <div className={styles.centreAccessory}>{centreImage}</div>
        <div className={styles.rightAccessoryV2}>
          <IconButton
            icon="Close"
            variant="secondary"
            data-testid="chatbot-exit-button"
            aria-label={translate(messages.exit)}
            onClick={onExitClick}
          />
        </div>
      </div>
    </div>
  )
}

interface Props {
  loading: boolean
  LogoComponent?: React.FC
  logoUrl?: string
  onBackClick: () => void
  onExitClick: () => void
  showBackButton: boolean
}

export default Header
