import React, { useEffect, useRef } from 'react'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { ConditionRow, LetterRow } from './ConditionListRows'
import styleValueToNumber from '../../../util/styleValueToNumber'
import { ConditionInterface } from '../../../constants/types'

import styles from './styles.module.scss'

const MARGIN_BOTTOM = styleValueToNumber(styles.marginBottom)

const getRow = (
  index: number,
  style: any,
  listData: ConditionInterface[],
  inputValue: string
) => {
  const { name, url } = listData[index]
  const regexp = new RegExp(inputValue, 'gi')

  if (!url) {
    return <LetterRow name={name} style={style} />
  }

  // Check if this condition row is the last in this group e.g A, B, C etc
  const nextIndex = index + 1
  const lastInGroup = !(listData[nextIndex] && listData[nextIndex].url)
  const shouldHighlight = !!inputValue

  return (
    <ConditionRow
      name={name}
      pattern={regexp}
      shouldHighlight={shouldHighlight}
      style={style}
      url={url}
      lastInGroup={lastInGroup}
    />
  )
}

interface ConditionsListProps {
  listData: ConditionInterface[]
  inputValue: string
}

const ConditionsList = ({ listData, inputValue }: ConditionsListProps) => {
  const listRef = useRef<FixedSizeList>(null)

  useEffect(() => {
    if (!listRef.current) {
      return
    }

    listRef.current.scrollToItem(0)
  }, [inputValue])

  return (
    <div className={styles.conditionsList} data-testid="condition-list">
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            height={height + MARGIN_BOTTOM}
            itemCount={listData.length}
            itemSize={60}
            ref={listRef}
            width={width}
          >
            {({ index, style }) => getRow(index, style, listData, inputValue)}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  )
}

export default ConditionsList
export { getRow }
