import { microservicesClient } from '../../client'
import { SuggestionInterface } from '../../../constants/types'
import { getBaseChatscriptUrl } from '../../util/baseUrls'

const getSymptomSuggestions = ({
  conversationId,
  query,
}: Props): Promise<SuggestionInterface[]> => {
  const baseUrl = getBaseChatscriptUrl()

  return microservicesClient
    .post<Response[]>(`${baseUrl}/conversations/${conversationId}/suggest`, {
      query,
    })
    .then((response) => response.data.map(({ value }) => value))
}

type Props = {
  conversationId: string
  query: string
}

type Response = {
  value: SuggestionInterface
}

export default getSymptomSuggestions
