import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'question.registerAccount.heading',
    defaultMessage:
      'Finally. We just need an email address and password to keep your data safe.',
  },
  email: {
    id: 'question.registerAccount.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'question.registerAccount.password',
    defaultMessage: 'Password',
  },
  passwordTip: {
    id: 'question.registerAccount.passwordTip',
    defaultMessage:
      'Use at least 8 characters including a number, an uppercase and a lowercase letter.',
  },
  submitButton: {
    id: 'question.registerAccount.submitButton',
    defaultMessage: 'Create account',
  },
})
