import { ChatscriptMessageInputType } from '../../../constants/enums'
import { ChatscriptReplyMessage } from '../../../constants/types'
import { ImageFile } from '../../../components/inputTypes/TextFileInput/types'

// We need the image as base64, but without the data:base64/ metadata
// that readAsDataURL puts on the beginning.
const getBase64 = (file: ImageFile) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const encoded = (reader.result as string).replace(
        /^data:(.*;base64,)?/,
        ''
      )
      resolve(encoded)
    }
    reader.onerror = (error) => reject(error)
  })

const formatFiles = async (files: ImageFile[]) =>
  Promise.all(
    files.map(async (file) => ({
      media_type: file.type,
      base64: await getBase64(file),
    }))
  )

type MessageMapping = {
  [key in ChatscriptMessageInputType | string]: (
    userInput: any
  ) => ChatscriptReplyMessage | Promise<ChatscriptReplyMessage>
}

const messageMapping: MessageMapping = {
  [ChatscriptMessageInputType.Card]: (userInput: string) => ({
    type: ChatscriptMessageInputType.Card,
    value: {
      id: userInput,
    },
  }),
  [ChatscriptMessageInputType.Choices]: (userInput: string | string[]) => ({
    type: ChatscriptMessageInputType.Choices,
    value: {
      choices: Array.isArray(userInput)
        ? userInput.map((id) => ({ id }))
        : [{ id: userInput }],
    },
  }),
  [ChatscriptMessageInputType.Date]: (userInput: string) => ({
    type: ChatscriptMessageInputType.Date,
    value: {
      date: userInput,
    },
  }),
  [ChatscriptMessageInputType.Phone]: (userInput: string) => ({
    type: ChatscriptMessageInputType.Phone,
    value: {
      phone: userInput,
    },
  }),
  [ChatscriptMessageInputType.Symptom]: (userInput: {
    id: string
    text: string
  }) => ({
    type: ChatscriptMessageInputType.Symptom,
    value: {
      id: userInput.id,
      text: userInput.text,
    },
  }),
  [ChatscriptMessageInputType.Text]: (
    userInput: string | { name: string }
  ) => ({
    type: ChatscriptMessageInputType.Text,
    value: {
      text: typeof userInput === 'string' ? userInput : userInput.name,
    },
  }),
  [ChatscriptMessageInputType.TextAndFiles]: async (userInput: FormData) => ({
    type: ChatscriptMessageInputType.TextAndFiles,
    value: {
      files: await formatFiles(userInput.getAll('files') as ImageFile[]),
      text: userInput.get('draft') as string,
    },
  }),
}

const createChatscriptReplyMessageRest = async (
  type: ChatscriptMessageInputType | string,
  userInput: any
) => messageMapping[type]?.(userInput) || { type, value: userInput }

export default createChatscriptReplyMessageRest
