import React, { useState } from 'react'
import classNames from 'classnames'
import { useFormatMessage } from '@babylon/intl'
import { Button } from '@babylon/web-platform-ui'
// TODO: [TSC-521]: replace with phone input from web-platform-ui
import { PhoneInput } from '@babylon/ui'

import messages from './messages'

import styles from './StandalonePhoneInput.module.scss'
import ArrowRight from '../../ArrowRight'

const StandalonePhoneInput = ({ loading, onSubmit }: Props) => {
  const translate = useFormatMessage()
  const [selectedCountryCode, setCountryCode] = useState('+44')
  const [selectedPhoneNumber, setPhoneNumber] = useState('')

  const onChange = ({
    countryCode,
    value,
  }: {
    countryCode: string
    value: string
  }) => {
    setCountryCode(countryCode)
    setPhoneNumber(value)
  }

  const onClick = () => {
    onSubmit(`${selectedCountryCode}${selectedPhoneNumber}`)
  }

  return (
    <div className={classNames(styles.phoneInputWrapper, styles.v2)}>
      <PhoneInput
        className=""
        countryCode={selectedCountryCode}
        errors={[]}
        label=""
        onChange={onChange}
        success=""
        tip=""
        value={selectedPhoneNumber}
        selectDisplayClassName={styles.selectDisplay}
      />
      <div className={styles.submitButton}>
        <Button
          isLoading={loading}
          loadingText=""
          onClick={onClick}
          rightIcon={<ArrowRight />}
        >
          {translate(messages.submitLabel)}
        </Button>
      </div>
    </div>
  )
}

interface Props {
  loading: boolean
  onSubmit: (phoneNumber: string) => void
}

export default StandalonePhoneInput
