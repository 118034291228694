import React from 'react'
import classNames from 'classnames'

import { Button as WebButton } from '@babylon/web-platform-ui'
import styles from './SubmitButton.module.scss'
import { FieldProps } from '../types'

const SubmitButtonV2 = (props: FieldProps) => {
  const {
    label,
    loading,
    isResponsive,
    type,
    loadingLabel,
    'data-testid': testId,
  } = props

  return (
    <div className={classNames({ [styles.responsive]: isResponsive })}>
      <WebButton
        data-testid={testId}
        isFullWidth
        isLoading={loading}
        loadingText={loadingLabel}
        type={type}
      >
        {label}
      </WebButton>
    </div>
  )
}

export default SubmitButtonV2
