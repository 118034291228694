import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'question.forgottenPassword.heading',
    defaultMessage:
      'Enter your email address below and you’ll receive a link to reset your password',
  },
  email: {
    id: 'question.forgottenPassword.email',
    defaultMessage: 'Email',
  },
  submitButton: {
    id: 'question.forgottenPassword.submitButton',
    defaultMessage: 'Send a Link',
  },
})
