import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormatMessage } from '@babylon/intl'
import { Link, Text } from '@babylon/web-platform-ui'

import { AppviewActionTypes, PGMActionTypes } from '../../../constants/enums'
import { getOptionForPGMAction } from '../../../util/callToActions'
import {
  PGMActionInterface,
  QuestionOptionInterface,
} from '../../../constants/types'
import { useProductConfig } from '../../../api'

import messages from './messages'
import styles from './styles.module.scss'

interface NoResultsProps {
  handleCallToAction: (option: QuestionOptionInterface) => void
  isShown: boolean
}

const NoResults = ({ handleCallToAction, isShown }: NoResultsProps) => {
  const translate = useFormatMessage()
  const { loadingQuery, data, error } = useProductConfig()

  let appointmentsBookingEnabled = false

  if (!loadingQuery && !error) {
    appointmentsBookingEnabled = !!data?.appointmentBookingEnabled
  }

  const bookAppointment = () => {
    const action: PGMActionInterface = {
      label: '',
      type: PGMActionTypes.appview,
      data: { view: AppviewActionTypes.BookConsultation },
    }
    handleCallToAction(getOptionForPGMAction(action, translate))
  }

  if (!isShown) {
    return null
  }

  return (
    <div className={styles.noResults}>
      <Text>{translate(messages.noResultsMessage)}</Text>
      {appointmentsBookingEnabled && (
        <Text>
          <FormattedMessage
            id={messages.noResultsCTAMessage.id}
            defaultMessage={messages.noResultsCTAMessage.defaultMessage}
            values={{
              link: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link as="button" onClick={bookAppointment} type="button">
                  {translate(messages.noResultsCTALink)}
                </Link>
              ),
            }}
          />
        </Text>
      )}
    </div>
  )
}

export default NoResults
