import React, { useEffect, useRef } from 'react'

import Header from '../Header'
import ProgressBar from '../ProgressBar'

import styles from './Layout.module.scss'

const Layout = ({
  accessoryContent,
  children,
  loading,
  LogoComponent,
  logoUrl,
  onBackClick,
  onExitClick,
  progress,
  showBackButton,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null)
  // While an answer to a question is posted to the backend, loading becomes
  // true. When this returns, loading becomes false and we want the top of the
  // next question to be scrolled into view.
  useEffect(() => {
    if (contentRef.current && !loading) {
      contentRef.current.scrollIntoView()
    }
  }, [loading])

  return (
    <div className={styles.root}>
      <Header
        loading={loading}
        LogoComponent={LogoComponent}
        logoUrl={logoUrl}
        onBackClick={onBackClick}
        onExitClick={onExitClick}
        showBackButton={showBackButton}
      />
      <div className={styles.content}>
        <div className={styles.contentMain} ref={contentRef}>
          <ProgressBar progress={progress} />
          {children}
        </div>
        {accessoryContent && (
          <div className={styles.contentAccessory}>{accessoryContent}</div>
        )}
      </div>
    </div>
  )
}

interface Props {
  accessoryContent?: React.ReactNode
  children: React.ReactNode
  loading: boolean
  LogoComponent?: React.FC
  logoUrl?: string
  onBackClick: () => void
  onExitClick: () => void
  progress?: number
  showBackButton: boolean
}

export default Layout
