import { reportUserFeedback } from '../api'
import addDataToFields from '../util/addDataToFields'

const validate = (fields) => {
  const errors = {}

  if (!fields.issue) {
    errors.issue = 'This field is required'
  }

  return errors
}

const userFeedbackProcessor = () => async (conversation, fields) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const { answeredQuestions } = conversation
  const feedbackForm = conversation.nextQuestion
  const errors = validate(fields)

  if (Object.keys(errors).length) {
    const nextQuestion = addDataToFields(feedbackForm, fields, errors)

    return {
      nextQuestion,
      answeredQuestions,
    }
  }

  const previousQuestion = answeredQuestions.slice(-1)[0].question
  const {
    conversationId,
    elementId,
    memberUuid,
  } = previousQuestion.conversationContext
  const { comment, issue } = fields

  reportUserFeedback({
    comment,
    conversationId,
    elementId,
    issue,
    memberUuid,
  })

  return {
    nextQuestion: previousQuestion,
    answeredQuestions,
  }
}

export default userFeedbackProcessor
