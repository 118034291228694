import React from 'react'
import { CSSTransition } from 'react-transition-group'

import styleValueToNumber from '../../../../util/styleValueToNumber'

import styles from './TransitionWrapper.module.scss'

const TRANSITION_DURATION = styleValueToNumber(styles.transitionDuration)

const TransitionWrapper = ({
  children,
  showChildren,
}: TransitionWrapperProps) => (
  <CSSTransition
    in={showChildren}
    timeout={TRANSITION_DURATION}
    classNames={styles.transitionWrapper}
    unmountOnExit
  >
    <div>{children}</div>
  </CSSTransition>
)

interface TransitionWrapperProps {
  children: React.ReactNode
  showChildren: boolean
}

export default TransitionWrapper
