import { IntlFormatters } from 'react-intl'

import { inputTypes } from '../../constants'
import messages from './messages'

const welcome = (translate: IntlFormatters['formatMessage']) => ({
  question: {
    fields: [
      {
        name: 'action',
        type: 'welcome_screen',
      },
    ],
    text: translate(messages.greeting),
    type: inputTypes.FORM,
  },
  answerProcessorName: 'welcomeProcessor',
  answerProcessorOptions: {},
})

export default welcome
