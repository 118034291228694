import { defineMessages } from 'react-intl'

export default defineMessages({
  question: {
    id: 'searchConditions.question',
    defaultMessage: 'Got an idea of what’s wrong?',
  },
  callToAction: {
    id: 'searchConditions.callToAction',
    defaultMessage: 'Find information on conditions',
  },
})
