import React from 'react'
import { Link, useDisclosure } from '@babylon/web-platform-ui'

import { LeafletInterface } from '../../constants/types'
import PrivacyNoticeModal from '../PrivacyNoticeModal'
import { trackLeafletOpened } from '../../util/tracking'

import styles from './LeafletLinkV2.module.scss'

const LeafletLink = ({ leaflet }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { longText, title, url } = leaflet

  const onClick = () => {
    trackLeafletOpened()

    if (longText) {
      onOpen()
    } else if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <section className={styles.container}>
      <PrivacyNoticeModal
        isOpen={isOpen}
        longText={longText!}
        onClose={onClose}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link as="button" onClick={onClick} type="button">
        {title}
      </Link>
    </section>
  )
}

interface Props {
  leaflet: LeafletInterface
}

export default LeafletLink
