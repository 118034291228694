import { inputTypes } from '../../constants'
import { PGMReportInterface } from '../../constants/types'

const pgmDiagnosis = (
  pgmReport: PGMReportInterface,
  pgmDiagnosisId: string
) => ({
  question: {
    pgmDiagnosisId,
    pgmReport,
    text: '',
    type: inputTypes.NOOP,
    undoable: true,
  },
})

export default pgmDiagnosis
