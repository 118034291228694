import React from 'react'

import ChatbotContainer from '../ChatbotContainer'
import withIntl from './IntlWrapper'
import withReactQuery from './ReactQueryWrapper'
import withRedux from './ReduxWrapper'
import withTheme from './ThemeWrapper'

type OptionalWrapperType = 'redux' | 'theme'
type RequiredWrapperType = 'intl' | 'reactQuery'
type WrapperType = OptionalWrapperType | RequiredWrapperType

// Applied in order, with the first being the inside component
const wrapperMapping = [
  { id: 'reactQuery', wrapper: withReactQuery },
  { id: 'intl', wrapper: withIntl },
  { id: 'redux', wrapper: withRedux },
  { id: 'theme', wrapper: withTheme },
]

const ChatbotWrapper = ({ wrappers, ...props }: Props) => {
  const combinedWrappers = {
    ...wrappers,
    intl: true,
    reactQuery: true,
  }

  const Component = wrapperMapping.reduce(
    (accumulatingComponent, { id, wrapper }) => {
      if (!combinedWrappers[id as WrapperType]) {
        return accumulatingComponent
      }

      return wrapper(accumulatingComponent)
    },
    ChatbotContainer
  )

  return <Component {...props} />
}

type Props = React.ComponentProps<typeof ChatbotContainer> & {
  wrappers?: { [key in OptionalWrapperType]?: boolean }
}

export default ChatbotWrapper
