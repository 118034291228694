import { runWithAdal } from 'react-adal'
import { authContext } from './auth'

const DO_NOT_LOGIN = true

runWithAdal(
  authContext,
  () => {
    require('./indexApp.jsx')
  },
  DO_NOT_LOGIN
)
