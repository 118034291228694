import { formatPolicyAsQuestion } from '../util/getPrivacyScreens'
import welcomeQuestion from '../questions/welcome'
import authAccessor from '../util/auth'

const gdprProcessor = ({
  setPolicyAcceptance = require('../api').setPolicyAcceptance,
  processors = require('./index'),
}) => async (conversation, answer, translate) => {
  const auth = authAccessor()
  const opts = conversation.nextQuestion.answerProcessorOptions
  const processorToUse = opts.originalOpts
    ? opts.originalOpts.processor
    : opts.processor

  // eslint-disable-next-line import/namespace
  const processor = processors[processorToUse]({})
  const policyId = opts.policy.noticeVersionId

  if (answer === 'GDPR_ACCEPT') {
    await setPolicyAcceptance(policyId, true)
  } else if (!opts.isLastChance) {
    const formattedQuestion = formatPolicyAsQuestion(opts.policy)

    return {
      nextQuestion: {
        ...formattedQuestion,
        question: {
          ...formattedQuestion.question,
          text: `${opts.policy.errorTitle}\n${opts.policy.errorText}`,
        },
        answerProcessorOptions: {
          ...formattedQuestion.answerProcessorOptions,
          isLastChance: true,
          processor: 'gdprProcessor',
          question: formattedQuestion,
          originalOpts: opts,
        },
      },
      answeredQuestions: [
        ...conversation.answeredQuestions,
        { question: formattedQuestion, answer: false },
      ],
    }
  } else {
    await setPolicyAcceptance(policyId, false)
    await auth.logout()

    return {
      nextQuestion: welcomeQuestion(translate),
      answeredQuestions: [...conversation.answeredQuestions],
    }
  }

  const finalOptions = opts.originalOpts || opts

  const newConversation = {
    nextQuestion: {
      ...finalOptions.question,
      conversationContext: {
        ...finalOptions.question.conversationContext,
        fromPrivacyFlow: true,
      },
    },
    answeredQuestions: [
      ...conversation.answeredQuestions,
      { question: conversation.nextQuestion, answer },
    ],
  }

  return processor(newConversation, finalOptions.answer)
}

export default gdprProcessor
