import loginQuestion from '../questions/login'
import addDataToFields from '../util/addDataToFields'

const validate = (fields) => {
  const errors = {}

  if (!fields.email) {
    errors.email = 'An email address is required'
  }

  return errors
}

const passwordResetProcessor = ({
  resetPassword = require('../api').resetPassword,
}) => (conversation, fields, translate) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const resetPasswordForm = conversation.nextQuestion
  const errors = validate(fields)
  let nextQuestion

  if (Object.keys(errors).length) {
    nextQuestion = addDataToFields(resetPasswordForm, fields, errors)

    return Promise.resolve({
      nextQuestion,
      answeredQuestions: conversation.answeredQuestions,
    })
  }

  const newQuestion = loginQuestion(translate)

  return resetPassword(fields.email)
    .then(() => ({
      nextQuestion: {
        ...newQuestion,
        question: {
          ...newQuestion.question,
          text: 'Thank you.\nA password reset link has been sent.',
        },
      },
      answeredQuestions: [
        ...conversation.answeredQuestions,
        {
          question: addDataToFields(resetPasswordForm, fields, errors),
          answer: fields,
        },
      ],
    }))
    .catch((err) => {
      let result

      if (err.response.status === 422) {
        const questionWithValues = addDataToFields(resetPasswordForm, fields, {
          email: err.response.data.message,
        })

        result = {
          nextQuestion: questionWithValues,
          answeredQuestions: [...conversation.answeredQuestions],
        }
      }

      return result
    })
}

export default passwordResetProcessor
