import { defineMessages } from 'react-intl'

export default defineMessages({
  dayLabel: {
    id: 'DateInput.dayLabel',
    defaultMessage: 'Day',
  },
  monthLabel: {
    id: 'DateInput.monthLabel',
    defaultMessage: 'Month',
  },
  yearLabel: {
    id: 'DateInput.yearLabel',
    defaultMessage: 'Year',
  },
})
