import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import createReducers, { getReducers, initialState } from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose

const enhancers = composeEnhancers(applyMiddleware(thunk))

const reducers = createReducers()
const store = createStore(reducers, initialState, enhancers)

// Create an inject reducer function
// implemented based on: https://redux.js.org/recipes/code-splitting#defining-an-injectreducer-function
store.injectReducer = (key, asyncReducer) => {
  const currentReducers = getReducers()

  if (currentReducers[key]) {
    throw new Error(`The following key is already taken in the store: ${key}!`)
  }

  store.replaceReducer(
    combineReducers({
      ...currentReducers,
      [key]: asyncReducer,
    })
  )
}

export default store
