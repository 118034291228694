import React, { ReactChild } from 'react'
import { Link as WebLink, IconProps } from '@babylon/web-platform-ui'

const fake = {} as IconProps
type IconType = typeof fake.icon

const LinkWrapperV2 = ({
  testId,
  href,
  variant,
  leftElement,
  leftIcon,
  children,
}: Props) => {
  const onClick = () => window.open(href, '_blank', 'noopener,noreferrer')

  return (
    <WebLink
      as="button"
      data-testid={testId}
      onClick={onClick}
      role="link"
      type="button"
      variant={variant}
      leftIcon={leftIcon}
      leftElement={leftElement}
    >
      {children}
    </WebLink>
  )
}

export interface Props {
  href: string
  children: ReactChild | ReactChild[]
  testId?: string
  leftElement?: React.ReactNode
  leftIcon?: IconType
  variant?: 'standalone' | 'inline' | 'destructive'
}

export default LinkWrapperV2
