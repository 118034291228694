import React from 'react'
import classNames from 'classnames'
import { Icon } from '@babylon/web-platform-ui'

import readFileAsync from '../../../util/readFileAsync'
import { ImageFile } from './types'

import styles from './FileInputButton.module.scss'

const FileInputButton = ({ disabled, onFileUpload }: Props) => {
  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: ImageFile[] = event.target.files
      ? (Array.from(event.target.files) as ImageFile[])
      : []

    const previews: string[] = await Promise.all(newFiles.map(readFileAsync))
    previews.forEach((base64, index) => {
      newFiles[index].preview = base64
    })

    onFileUpload(newFiles)
  }

  return (
    <div
      className={classNames(styles.fileInputButton, {
        [styles['fileInputButton--disabled']]: disabled,
      })}
    >
      <input
        onChange={onChange}
        type="file"
        name="files"
        id="files"
        accept="image/jpeg"
        disabled={disabled}
        className={styles.input}
        data-testid="file-upload"
      />
      <label htmlFor="files" className={styles.label}>
        <Icon fontSize={20} icon="Photo" aria-label="Upload images" />
      </label>
    </div>
  )
}

interface Props {
  disabled: boolean
  onFileUpload: (imageFiles: ImageFile[]) => void
}

export default FileInputButton
