import moment from 'moment'

import { getPrivacyNoticeVersions } from '../api'
import registerAccount from '../questions/registerAccount'
import addDataToFields from '../util/addDataToFields'

const validate = (fields, regions) => {
  const errors = {}
  const targetRegion = regions.find(
    (r) => r.id === parseInt(fields.region_id, 10)
  )
  ;['gender', 'region_id'].forEach((f) => {
    if (!fields[f]) {
      errors[f] = 'This field is required'
    }
  })

  if (!moment(fields.date_of_birth, 'YYYY-MM-DD', true).isValid()) {
    errors.date_of_birth = 'Please enter a valid date'
  }

  const parsed = moment(fields.date_of_birth, 'YYYY-MM-DD')

  if (
    !errors.date_of_birth &&
    moment().diff(parsed, 'years') < targetRegion.age_of_consent
  ) {
    errors.date_of_birth = `You must be at least ${targetRegion.age_of_consent} years old to use this service`
  }

  return errors
}

const registerProcessor = () => async (conversation, fields, translate) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const registerForm = conversation.nextQuestion
  const context = registerForm.conversationContext
  const previousFields = registerForm.answerProcessorOptions.fields
  const errors = validate(fields, registerForm.answerProcessorOptions.regions)
  let nextQuestion

  if (Object.keys(errors).length) {
    nextQuestion = addDataToFields(registerForm, fields, errors)

    return Promise.resolve({
      nextQuestion,
      answeredQuestions: conversation.answeredQuestions,
    })
  }

  fields.region_id = parseInt(fields.region_id, 10)

  const notices = await getPrivacyNoticeVersions('signup')
  nextQuestion = await registerAccount(fields, notices.data, translate)

  return {
    nextQuestion: {
      ...nextQuestion,
      answerProcessorOptions: {
        ...nextQuestion.answerProcessorOptions,
        fields: { ...fields, ...previousFields },
      },
      conversationContext: context,
    },
    answeredQuestions: [
      ...conversation.answeredQuestions,
      {
        question: addDataToFields(registerForm, fields, {}),
        answer: fields,
      },
    ],
  }
}

export default registerProcessor
