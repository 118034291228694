import { microservicesClient } from '../../client'
import { ChatscriptConversation } from '../../../constants/types'
import { getBaseChatscriptUrl } from '../../util/baseUrls'

const undoLastChatscriptMessage = ({
  conversationId,
  etag,
  memberUuid,
}: Props) => {
  const baseUrl = getBaseChatscriptUrl(memberUuid)

  return microservicesClient
    .post<Response>(
      `${baseUrl}/conversations/${conversationId}/undo`,
      {},
      {
        headers: {
          'If-Match': etag,
        },
      }
    )
    .then((response) => ({
      ...response.data,
      etag: response.headers.etag as string,
    }))
}

type Props = {
  conversationId: string
  etag: string
  memberUuid?: string
}

type Response = {
  conversation: ChatscriptConversation
}

export default undoLastChatscriptMessage
