import React from 'react'
import { Divider, ProgressIndicator } from '@babylon/web-platform-ui'

import styles from './ProgressBarV2.module.scss'

const ProgressBar = ({ progress }: Props) => {
  if (typeof progress !== 'number') {
    return (
      <div className={styles.container}>
        <Divider data-testid="progress-divider" />
      </div>
    )
  }

  const width = progress * 100

  return (
    <div className={styles.container}>
      <ProgressIndicator min={0} max={100} value={width} />
    </div>
  )
}

interface Props {
  progress?: number
}

export default ProgressBar
