import { startNewConversation } from '../api'
import authAccessor from './auth'

export default async (email, password, options) => {
  const auth = authAccessor()

  await auth.login(email, password)
  const userId = await auth.activeUser()

  return startNewConversation(userId, options)
}
