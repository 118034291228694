import { questionWithError } from '../api'

import formatConversationStep from './formatConversationStep'
import getInProgressConversation from './getInProgressConversation'
import processChatscriptResponse from './processChatscriptResponseRest'
import translationMessages from './messages'

const ETAG_INVALID_ERROR = 412

async function refreshConversation({ conversation, context, translate }) {
  const {
    conversationId,
    memberUuid,
  } = conversation.nextQuestion.conversationContext
  const newConversation = await getInProgressConversation({
    conversationId,
    memberUuid,
  })

  const processed = await processChatscriptResponse(context, newConversation)

  const { nextQuestion } = formatConversationStep(processed, context)

  return {
    nextQuestion: questionWithError(
      nextQuestion,
      translate(translationMessages.handleChatscriptErrorSendingMessage)
    ),
    answeredQuestions: [],
  }
}

export default async function handleChatscriptError({
  context,
  chatscriptError,
  conversation,
  translate,
}) {
  if (
    chatscriptError.response &&
    chatscriptError.response.status == ETAG_INVALID_ERROR
  ) {
    return refreshConversation({
      conversation,
      context,
      translate,
    })
  }

  throw chatscriptError
}
