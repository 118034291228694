import React from 'react'
import classNames from 'classnames'
import { Box, Heading, Icon, useColorTheme } from '@babylon/web-platform-ui'

import LinkWrapper from '../../LinkWrapper'
import sanitize from '../../../util/sanitize'

import styles from './styles.module.scss'

interface ConditionRowProps {
  name: string
  pattern: string | RegExp
  shouldHighlight: boolean
  style: any
  url: string
  lastInGroup?: boolean
}

const ConditionRow = ({
  name,
  pattern,
  shouldHighlight,
  style = {},
  url,
  lastInGroup,
}: ConditionRowProps) => {
  const { colors } = useColorTheme()

  return (
    <Box
      className={classNames(styles.conditionContainer, {
        [styles.letterBottom]: lastInGroup,
      })}
      style={style}
      backgroundColor={colors.background.backgroundPrimary}
      borderBottomColor={colors.functional.divider}
    >
      <LinkWrapper
        className={classNames(styles.condition, {
          [styles.letterBottom]: lastInGroup,
        })}
        href={url}
        testId={`condition-row-${name}`}
      >
        <>
          {shouldHighlight ? (
            <span
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  name.replace(pattern, (str) => `<strong>${str}</strong>`)
                ),
              }}
            />
          ) : (
            <span>{name}</span>
          )}
          <Icon icon="ExternalLink" color={colors.functional.graphic} />
        </>
      </LinkWrapper>
    </Box>
  )
}

interface LetterRowProps {
  name: string
  style: any
}

const LetterRow = ({ name, style = {} }: LetterRowProps) => {
  const { colors } = useColorTheme()

  return (
    <Box
      style={style}
      data-testid={`filter-row-${name}`}
      className={styles.letterTop}
      backgroundColor={colors.background.backgroundPrimary}
    >
      <Heading as="h2" size="md">
        {name}
      </Heading>
    </Box>
  )
}

export { ConditionRow, LetterRow }
