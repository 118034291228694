import React, { useState } from 'react'
import { Checkbox } from '@babylon/web-platform-ui'

import styles from './CheckboxInput.module.scss'

const CheckboxInput = ({ error, label, name, onChange }: Props) => {
  const [checked, setChecked] = useState(false)

  const updateChecked = () => {
    setChecked((prevState) => {
      const isChecked = !prevState
      onChange(isChecked)

      return isChecked
    })
  }

  return (
    <Checkbox
      className={styles.checkbox}
      data-testid={label}
      errorMessage={error}
      isChecked={checked}
      label={label}
      name={name}
      onChange={updateChecked}
    />
  )
}

interface Props {
  error?: string
  label: string
  name: string
  onChange: (e: any) => any
}

export default CheckboxInput
