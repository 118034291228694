import React from 'react'

import {
  RadioGroup as WebRadioGroup,
  Radio as WebRadio,
} from '@babylon/web-platform-ui'
import { FieldProps } from '../types'

const RadioGroupV2 = (props: FieldProps) => {
  const { errors = [], error, label, name, options, value, onChange } = props

  const errorMessage = errors.length > 0 ? errors.join(' | ') : error

  return (
    <WebRadioGroup
      label={label}
      name={name}
      errorMessage={errorMessage}
      value={value}
      onChange={onChange}
    >
      {options?.map((option) => (
        <WebRadio
          key={option.label}
          label={option.label}
          value={option.value}
        />
      ))}
    </WebRadioGroup>
  )
}

export default RadioGroupV2
