import { groupBy } from 'lodash'
import { ConditionByLetter, ConditionInterface } from '../../../constants/types'

export const groupConditionsByLetter = (conditions: ConditionInterface[]) =>
  groupBy(conditions, (condition) => condition.name.toUpperCase().charAt(0))

export const filterConditionsByLetter = (
  conditionsByLetter: ConditionByLetter,
  value: string
) => {
  const filteredConditionsByLetter: ConditionByLetter = {}
  Object.entries(conditionsByLetter).forEach(([letter, conditions]) => {
    const filteredConditions = conditions.filter((condition) =>
      condition.name.toUpperCase().includes(value.toUpperCase())
    )

    if (filteredConditions.length) {
      filteredConditionsByLetter[letter] = filteredConditions
    }
  })

  return filteredConditionsByLetter
}

export const reduceConditionsToList = (
  conditionsByLetter: ConditionByLetter,
  value: string
) => {
  let entries: any[] = []

  if (!value) {
    entries = Object.entries(conditionsByLetter).sort((a, b) =>
      a[0].localeCompare(b[0])
    )
  } else {
    // Order conditions starting with the same letter as the first letter of the
    // value at the top, then all others
    const firstLetter = value[0].toUpperCase()
    const {
      [firstLetter.toUpperCase()]: firstLetterConditions,
      ...otherConditions
    } = conditionsByLetter

    if (firstLetterConditions) {
      entries = [[firstLetter, firstLetterConditions]]
    }

    if (otherConditions) {
      entries = [
        ...entries,
        ...Object.entries(otherConditions).sort((a, b) =>
          a[0].localeCompare(b[0])
        ),
      ]
    }
  }

  return entries.reduce(
    (acc, [letter, conditions]) => [...acc, { name: letter }, ...conditions],
    []
  )
}
