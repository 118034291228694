import React from 'react'
import { Text } from '@babylon/web-platform-ui'

const Disclaimer = () => (
  <Text>
    Please note: this version of the symptom checker is for testing purposes
    only.
  </Text>
)

export default Disclaimer
