import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Button } from '@babylon/web-platform-ui'

import { GoToQuestionInterface } from '../../../../../constants/types'
import loginQuestion from '../../../../../questions/login'
import messages from './messages'
import styles from './LoginLink.module.scss'

const LoginLink = ({ goToQuestion }: Props) => {
  const translate = useFormatMessage()

  const goToLogin = () => {
    goToQuestion(loginQuestion(translate))
  }

  return (
    <div className={styles.container}>
      <Button isFullWidth onClick={goToLogin} variant="secondary">
        {translate(messages.loginLink)}
      </Button>
    </div>
  )
}

interface Props {
  goToQuestion: (newQuestion: GoToQuestionInterface) => void
}

export default LoginLink
