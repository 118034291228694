import React from 'react'
import { Text } from '@babylon/web-platform-ui'

import { PGMActionInterface } from '../../constants/types'

import styles from './CallToActionDisclaimer.module.scss'

const CallToActionDisclaimer = ({ action }: Props) => (
  <div className={styles.disclaimer}>
    <Text>{action.data.disclaimer}</Text>
  </div>
)

interface Props {
  action: PGMActionInterface
}

export default CallToActionDisclaimer
