import { uniqBy } from 'lodash'

const RECENT_PROFILES = 'recentProfiles'

const set = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

const get = (key, defaultValue) => {
  const ls = window.localStorage.getItem(key)

  try {
    return (ls && JSON.parse(ls)) || defaultValue
  } catch (error) {
    return defaultValue
  }
}

const addRecentProfile = (pathname) => {
  const profiles = get(RECENT_PROFILES, [])
  profiles.push({ pathname, lastAccessed: Date.now() })

  set(
    RECENT_PROFILES,
    uniqBy(profiles, (o) => o.pathname)
  )
}

const getRecentProfiles = () => get(RECENT_PROFILES)

export { addRecentProfile, getRecentProfiles }
