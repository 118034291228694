import LinkifyIt, { Match } from 'linkify-it'

// explicitly ignore text not starting with 911 or 999
// ignore number that are longer than 3 digits long
const validateEmergencyPhoneNumber = (matchingText: string) =>
  /^(911|999)(?![0-9])/.test(matchingText)

const linkifyIt = new LinkifyIt()

linkifyIt.add('9', {
  validate(text, pos: number) {
    const matched = text.slice(pos - 1)

    // `2` matches the remaining numbers after the first 9, `0` is a false value
    return validateEmergencyPhoneNumber(matched) ? 2 : 0
  },
  normalize(match: Match) {
    match.url = `tel://${match.url}`
  },
})

const matchDecorator = (matchedText: string): Match[] | null =>
  linkifyIt.match(matchedText)

export { matchDecorator, validateEmergencyPhoneNumber }
