import { get } from 'lodash'

import addDataToFields from '../util/addDataToFields'
import { trackUserRegistered } from '../util/tracking'

import { registerUser, questionWithError } from '../api'
import loginAndStartConversation from '../util/loginAndStartConversation'
import getPrivacyScreens from '../util/getPrivacyScreens'
import displayPrivacyScreen from '../util/displayPrivacyScreen'

const validate = (fields) => {
  const errors = {}
  ;['email', 'password'].forEach((f) => {
    if (!fields[f]) {
      errors[f] = 'This field is required'
    }
  })

  if (!fields.terms_and_conditions_accepted) {
    errors.terms_and_conditions_accepted =
      'You must accept the terms and conditons to use our services.'
  }

  if (!/@/.test(fields.email) && !errors.email) {
    errors.email = "This does't look like a valid email address to us"
  }

  if (
    ([/[0-9]/, /[a-z]/, /[A-Z]/].some((r) => !r.test(fields.password)) ||
      fields.password.length < 8) &&
    !errors.password
  ) {
    errors.password = 'This password does not match the criteria'
  }

  if (fields.password && fields.password.length > 72 && !errors.password) {
    errors.password = 'Password is too long (maximum is 72 characters)'
  }

  return errors
}

const registerProcessor = () => async (conversation, fields) => {
  if (!conversation.nextQuestion) {
    throw new Error('Answer processor invoked on completed conversation')
  }

  const registerForm = conversation.nextQuestion
  const { conversationContext } = registerForm
  const errors = validate(fields)
  let nextQuestion

  if (Object.keys(errors).length) {
    nextQuestion = addDataToFields(registerForm, fields, errors)

    return { nextQuestion, answeredQuestions: conversation.answeredQuestions }
  }

  if (!conversationContext.fromPrivacyFlow) {
    try {
      const options = registerForm.answerProcessorOptions
      const { optionalPolicyIds } = options

      const acceptedOptional = optionalPolicyIds
        ? optionalPolicyIds.filter((policyId) => fields[policyId])
        : []

      await registerUser({
        ...options.fields,
        ...fields,
        accepted_notice_versions: [
          ...(options.policyIds || []),
          ...acceptedOptional,
        ],
      })
    } catch (err) {
      if (!get(err, 'response.data.cause.errors')) {
        return {
          nextQuestion: questionWithError(
            registerForm,
            'There was a problem sending this message. Please try again.'
          ),
          answeredQuestions: conversation.answeredQuestions,
        }
      }

      nextQuestion = addDataToFields(
        registerForm,
        fields,
        err.response.data.cause.errors
      )

      return {
        nextQuestion,
        answeredQuestions: conversation.answeredQuestions,
      }
    }
  }

  const gdprScreens = await getPrivacyScreens({
    email: fields.email,
    password: fields.password,
  })

  if (gdprScreens.length) {
    return displayPrivacyScreen(
      gdprScreens[0],
      'registerProcessor',
      fields,
      registerForm,
      conversation
    )
  }

  const payload = await loginAndStartConversation(fields.email, fields.password)

  trackUserRegistered()

  return {
    nextQuestion: payload,
    answeredQuestions: [
      ...conversation.answeredQuestions,
      { question: registerForm, answer: fields },
    ],
  }
}

export default registerProcessor
