import { defineMessages } from 'react-intl'

export default defineMessages({
  noResultsMessage: {
    id: 'noResults.message',
    defaultMessage:
      'Sorry, I couldn’t find any results for that search term. Please try entering your symptom again.',
  },
  noResultsCTAMessage: {
    id: 'noResults.callToAction.message',
    defaultMessage: 'Alternatively, you can {link} if it’s urgent.',
  },
  noResultsCTALink: {
    id: 'noResults.callToAction.link',
    defaultMessage: 'book an appointment',
  },
})
