import React from 'react'

import {
  Heading,
  Text,
  BulletList,
  BulletListItem,
  Box,
  CardAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@babylon/web-platform-ui'
import { useFormatMessage, useIntl } from '@babylon/intl'

import { APP_NAME } from '../../config'
import NHSIcon from '../../assets/nhs'
import LinkWrapper from '../LinkWrapper'
import { DiagnosisOverviewProps } from './DiagnosisOverviewProps'

import messages from './messages'

import styles from './DiagnosisOverviewV2.module.scss'

const useIntlSafe = () => {
  try {
    return useIntl()
  } catch (err) {
    return undefined
  }
}

const DiagnosisOverview = ({
  callToActions,
  condition,
}: DiagnosisOverviewProps) => {
  const translate = useFormatMessage()
  const gbLocale = 'en-GB'
  const intl = useIntlSafe()
  // default to isGB if there is no intl object
  const isGB = !intl || intl.locale === gbLocale

  const showNhsChoices =
    isGB && APP_NAME !== 'rwanda_web' && condition.leaflet_link

  return (
    <div>
      <div className={styles.heading}>
        <Heading as="h1" size="md">
          {condition.layman_name}
        </Heading>
      </div>
      <Text>{condition.short_overview}</Text>
      <div className={styles.desc}>
        <Text>
          <strong>{condition.common_treatment.description}</strong>
        </Text>
      </div>

      <div className={styles.headingTwo}>
        <Heading as="h2" size="xs">
          {translate(messages.diagnosisReportHeadingTwo)}
        </Heading>
      </div>
      <div className={styles.diagnosisReportParagraph}>
        <Text>{translate(messages.diagnosisReportParagraph)}</Text>
      </div>

      <div className={styles.relatedSymptoms}>
        <BulletList as="ul">
          {condition.positive_related_symptoms.map((s) => (
            <BulletListItem key={s.id}>{s.layman_name}</BulletListItem>
          ))}
        </BulletList>
      </div>

      {condition.extra_overview?.length > 0 && (
        <div className={styles.extraOverview}>
          <Box>
            <CardAccordion>
              {condition.extra_overview.map((section) => (
                <AccordionItem key={section.title}>
                  <Heading as="h2">
                    <AccordionButton>{section.title}</AccordionButton>
                  </Heading>
                  <AccordionPanel>
                    <BulletList as="ul">
                      {section.items.map((item, i) => (
                        <BulletListItem key={i}>{item}</BulletListItem>
                      ))}
                    </BulletList>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </CardAccordion>
          </Box>
        </div>
      )}

      {showNhsChoices && (
        <div className={styles.leafletLink}>
          <LinkWrapper href={condition.leaflet_link} variant="standalone">
            {translate(messages.nhsChoices)}
            <NHSIcon className={styles.nhsChoices} />
          </LinkWrapper>
        </div>
      )}

      {callToActions && <div className={styles.ctas}>{callToActions}</div>}
    </div>
  )
}

export default DiagnosisOverview
