import React from 'react'
import { QueryClientProvider } from 'react-query'

import getQueryClient from '../../util/getQueryClient'

const withReactQuery = <P extends object>(
  Component: (props: P) => JSX.Element | null
) => (props: P) => {
  const client = getQueryClient()

  return (
    <QueryClientProvider client={client}>
      <Component {...props} />
    </QueryClientProvider>
  )
}

export default withReactQuery
