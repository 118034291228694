import { defineMessages } from 'react-intl'

export default defineMessages({
  view: {
    id: 'privacySettings.view',
    defaultMessage: 'View',
  },
  close: {
    id: 'Modal.CloseButtonText',
    defaultMessage: 'Close',
  },
})
