/* eslint-disable no-case-declarations, default-case */

import {
  AppviewActionMessage,
  QuestionOptionInterface,
} from '../../constants/types'
import {
  AppviewActionTypes,
  CallToActionTypes,
  LocationActionTypes,
} from '../../constants/enums'

import { trackEventWithConversationId } from './events'

const getCTA = (
  option: QuestionOptionInterface
): { action: string; label?: string } | null => {
  const { type, action, params } = option

  if (type === 'location') {
    switch (params?.target) {
      case LocationActionTypes.FindPlaceEmergencyRoom:
        return { action: 'find emergency room' }
      case LocationActionTypes.FindPlaceEyeCasualty:
        return { action: 'find eye casualty' }
      case LocationActionTypes.FindPlaceHospital:
        return { action: 'find hospital' }
      case LocationActionTypes.FindPlacePharmacy:
        return { action: 'find pharmacy' }
      case LocationActionTypes.FindPlaceSTDClinic:
        return { action: 'find std clinic' }
      case LocationActionTypes.FindPlaceUrgentCare:
        return { action: 'find urgent care' }
    }
  }

  switch (action?.type) {
    case CallToActionTypes.Appview:
      const appviewAction = action as AppviewActionMessage

      switch (appviewAction.data.view) {
        case AppviewActionTypes.StartConversation:
          return {
            action: 'start new chat',
            label: action.data.parameters?.type,
          }
        case AppviewActionTypes.BookConsultation:
          return { action: 'book appointment' }
        case AppviewActionTypes.ClinicalChat:
          return { action: 'start live chat' }
        case AppviewActionTypes.MonitorCovidCarePlan:
          return { action: 'coronavirus care plan' }
      }

      break

    case CallToActionTypes.Custom:
      return { action: 'custom cta', label: action.data.type }
    case CallToActionTypes.Email:
      return { action: 'email support' }
    case CallToActionTypes.Ask:
      return { action: 'message a clinician' }
    case CallToActionTypes.SearchConditions:
      return { action: 'search conditions' }
    case CallToActionTypes.Phone:
    case CallToActionTypes.CallSamaritans:
      return { action: 'call emergency number' }
    case CallToActionTypes.WebView:
      return { action: 'web view', label: action.data.url }
  }

  return null
}

const trackCTA = (
  actionPrefix: string,
  option: QuestionOptionInterface,
  conversationId: string
) => {
  const cta = getCTA(option)

  if (!cta) {
    return
  }

  const { action, label } = cta
  trackEventWithConversationId(
    `${actionPrefix} ${action}`,
    conversationId,
    label
  )
}

const trackCTAImpression = (
  option: QuestionOptionInterface,
  conversationId: string
) => trackCTA('cta saw', option, conversationId)

const trackCTAClick = (
  option: QuestionOptionInterface,
  conversationId: string
) => trackCTA('cta clicked', option, conversationId)

export { getCTA, trackCTAClick, trackCTAImpression }
